import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";

/* Themes */
import defaultTheme from "./themes/default.json";

// LAYOUT
import Layout from "./components/Views/Shared/Layout";

// PAGES
import { Home } from "./components/Views/Home/Home";
import { PageContent } from "./components/Views/Home/PageContent";
import { PrivacyPolicy } from "./components/Views/Home/PrivacyPolicy";
import { Terms } from "./components/Views/Home/Terms";
import { Product } from "./components/Views/Products/Product";
import { ProductDetails } from "./components/Views/Products/ProductDetails";
import { Client } from "./components/Views/Client/Client";
import { Shop } from "./components/Views/Shop/Shop";
import { ShopSuccess } from "./components/Views/Shop/ShopSuccess";

// STYLES
import "./styles/reset.scss";
import "./styles/config.scss";

export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={defaultTheme}>
        <Routes>
          {/* Homepage */}
          <Route
            exact
            path="/"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            exact
            path="/recuperar-password/:token"
            element={
              <Layout recoverPassword={true}>
                <Home />
              </Layout>
            }
          />
          <Route
            exact
            path="/politica-de-privacidade"
            element={
              <Layout>
                <PrivacyPolicy />
              </Layout>
            }
          />
          <Route
            exact
            path="/termos-e-condicoes"
            element={
              <Layout>
                <Terms />
              </Layout>
            }
          />
          {/* Piscinas */}
          <Route
            exact
            path="/piscinas"
            element={
              <Layout>
                <PageContent id={1} imgPath={"piscinas"} />
              </Layout>
            }
          />
          {/* Chave na mão */}
          <Route
            exact
            path="/chave-na-mao"
            element={
              <Layout>
                <PageContent id={2} imgPath={"chavenamao"} />
              </Layout>
            }
          />
          {/* Products */}
          <Route
            exact
            path="/produtos"
            element={
              <Layout>
                <Product />
              </Layout>
            }
          />
          <Route
            exact
            path="/produtos/:tag"
            element={
              <Layout>
                <ProductDetails />
              </Layout>
            }
          />

          {/* Client */}
          <Route
            exact
            path="/cliente"
            element={
              <Layout authorize>
                <Client />
              </Layout>
            }
          />

          {/* Shop */}
          <Route
            exact
            path="/carrinho"
            element={
              <Layout authorize>
                <Shop />
              </Layout>
            }
          />

          <Route
            exact
            path="/carrinho/sucesso/:order"
            element={
              <Layout authorize>
                <ShopSuccess />
              </Layout>
            }
          />

          {/* Default Route */}
          <Route
            path="*"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
        </Routes>
      </ThemeProvider>
    );
  }
}
