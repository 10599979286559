import React, { Component } from "react";
import styled, { css } from "styled-components";
import NanoFlex from "../../Global/NanoFlex";

import { CategoryItem } from "./CategoryItem.js";

const StyledCategoriesGrid = styled(NanoFlex)`
  .gridContainer {
    width: 100%;
    justify-content: center;
    display: grid;
    grid-gap: 20px;
    grid-auto-flow: dense;

    ${(props) =>
      props.autoFill &&
      css`
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      `}

    ${(props) =>
      !props.autoFill &&
      css`
        grid-template-columns: repeat(${(props) => props.maxCol ?? "auto-fit"}, minmax(100px, ${(props) => props.maxColSize ?? 600}px));
        grid-template-rows: repeat(auto-fill, minmax(100px, auto));
        grid-auto-rows: 450px;
      `}

    grid-template-rows: auto;
    grid-auto-flow: dense;

    height: 100%;
  }
`;

export class CategoriesGrid extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <StyledCategoriesGrid className="results" maxColSize={this.props.maxColSize} maxCol={this.props.maxCol} autoFill={this.props.autoFill}>
        <div className="gridContainer">
          {this.props.data?.map((d, i) => (
            <CategoryItem key={i} data={d} lvl={this.props.lvl ?? 1} />
          ))}
        </div>
      </StyledCategoriesGrid>
    );
  }
}
