import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import Image from "../../Global/Elements/Image.js";
import { randomInteger, randomDecimal, formatNumDefault, removeEmptyProperties } from "../../Global/tools.js";

import { ReactComponent as Heart } from "../../Global/Icons/heart.svg";

const StyledProductCard = styled.div`
  box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
  border: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};

  a {
    text-decoration: none;
  }

  .imgContainer {
    overflow: hidden;
    width: 100%;
    height: 100%;
    img {
      padding: 10px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .infoContainer {
    height: 125px;
    padding: 10px 20px;
    border-top: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};

    .price {
      .spacing {
        padding: 0px 5px;
      }
      h2 {
        color: ${(props) => props.theme.color.colorsPalette.red};
        font-weight: bold;
      }
      h5 {
        height: 25px;
        margin-left: 2px;
        color: ${(props) => props.theme.color.colorsPalette.red};
        font-weight: bold;
        vertical-align: top;
      }
    }

    .favorite {
      width: auto;
      svg {
        fill: ${(props) => (props.isFavorite ? props.theme.color.colorsPalette.red : props.theme.color.colorsPalette.grey)};
        height: 20px;
      }
    }
  }
`;

export class ProductCard extends Component {
  constructor(props) {
    super(props);
  }

  getData = () => {
    if (this.props.data && this.props.data.id) return this.props.data;

    /*MOCK DATA*/
    let titleMock = [
      "ZF12 Reboco à base de cal e gesso para inteiror",
      "MS 20 Reboco à base de cimento e cal para interior",
      "MM 30 Argamassa cimentícia para alvenaria",
      "ZP 149 Massa de acabamento à base de gesso para interior",
      "A 50 Adesivo de base cimentícia cinza",
      "A 50 Adesivo de base cimentícia cinza",
      "A 96 Adesivo de base cimentícia cinza e extra-branco",
      "GYPSOMAF Pasta de agarre para gesso cartonado",
      "FASSAJOINT 3H Estuque para gesso cartonado",
      "FASSAJOINT 2H Estuque para gesso cartonado",
      "MH 19 Reboco fibro-reforçado, com hidrorepelente, à base de cal e",
      "Z 161 Reboco à base de gesso e perlite para interior",
    ];
    let minPrice = randomDecimal(1, 30);
    return {
      id: 1,
      name: titleMock[randomInteger(0, titleMock.length - 1)],
      minPrice: minPrice,
      maxPrice: randomDecimal(minPrice, 30),
    };
  };

  render() {
    var data = this.getData();
    return (
      <StyledProductCard isFavorite={randomInteger(0, 1) === 1}>
        <Link to={`/produtos/${data.tag}${this.props.params ? `?${new URLSearchParams(removeEmptyProperties(this.props.params)).toString()}` : ``}`}>
          <NanoFlex flexDirection={"column"}>
            <div className="imgContainer">
              <Image id={data.id} imageUrl={`/api/products/${data.id}/1.jpg`} />
            </div>
            <NanoFlex className="infoContainer" flexDirection={"column"}>
              <NanoFlex className="title" justifyContent={"flex-start"}>
                <Type.h5>{data.name}</Type.h5>
              </NanoFlex>
              <NanoFlex>
                <NanoFlex className="price" justifyContent={"flex-start"}>
                  <Type.h2>{formatNumDefault(data.minPrice ?? 0, 2, 2)}</Type.h2>
                  <Type.h5>€</Type.h5>
                  {data.minPrice !== data.maxPrice && (
                    <React.Fragment>
                      <Type.h2 className="spacing">-</Type.h2>
                      <Type.h2>{formatNumDefault(data.maxPrice ?? 0, 2, 2)}</Type.h2>
                      <Type.h5>€</Type.h5>
                    </React.Fragment>
                  )}
                </NanoFlex>
                {/* <NanoFlex className="favorite" justifyContent={"flex-end"}>
                  <Heart />
                </NanoFlex> */}
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </Link>
      </StyledProductCard>
    );
  }
}
