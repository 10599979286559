import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled, { css } from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import { withParamsAndNavigate } from "./../../Global/tools.js";

import { ReactComponent as ArrowL } from "../../Global/Icons/arrowL.svg";
import { ReactComponent as LocationDot } from "../../Global/Icons/locationDot.svg";

/*Payment Methods*/
import { ReactComponent as Mb } from "../../Global/Icons/paymentMethods/mb.svg";
import { ReactComponent as MbWay } from "../../Global/Icons/paymentMethods/mbway.svg";
import { ReactComponent as Visa } from "../../Global/Icons/paymentMethods/visa.svg";
import { ReactComponent as Mastercard } from "../../Global/Icons/paymentMethods/mastercard.svg";
import { ReactComponent as Paypal } from "../../Global/Icons/paymentMethods/paypal.svg";

const StyledShopPayment = styled(NanoFlex)`
  .back {
    cursor: pointer;
    width: auto;
    gap: 5px;
    padding: 0px 0px 10px 0px;

    .backIcon {
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .backTitle {
      border-bottom: 2px solid ${(props) => props.theme.color.main.font};
    }
  }

  .paymentMethodItemsContainer {
    gap: 20px;
    .paymentMethodItem {
      cursor: pointer;
      padding: 20px;
      box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
      border: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
      gap: 20px;

      &.selected {
        box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.red};
        border: 2px solid ${(props) => props.theme.color.colorsPalette.red};
      }

      .paymentMethodBlock {
        gap: 20px;
        .name {
          h3 {
            font-weight: bold;
          }
        }

        .icon {
          width: auto;
        }
      }
    }
  }
`;

class ShopPayment extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      idPaymentMethod: this.props.idPaymentMethod,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.idPaymentMethod !== this.props.idPaymentMethod) {
      this.setState({ idPaymentMethod: this.props.idPaymentMethod });
    }

    if (prevState.idPaymentMethod !== this.state.idPaymentMethod && this.props.onPaymentMethodChange) {
      this.props.onPaymentMethodChange(this.state.idPaymentMethod);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledShopPayment flexDirection="column" alignItems="flex-start" isMobile={this._isMobile}>
        <NanoFlex className="back" justifyContent={"flex-start"} onClick={this.props.onBackClick}>
          <NanoFlex className="backIcon">
            <ArrowL />
          </NanoFlex>
          <NanoFlex className="backTitle">
            <Type.h4>Voltar</Type.h4>
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="paymentMethodItemsContainer" flexDirection="column">
          <NanoFlex
            className={`paymentMethodItem ${this.state.idPaymentMethod === 1 ? "selected" : ""}`}
            onClick={() => this.setState({ idPaymentMethod: 1 })}
          >
            <NanoFlex className="paymentMethodBlock" justifyContent="flex-start">
              <NanoFlex className="name" justifyContent="flex-start">
                <Type.h3>Paypal</Type.h3>
              </NanoFlex>
              <NanoFlex className="icon">
                <Paypal />
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex
            className={`paymentMethodItem ${this.state.idPaymentMethod === 2 ? "selected" : ""}`}
            flexDirection="column"
            onClick={() => this.setState({ idPaymentMethod: 2 })}
          >
            <NanoFlex className="paymentMethodBlock" justifyContent="flex-start">
              <NanoFlex className="name" justifyContent="flex-start">
                <Type.h3>Cartão de Credito</Type.h3>
              </NanoFlex>
              <NanoFlex className="icon">
                <Visa />
                <Mastercard />
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="paymentMethodBlock" justifyContent="flex-start">
              <NanoFlex className="name" justifyContent="flex-start">
                <Type.h3>Referência Multibanco</Type.h3>
              </NanoFlex>
              <NanoFlex className="icon">
                <Mb />
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="paymentMethodBlock" justifyContent="flex-start">
              <NanoFlex className="name" justifyContent="flex-start">
                <Type.h3>MB WAY</Type.h3>
              </NanoFlex>
              <NanoFlex className="icon">
                <MbWay />
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex
            className={`paymentMethodItem ${this.state.idPaymentMethod === 3 ? "selected" : ""}`}
            flexDirection="column"
            onClick={() => this.setState({ idPaymentMethod: 3 })}
          >
            <NanoFlex className="paymentMethodBlock" justifyContent="flex-start">
              <NanoFlex className="name" justifyContent="flex-start">
                <Type.h3>Transferencia Bancaria</Type.h3>
              </NanoFlex>
              <NanoFlex flexDirection="column">
                <Type.h3>TITULAR: REMOGRES</Type.h3>
                <Type.h3>IBAN: PT50 0007 0000 0036503098323</Type.h3>
                <Type.h3>INDICAR NUMERO DA ENCOMENDA NAS NOTAS DA TRANSFERENCIA</Type.h3>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledShopPayment>
    );
  }
}

var shopPayment = withParamsAndNavigate(ShopPayment);
export { shopPayment as ShopPayment };
