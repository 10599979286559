import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import { withParamsAndNavigate } from "../../Global/tools.js";

const StyledTerms = styled(NanoFlex)`
  padding: 0px 5vw;
  .sectionContainer {
    border-right: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
    border-left: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
    padding: 20px;
    gap: 5px;

    .gap {
      height: 10px;
    }

    .bold {
      font-weight: bold;
    }

    ul {
      margin-left: 40px;
      li {
        list-style-type: disc;
      }

      &.secondLevel {
        li {
          list-style-type: decimal;
        }
      }
    }

    .table {
      .header {
        border-bottom: 1px solid black;
      }
    }
  }
`;

class Terms extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
        <StyledTerms flexDirection="column">
        <NanoFlex className="sectionContainer" flexDirection="column" alignItems="flex-start">
          <NanoFlex>
            <Type.h1 className="bold">Termos e Condições da Conta Online</Type.h1>
          </NanoFlex>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h2 className="bold">Capítulo I – Condições Gerais do Site ynnowa</Type.h2>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">1. Introdução</Type.h3>
          <Type.h3>
            O presente documento estabelece a informação relativa à conta online e aos contratos celebrados à distância na página web{" "}
            <a href="https://www.ynnowa.com">www.ynnowa.com</a>, representada pela empresa Remogres, lda (doravante o Site Remogres), e de todos os
            seus subdomínios, a que alude o Decreto-Lei nº 24/2014 de 14 de fevereiro na redação atual, quer no âmbito da compra e venda dos bens e
            serviços estabelecida sem criação de conta, quer no âmbito da conta Remogres.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            O Site Remogres é propriedade da Remogres lda , com sede na Rua Atlectico Clube de Arrentela lote15-10B, Arrentela, 2840-621 Seixal,
            pessoa coletiva n° 514 427 427, que é também o seu número de matrícula na Conservatória do Registo Comercial de Lisboa, de ora em diante
            abreviadamente designada por Remogres.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            A Remogres disponibiliza um serviço de Apoio ao Cliente, através do qual o Cliente poderá, a qualquer momento, contactar a Remogres para
            pedir esclarecimentos, dar sugestões ou apresentar reclamações, através do endereço de e-mail{" "}
            <a href="mailto: ynnowa@gmail.pt">ynnowa@gmail.pt</a> ou através da linha de Apoio ao Cliente 21 084 1884, disponível das 8:00 às 17:00,
            todos os dias da semana, exceto, fins de semana e feriados (chamada para a rede fixa nacional).
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            A compra de qualquer produto no Site Remogres e seus subdomínios e a criação de conta pressupõem a aceitação das presentes informações e
            Termos e Condições da Conta Online.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>No Site Remogres o Cliente pode ainda acompanhar oportunidades e consultar o seu histórico de compras.</Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">2. Condições de Utilização do Site Remogres</Type.h3>
          <Type.h3>O Utilizador deve ter a idade mínima de 18 (dezoito) anos para criar conta online e efetuar compras no Site Remogres.</Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>O Utilizador deve utilizar o Site Remogres em cumprimento com o disposto na legislação aplicável.</Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            O Site Remogres pode possuir ligações a outros websites, os quais poderão conter informações/ferramentas úteis para os seus utilizadores.
            Os presentes Termos e Condições da Conta Online Remogres não serão aplicáveis a websites de terceiros. Como tal, caso o Utilizador visite
            outro website, redirecionado a partir do Site Remogres, deverá tomar conhecimento e aceitar os termos e condições e a política de
            privacidade do mesmo.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">3. Alteração do Site Remogres e dos presentes Termos e Condições da Conta Online Remogres</Type.h3>
          <Type.h3>
            A Remogres reserva-se o direito de modificar a qualquer momento, sem aviso prévio, a apresentação e conteúdo do Site Remogres, seus
            serviços e os presentes Termos e Condições da Conta Online Remogres, ressalvando-se os direitos adquiridos quanto aos contratos já
            celebrados e em execução. Essas modificações servirão para uma melhoria do Site Remogres e, simultaneamente, dos serviços oferecidos ao
            Utilizador que, caso não concorde com as alterações introduzidas, poderá cessar a utilização do Site Remogres.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">4. Propriedade intelectual</Type.h3>
          <Type.h3>
            Todo o conteúdo e informação constantes no Site Remogres e seus subdomínios, são propriedade da Remogres ou foram licenciados a esta e por
            isso a utilização, reprodução, cópia e divulgação por outros meios de logotipos, textos, imagens e vídeos, ou propriedade intelectual,
            constantes naquele está sujeita à sua autorização prévia da Remogres nesse sentido.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            O Cliente deve cumprir as condições contratuais e legais aplicáveis aos produtos comercializados no Site Remogres, nomeadamente no que
            concerne à utilização dos direitos intelectuais, direitos de autor e direitos conexos, direitos de utilização pessoais que regulam as
            cópias, a reprodução, alugueres, etc. A Remogres não poderá ser responsabilizada pela utilização indevida que porventura possa ser feita
            desses Produtos.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">5. Informação sobre os Produtos e sobre Preços</Type.h3>
          <Type.h3 className="bold">5.1 Informação de Produtos</Type.h3>
          <Type.h3>
            A Remogres tem em consideração a informação relativa às caraterísticas essenciais dos produtos mediante descrições técnicas partilhadas
            pelos seus fornecedores ou por terceiros, de fotografias e vídeos que ilustram os produtos comercializados, em rigoroso respeito pelos
            melhores padrões do mercado, transmitindo-as de forma clara aos seus clientes na página de cada produto e/ou serviço que disponibiliza.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            O Site Remogres também disponibiliza ao Cliente a possibilidade de adquirir produtos Outlet que podem ter danos na embalagem ou não ter a
            embalagem original, terem sido ligeiramente danificados no armazém, no transporte ou manipulação, ou simplesmente terem sido devolvidos.
            Estes produtos foram minuciosamente examinados pelos peritos Remogres para garantir que estão em perfeito estado de funcionamento e
            encontram-se assinalados no Site Remogres como Outlet.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">5.2 Informação de Preços</Type.h3>
          <Type.h3>
            A Remogres disponibiliza no seu Site, de forma clara, o preço total de cada bem ou serviço, incluindo taxas e impostos, encargos
            suplementares de transporte, despesas postais ou de entrega ou quaisquer outros encargos que sejam aplicáveis.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            A Remogres não poderá ser responsabilizada no caso de ocorrência de erro informático, manual, técnico, ou de qualquer outra origem, que
            cause uma alteração substancial não prevista no preço de venda ao público que conste do Site Remogres, pelo que nos casos em que este seja
            exorbitante ou manifestamente irrisório, o pedido de compra será considerado inválido e anulado e o Cliente será informado desse facto.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            Os preços e produtos disponibilizados no Site Remogres, bem como as campanhas promocionais e ofertas, são unicamente válidos para compras
            efetuadas através do Site Remogres, podendo não coincidir com os praticados nas lojas físicas, salvo promoções indicadas expressamente na
            página online.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">6. Criação de conta no Site Remogres</Type.h3>
          <Type.h3>
            Para aceder a todas as vantagens da conta online da Remogres, bem como para realizar um pedido de encomenda é necessário aceder ao Site
            Remogres e criar conta como utilizador naquele website, através do preenchimento do formulário eletrónico com os seus dados pessoais,
            seguindo as instruções indicadas em cada etapa.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>Após o registo, o utilizador poderá proceder à compra do(s) seu(s) produto(s) à Remogres.</Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">7. Meios de pagamento</Type.h3>
          <Type.h3>
            Para a comodidade do Cliente, a Remogres disponibiliza no Site Remogres os seguintes meios de pagamento dos produtos e/ou serviços:
          </Type.h3>
          <ul>
            <li>
              <Type.h3>Cartão Visa, MasterCard ou American Express;</Type.h3>
            </li>
            <li>
              <Type.h3>Multibanco</Type.h3>
            </li>
            <li>
              <Type.h3>PayPal</Type.h3>
            </li>
            <li>
              <Type.h3>MB Way</Type.h3>
            </li>
            <li>
              <Type.h3>Pay Shop</Type.h3>
            </li>
            <li>
              <Type.h3>Transferências</Type.h3>
            </li>
          </ul>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h2 className="bold">Capítulo II – Condições de Compra à Remogres no Site Remogres</Type.h2>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">1. Disponibilidade dos Produtos</Type.h3>
          <Type.h3>
            Todos os produtos que se encontram no Site Remogres estão disponíveis para encomenda, salvo rutura de stock. Os produtos disponíveis no
            Site Remogres possuem um stock limitado pelo que podem ficar indisponíveis sem aviso.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            Em caso de rutura de stock ocorrida após o pagamento da encomenda, a Remogres irá informar o Cliente desse facto tendo em vista o novo
            agendamento de entrega assim que o artigo estiver disponível ou o reembolso no prazo máximo de 14 (catorze) dias a contar da data do
            conhecimento daquela indisponibilidade.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">2. Custos de Entrega</Type.h3>
          <Type.h3>
            Podem ser devidos encargos suplementares pelo transporte do produto adquirido no Site Remogres, os quais acrescem ao preço total dos
            produtos selecionados. O Cliente será informado destes encargos, sempre que aplicável, bem como do preço total, antes de confirmar o seu
            pedido e concluir o processo de compra. A Remogres reserva-se o direito de alterar os preços destes encargos a qualquer momento, sem
            prejuízo de aplicar as taxas e preços contratualizados no momento da realização do pedido de compra.
          </Type.h3>
          <Type.h3>Os nossos custos de entrega são os seguintes:</Type.h3>
          <NanoFlex className="table" flexDirection={"column"}>
            <NanoFlex className="header">
              <NanoFlex>
                <Type.h3 className="bold">Local de Entrega</Type.h3>
              </NanoFlex>
              <NanoFlex>
                <Type.h3 className="bold">Tipo de Artigo</Type.h3>
              </NanoFlex>
              <NanoFlex>
                <Type.h3 className="bold">Portugal Continental</Type.h3>
              </NanoFlex>
              <NanoFlex>
                <Type.h3 className="bold">Madeira Porto Santo e Açores</Type.h3>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="body">
              <NanoFlex className="row">
                <NanoFlex>
                  <Type.h3>Portugal e Ilhas</Type.h3>
                </NanoFlex>
                <NanoFlex>
                  <Type.h3>Todos os artigos</Type.h3>
                </NanoFlex>
                <NanoFlex flexDirection={"column"}>
                  <Type.h3>Portes grátis:</Type.h3>
                  <ul>
                    <li>
                      <Type.h3>Acima de 100€ e abaixo de 10kg</Type.h3>
                    </li>
                    <li>
                      <Type.h3>Acima de 300€ e abaixo de 30kg</Type.h3>
                    </li>
                    <li>
                      <Type.h3>Acima de 500€ e abaixo de 700kg</Type.h3>
                    </li>
                    <li>
                      <Type.h3>Acima de 2000€ e abaixo de 3000kg</Type.h3>
                    </li>
                  </ul>
                  <Type.h3>Atenção que existem exceções em que são cobrados portes, devido ao tamanho do produto.</Type.h3>
                </NanoFlex>
                <NanoFlex>
                  <Type.h3>Acresce 20€ ao preçário anterior</Type.h3>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">3. Taxas e Impostos</Type.h3>
          <Type.h3>
            Os preços de venda ao público indicados no Site Remogres e seus subdomínios são apresentados em euros. O IVA e outros impostos ou taxas,
            eventualmente devidos, estão incluídos em todos os preços.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">4. Tratamento e entrega da encomenda</Type.h3>
          <Type.h3>
            A encomenda apenas será processada após a confirmação do respetivo pagamento, sendo o Cliente informado, por escrito, da confirmação da
            celebração da venda do produto/serviço e da data estimada de entrega ou disponibilização na loja.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>A Remogres não realiza entregas em apartados.</Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            A Remogres faz entregas dos produtos vendidos por si, no Site Remogres, no território de Portugal Continental e nos arquipélagos dos
            Açores e Madeira.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            O Cliente pode escolher se quer receber o artigo numa morada à sua escolha ou numa loja Remogres à sua escolha, consoante a tipologia de
            artigos e sujeito às condições de entrega em loja.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            Caso o Cliente escolha levantar o artigo numa loja Remogres, deverá proceder ao levantamento no prazo de 15 dias, após a notificação para
            o efeito. Caso não proceda ao levantamento do artigo no prazo indicado, a Remogres irá conceder um prazo adicional máximo para o efeito de
            90 dias, findo o qual o(s) artigo(s) serão considerados abandonados, nos termos previstos no artigo 1318.º do código civil, o que poderá
            implicar a perda do direito de propriedade a favor da Remogres.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            A fatura da encomenda será disponibilizada, no momento da expedição da encomenda, em formato digital para o email. . A Remogres poderá
            oferecer serviços adicionais relacionados com o processo de entrega que serão visíveis no processo de compra.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">5. Seguimento da encomenda</Type.h3>
          <Type.h3>
            A Remogres irá informar o Cliente do estado da sua encomenda e data de entrega ou disponibilização em Loja por email e/ou SMS. Para
            qualquer questão adicional deverá contactar a nossa Linha de Apoio ao Cliente (21 0841884), que lhe dará a informação sobre a sua entrega.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">6. Erros</Type.h3>
          <Type.h3>
            Nos termos do indicado no ponto 5.2 do Capítulo I, ressalvam-se eventuais erros de programação, defeitos no funcionamento do sistema
            informático (máquina) ou deformação da mensagem (incluindo lapsos de escrita), nos termos e para os efeitos do artigo 33.º nº 2 al. a) e
            b) do Decreto-Lei 7/2004, de 07 de janeiro.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">7. Garantia</Type.h3>
          <Type.h3>
            Os produtos comercializados pela Remogres em www.ynnowa.pt estão abrangidos pelo Regime Jurídicos dos Direitos dos Consumidores na Compra
            e Venda de Bens, Conteúdos e Serviços Digitais, aprovado pelo Decreto-Lei nº 84/2021, de 18 de outubro, na sua versão atual.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            Poderá exercer os seus direitos contactando a linha de Apoio ao Cliente Remogres. Poderá ainda dirigir-se diretamente ao fabricante.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">8. Direito de Rejeição</Type.h3>
          <Type.h3>
            Em caso de desconformidade do bem, nos primeiros trinta (30) dias após a entrega do mesmo, o consumidor pode solicitar a sua substituição
            ou a resolução contratual.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">9. Direito de Livre Resolução do Contrato</Type.h3>
          <Type.h3>
            No âmbito das compras em linha (online), o consumidor dispõe de um prazo de 7 dias, a contar do dia em que adquira a posse física dos bens
            ou do dia em que adquira a posse física do último bem, no caso de vários bens encomendados numa única encomenda e entregues separadamente,
            para proceder à devolução da sua encomenda, com custos para além dos estabelecidos na presente cláusula, de acordo com o disposto no nº 1
            do artigo 10º do DL nº 24/2014, de 14 de fevereiro, na sua versão atual.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            Para exercer este direito, deve declarar inequivocamente de que pretende resolver o contrato e deve devolver o artigo completo, nas mesmas
            condições em que foi vendido, sem sinais de uso indevido, em embalagem original (ou embalagem Remogres no caso de produtos Outlet sem
            embalagem original) e em bom estado, manual de instruções e acompanhado de todos os acessórios que o constituem.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            No caso de o Cliente comprar vários produtos no âmbito de uma campanha com oferta de um ou vários produtos da encomenda, como por exemplo
            “pague um leve dois” ou “pague dois e leve três”, o direito de livre resolução deve ser exercido em relação a todos os bens encomendados.
            Nestes casos, a Remogres não aceitará a devolução parcial da encomenda.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>No caso de exercício do direito em apreço, incumbe ao Cliente suportar o custo da devolução dos Produtos.</Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">10. Declaração de resolução</Type.h3>
          <Type.h3>
            Caso assim entenda, o consumidor pode exercer o direito de livre resolução através de envio via eletrónica a solicitar a livre resolução
            anexo ao referido Decreto-Lei n.º 24/2014, de 14 de fevereiro, ou através de qualquer outra declaração inequívoca de resolução.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">11. Devolução do Artigo</Type.h3>
          <Type.h3>
            Assim que a Remogres receba a declaração do Cliente de exercício do direito de resolução, a Linha de Apoio ao Cliente irá contactar o
            Cliente para agendar a recolha do artigo, nos casos aplicáveis. A recolha dos restantes produtos pode estar sujeita ao pagamento dos
            custos de transporte.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">12.1 Reembolso</Type.h3>
          <Type.h3>
            Após o exercício do Direito de Livre Resolução do Contrato, o reembolso do valor pago pelo Cliente, incluindo, se aplicável, os custos que
            o cliente tenha pago pela entrega do bem, será efetuado através do mesmo meio de pagamento que foi utilizado na compra inicial, após a
            validação do cumprimento das condições de devolução descritas no ponto 11, tendo a Remogres um prazo máximo de 14 dias para o efeito.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            Salvo os casos em que a Remogres se ofereça para recolher o artigo, a Remogres pode reter o reembolso enquanto o artigo não for recebido
            ou enquanto o Cliente não apresentar prova da devolução do artigo.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">12.2 Direito de Livre Resolução aplicável à Prestação de Serviços</Type.h3>
          <Type.h3>
            Nos termos previstos na legislação aplicável, o Direito de Livre Resolução no âmbito da prestação de serviços está sujeito às seguintes
            restrições:
          </Type.h3>
          <ul>
            <li>
              <Type.h3>
                Se o Cliente solicitar expressamente que a prestação de serviços se inicie ou seja prestada antes do prazo dos 7 dias previsto no
                ponto 10, e vier a exercer o Direito de Livre Resolução, deve pagar um montante proporcional ao que foi efetivamente prestado até à
                comunicação da resolução;
              </Type.h3>
            </li>
            <li>
              <Type.h3>
                O Cliente não pode resolver livremente o contrato de prestação de serviços quando os serviços tenham sido integralmente prestados nos
                termos do ponto anterior
              </Type.h3>
            </li>
            <li>
              <Type.h3>
                O Cliente não pode resolver livremente o contrato de prestação de serviços de reparação ou manutenção a executar no seu domicílio, a
                pedido deste, salvo no que exceda esse pedido.
              </Type.h3>
            </li>
          </ul>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">12.3 Exclusões Legais ao Direito de Livre Resolução</Type.h3>
          <Type.h3>Sem prejuízo do disposto no ponto 10.4, excluem-se do Direito de Livre Resolução do Contrato os contratos de:</Type.h3>
          <ul>
            <li>
              <Type.h3>Fornecimento de bens confecionados de acordo com as especificações do Cliente ou manifestamente personalizados;</Type.h3>
            </li>
            <li>
              <Type.h3>
                Fornecimento de bens que, por natureza, não possam ser reenviados ou sejam suscetíveis de se deteriorarem ou de ficarem rapidamente
                fora do prazo;
              </Type.h3>
            </li>
            <li>
              <Type.h3>
                Fornecimento de bens selados não suscetíveis de devolução, por motivos de proteção da saúde ou de higiene quando abertos após a
                entrega
              </Type.h3>
            </li>
            <li>
              <Type.h3>Fornecimento de bens que, após a sua entrega e por natureza, fiquem inseparavelmente misturados com outros produtos;</Type.h3>
            </li>
            <li>
              <Type.h3>
                Fornecimento de gravações de áudio ou vídeo ou de programas informáticos selados, a que o consumidor tenha retirado o selo de garantia
                de inviolabilidade após a entrega;
              </Type.h3>
            </li>
            <li>
              <Type.h3>
                Fornecimento de jornal, periódico ou revista, com exceção dos contratos de assinatura para o envio dessas publicações;
              </Type.h3>
            </li>
            <li>
              <Type.h3>Fornecimento de conteúdos digitais não fornecidos em suporte material se:</Type.h3>
              <ul className="secondLevel">
                <li>
                  <Type.h3>A sua execução tiver início com o consentimento do Cliente;</Type.h3>
                </li>
                <li>
                  <Type.h3>O Cliente reconhecer que o seu consentimento implica a perda do direito de livre resolução.</Type.h3>
                </li>
              </ul>
            </li>
          </ul>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">13. Responsabilidade</Type.h3>
          <Type.h3>
            A Remogres não é responsável pelos prejuízos decorrentes de quaisquer vírus informáticos, problemas de rede, ou de fornecimento de
            internet, informações prestadas pelos fornecedores ou quaisquer outras situações a que seja alheia e que impeçam o acesso e correto
            funcionamento do domínio www.ynnowa.pt bem como dos serviços disponibilizados no mesmo.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            A Remogres não poderá ser responsabilizada no caso de ocorrência de erro informático, manual, técnico, ou de qualquer outra origem, que
            cause uma alteração substancial não prevista no preço de venda ao público que conste do Site Remogres, pelo que nos casos em que este seja
            exorbitante ou manifestamente irrisório, o pedido de compra será considerado inválido e anulado e o Cliente será informado desse facto.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">14. Confirmação da Receção</Type.h3>
          <Type.h3>É responsabilidade do Cliente confirmar a receção dos Produtos e a conformidade dos mesmos.</Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            Os Produtos assumem-se como recebidos no prazo de 21 (vinte e um) dias corridos a contar da data da respetiva expedição, salvo indicação
            em contrário pelo Cliente. Esta cláusula não prejudica ou condiciona os direitos do consumidor previstos na lei.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h2 className="bold">Capítulo III - Política relativa a dados pessoais</Type.h2>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">1. Dados Pessoais tratados pela Remogres</Type.h3>
          <Type.h3>
            O responsável pelo tratamento de Dados Pessoais do Cliente no Site Remogres é a Remogres, lda, melhor identificada no Capítulo I, que pode
            ser contactada através da linha de Apoio ao Cliente 21 080 18 84, disponível das 8:00 às 17:00, todos os dias da semana exceto fins de
            semana e feriados (Chamada para a rede fixa nacional).
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            O tratamento dos dados pessoais do Cliente no âmbito da conta online e do Programa Cliente Remogres associado tem como objetivo
            possibilitar ao Cliente aceder ao seu histórico de compras. A previsão de preferências de consumo e identificação de perfis visa a
            elaboração de ações customizadas de marketing, a venda personalizada no atendimento presencial, a comunicação antecipada de campanhas que
            previsivelmente sejam do interesse do Cliente, podendo estas ser atribuídas e comunicadas de forma automática, bem como a otimização da
            gama de produtos disponibilizados online.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            A título de exemplo, um cliente que evidencie preferências por determinadas gamas de artigos poderá receber comunicações de campanhas
            relativas a artigos dessas gamas, podendo essas comunicações ser antecipadas face ao público em geral. Por outro lado, a utilização do
            Site Remogres permitirá à Remogres a aposta em artigos mais procurados pelos clientes.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            No âmbito de cada compra concreta, a Remogres trata os dados relativos às transações na execução do contrato de compra e venda de
            aquisição de produtos ou prestação de serviços que a Remogres disponibiliza no Site Remogres ou nas respetivas diligências pré-contratuais
            a pedido do Cliente (titular dos dados). Incluem-se neste âmbito os dados de faturação, entrega, pagamento e devoluções.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            Durante a navegação no Site Remogres, a Remogres poderá aceder e proceder ao armazenamento de informações relativas à navegação.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">2. Destinatários dos dados pessoais</Type.h3>
          <Type.h3>
            A Remogres apenas transmite os dados pessoais dos Clientes à Autoridade Tributária e a outras entidades que tenham necessidade de os
            receber devido a obrigações legais ou para cumprimento do contrato de compra e venda.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">3. Prazo de conservação dos dados pessoais</Type.h3>
          <Type.h3>
            A Remogres procederá à eliminação dos dados pessoais relativos às transações realizadas pelo Cliente no Site Remogres 10 (dez) anos após a
            data da última transação.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            O prazo referido corresponde ao prazo obrigatório de conservação dos documentos contabilísticos. Este prazo é ainda justificado com o
            facto de ser o prazo médio de vida útil dos equipamentos comercializados no site da Remogres, sendo este prazo justificado para efeitos de
            exercício de garantia e assistência pós-venda, de facilitar referências de artigos para compra de peças e acessórios.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            Os dados pessoais recolhidos para efeitos da entrega dos produtos serão eliminados no prazo de 2 (dois) anos após a entrega em cumprimento
            das obrigações de garantia dos artigos.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            Caso o Cliente cancele a sua conta online ou retire o consentimento, os dados serão eliminados pela Remogres no prazo de um mês após o
            cliente manifestar essa intenção de a cancelar. Neste caso, a Remogres manterá apenas os dados de transações para efeitos fiscais, durante
            o prazo previsto na Lei.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">4. Direitos do Cliente</Type.h3>
          <Type.h3>
            O Cliente tem os seguintes direitos no âmbito da proteção de dados pessoais: direito de acesso, direito de retificação, direito de
            apagamento, direito de limitação do tratamento, direito de portabilidade, direito de oposição e direito de não ficar sujeito a decisões
            automatizadas, os quais podem ser exercidos nos termos previstos na legislação e regulamentação aplicável.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h2 className="bold">Capítulo IV – Lei aplicável e Resolução Alternativa de Litígios</Type.h2>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3 className="bold">1. Legislação aplicável</Type.h3>
          <Type.h3>
            Os presentes Termos & Condições e todos os contratos que vierem a ser celebrados ao abrigo dos mesmos regem-se pela legislação portuguesa.
          </Type.h3>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>Atualmente existem em Portugal as seguintes entidades de Resolução de Litígios de Consumo:</Type.h3>
          <ul>
            <li>
              <Type.h3>
                <a href="https://www.cniacc.pt/pt/">Centro Nacional de Informação e Arbitragem de Conflitos de Consumo (CNIACC);</a>
              </Type.h3>
            </li>
            <li>
              <Type.h3>
                <a href="https://cacrc.pt/">Centro de Arbitragem de Conflitos de Consumo do Distrito de Coimbra (CACCDC);</a>
              </Type.h3>
            </li>
            <li>
              <Type.h3>
                <a href="http://www.centroarbitragemlisboa.pt/">Centro de Arbitragem de Conflitos de Consumo de Lisboa (CACCL);</a>
              </Type.h3>
            </li>
            <li>
              <Type.h3>
                <a href="https://arbitragem.autonoma.pt/">Centro de Arbitragem da Universidade Autónoma de Lisboa (CAUAL);</a>
              </Type.h3>
            </li>
            <li>
              <Type.h3>
                <a href="https://www.madeira.gov.pt/cacc/">Centro de Arbitragem de Conflitos de Consumo da Região Autónoma da Madeira (CACC RAM);</a>
              </Type.h3>
            </li>
            <li>
              <Type.h3>
                <a href="https://cicap.pt/">Centro de Informação e Arbitragem do Porto (CICAP);</a>
              </Type.h3>
            </li>
            <li>
              <Type.h3>
                <a href="https://www.triave.pt/">Centro de Informação e Arbitragem do Vale do Ave (TRIAVE);</a>
              </Type.h3>
            </li>
            <li>
              <Type.h3>
                <a href="https://www.ciab.pt/pt/">Centro de Informação e Arbitragem do Vale do Cávado (CIAB);</a>
              </Type.h3>
            </li>
            <li>
              <Type.h3>
                <a href="https://www.consumidoronline.pt/pt/">Centro de Informação, Mediação e Arbitragem do Algarve (CIMAAL).</a>
              </Type.h3>
            </li>
          </ul>
          <NanoFlex className="gap"></NanoFlex>
          <Type.h3>
            Mais informações no site da Direção-Geral do Consumidor: <a href="https://www.consumidor.pt">www.consumidor.pt</a>.
          </Type.h3>
        </NanoFlex>
        </StyledTerms>
    );
  }
}

var terms = withParamsAndNavigate(Terms);
export { terms as Terms };
