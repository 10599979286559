import React from "react";
import BaseComponent from "../../BaseComponent.js";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import Image from "../../Global/Elements/Image.js";
import { withParamsAndNavigate, formatNumDefault, getVariationRefFormat } from "./../../Global/tools.js";

//Services
import shopService from "./../../../services/ShopService.js";

import { ReactComponent as Trash } from "../../Global/Icons/trash.svg";
import { ReactComponent as TruckFast } from "../../Global/Icons/truckFast.svg";

const StyledShopItems = styled(NanoFlex)`
  .noShopItemsContainer {
    height: 25vh;
    gap: 20px;
    .icon {
      height: auto;
      svg {
        width: 24px;
        height: 24px;

        fill: ${(props) => props.theme.color.colorsPalette.lightBlack};
      }
    }

    .message {
      height: auto;
      h2 {
        text-align: center;
        font-weight: bold;
        color: ${(props) => props.theme.color.colorsPalette.lightBlack};
      }
    }
  }

  .shopItemsContainer {
    gap: 20px;
    .shopItem {
      height: 170px;
      border: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
      box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
      .imgContainer {
        max-width: 150px;
        overflow: hidden;
        width: 100%;
        height: 100%;
        img {
          padding: 10px;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .productInfo {
        padding: 20px 20px;
        gap: 10px;
        .info {
          .ref {
            height: auto;
          }

          .title {
            height: auto;

            h3 {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }

          .amountContainer {
            width: auto;
            gap: 10px;
            ${(props) =>
              props.isMobile &&
              css`
                padding: 10px 0px;
              `}
            .btnAmount {
              min-width: 40px;
              width: 40px;
              height: 40px;
              border: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
              user-select: none;

              &.btnMinus,
              &.btnPlus {
                cursor: pointer;
              }
            }
          }
        }

        .productPrice {
          max-width: 100px;

          .delete {
            .btn {
              cursor: pointer;
              width: 24px;
              height: 24px;
              svg {
                width: 24px;
                height: 24px;
              }
            }
          }
        }
      }
    }
  }
`;

class ShopItems extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      shopLines: this.props.shopLines,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.shopLines) !== JSON.stringify(this.props.shopLines)) {
      this.setState({ shopLines: this.props.shopLines });
    }

    if (JSON.stringify(prevState.shopLines) !== JSON.stringify(this.state.shopLines) && this.props.onShopLinesChange) {
      this.props.onShopLinesChange(this.state.shopLines);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  removeShopLine = (item) => {
    shopService.removeShopLine(item.idProductVariation).then((response) => {
      if (response.ok) {
        window.dispatchEvent(new Event("updateShopCount"));
        this.setState({ shopLines: this.state.shopLines.filter((s) => s.idProductVariation !== item.idProductVariation) });
      }
    });
  };

  changeShopLineQuantity = (item, increment) => {
    /*Prevent Negative Quantity*/
    if (item.quantity + increment > 0) {
      shopService.addShopLine({ ...item, quantity: increment }).then((response) => {
        if (response.ok) {
          let immutableLines = JSON.parse(JSON.stringify(this.state.shopLines));
          let toUpdate = immutableLines.find((l) => l.idProductVariation == item.idProductVariation);
          if (toUpdate) {
            toUpdate.quantity = toUpdate.quantity + increment;
          }
          this.setState({ shopLines: immutableLines });
        }
      });
    }
  };

  render() {
    return (
      <StyledShopItems flexDirection={this._isMobile ? "column" : "row"} alignItems="flex-start">
        {this.state.shopLines && this.state.shopLines.length === 0 && (
          <NanoFlex className="noShopItemsContainer" flexDirection={"column"}>
            <NanoFlex className="icon">
              <TruckFast />
            </NanoFlex>
            <NanoFlex className="message">
              <Type.h2>Ainda não tens artigos no carrinho.</Type.h2>
            </NanoFlex>
          </NanoFlex>
        )}
        {this.state.shopLines && this.state.shopLines.length > 0 && (
          <NanoFlex className="shopItemsContainer" flexDirection="column">
            {this.state.shopLines?.length > 0 &&
              this.state.shopLines.map((l) => {
                return (
                  <NanoFlex key={l.idProductVariation} className="shopItem">
                    <Link to={`/produtos/${l.productTag}`}>
                      <div className="imgContainer">
                        <Image id={l.idProduct} imageUrl={`/api/products/${l.idProduct}/1.jpg`} />
                      </div>
                    </Link>
                    <NanoFlex className="productInfo" justifyContent="flex-start">
                      <NanoFlex className="info" flexDirection="column">
                        <NanoFlex className="title" justifyContent="flex-start">
                          <Type.h3>{l.productName}</Type.h3>
                        </NanoFlex>
                        <NanoFlex className="title" justifyContent="flex-start">
                          <Type.h3>{l.variationName}</Type.h3>
                        </NanoFlex>
                        <NanoFlex className="ref" justifyContent="flex-start">
                          <Type.h4>REF: {getVariationRefFormat(l.ref)}</Type.h4>
                        </NanoFlex>
                        <NanoFlex justifyContent="flex-start">
                          <NanoFlex className="amountContainer">
                            <NanoFlex className="btnAmount btnMinus" onClick={() => this.changeShopLineQuantity(l, -1)}>
                              <Type.h3>-</Type.h3>
                            </NanoFlex>
                            <NanoFlex className="btnAmount">
                              <Type.h3>{l.quantity}</Type.h3>
                            </NanoFlex>
                            <NanoFlex className="btnAmount btnPlus" onClick={() => this.changeShopLineQuantity(l, 1)}>
                              <Type.h3>+</Type.h3>
                            </NanoFlex>
                          </NanoFlex>
                        </NanoFlex>
                      </NanoFlex>
                      <NanoFlex className="productPrice" flexDirection="column">
                        <NanoFlex className="delete">
                          <NanoFlex className="btn" onClick={() => this.removeShopLine(l)}>
                            <Trash />
                          </NanoFlex>
                        </NanoFlex>
                        <NanoFlex className="price">
                          <Type.h3>{formatNumDefault((l.promo ?? l.pvp) * l.quantity, 2, 2)} €</Type.h3>
                        </NanoFlex>
                      </NanoFlex>
                    </NanoFlex>
                  </NanoFlex>
                );
              })}
          </NanoFlex>
        )}
      </StyledShopItems>
    );
  }
}

var shopItems = withParamsAndNavigate(ShopItems);
export { shopItems as ShopItems };
