import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled, { css } from "styled-components";
import { Popper, Reference, Manager } from "react-popper";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import { withParamsAndNavigate, formatNumDefault } from "./../../Global/tools.js";

import { ShopItems } from "./ShopItems.js";
import { ShopBillingAddress } from "./ShopBillingAddress.js";
import { ShopDeliveryAddress } from "./ShopDeliveryAddress.js";
import { ShopPayment } from "./ShopPayment.js";

import { ReactComponent as CircleInfo } from "../../Global/Icons/circleInfo.svg";

//Services
import shopService from "./../../../services/ShopService.js";

const StyledShop = styled(NanoFlex)`
  padding: 0px 5vw 30px 5vw;

  .titleContainer {
    padding: 40px 0px;
    .title {
      width: auto;
      border-bottom: 3px solid ${(props) => props.theme.color.colorsPalette.red};
    }
  }

  .shopContainer {
    gap: 30px;

    .noShopItemsContainer {
      height: 25vh;
      gap: 20px;
      .icon {
        height: auto;
        svg {
          width: 24px;
          height: 24px;

          fill: ${(props) => props.theme.color.colorsPalette.lightBlack};
        }
      }

      .message {
        height: auto;
        h2 {
          text-align: center;
          font-weight: bold;
          color: ${(props) => props.theme.color.colorsPalette.lightBlack};
        }
      }
    }

    .shopItemsContainer {
      gap: 20px;
      .shopItem {
        height: 170px;
        border: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
        box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
        .imgContainer {
          max-width: 150px;
          overflow: hidden;
          width: 100%;
          height: 100%;
          img {
            padding: 10px;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .productInfo {
          padding: 20px 20px;
          gap: 10px;
          .info {
            .ref {
              height: auto;
            }

            .title {
              height: auto;

              h3 {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }

            .amountContainer {
              width: auto;
              gap: 10px;
              ${(props) =>
                props.isMobile &&
                css`
                  padding: 10px 0px;
                `}
              .btnAmount {
                min-width: 40px;
                width: 40px;
                height: 40px;
                border: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
                user-select: none;

                &.btnMinus,
                &.btnPlus {
                  cursor: pointer;
                }
              }
            }
          }

          .productPrice {
            max-width: 100px;

            .delete {
              .btn {
                cursor: pointer;
                width: 24px;
                height: 24px;
                svg {
                  width: 24px;
                  height: 24px;
                }
              }
            }
          }
        }
      }
    }

    .shopResumeContainer {
      ${(props) =>
        !props.isMobile &&
        css`
          width: auto;
        `}
      .shopResume {
        ${(props) =>
          !props.isMobile &&
          css`
            min-width: 25vw;
            width: auto;
          `}
        padding: 20px;
        border: 3px solid ${(props) => props.theme.color.colorsPalette.grey};
        border-top: 3px solid ${(props) => props.theme.color.colorsPalette.red};

        gap: 20px;

        .costContainer {
          padding: 10px 0px;
          gap: 10px;
          border-bottom: 2px solid ${(props) => props.theme.color.main.font};

          .costItem {
            gap: 20px;

            h4 {
              white-space: nowrap;
            }

            .costInfo {
              padding: 0px 5px;
              width: auto;
              svg {
                width: 15px;
                height: 15px;
              }
            }
            .costInfoPopup {
              z-index: 1;
              padding: 10px;
              border: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
              box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
              background-color: white;
            }
          }
        }

        .payContainer {
          .btnPay {
            cursor: pointer;
            height: 40px;
            background-color: ${(props) => props.theme.color.colorsPalette.red};

            h3 {
              color: ${(props) => props.theme.color.main.white};
            }

            &.disabled {
              cursor: default;
              background-color: ${(props) => props.theme.color.colorsPalette.grey};
              h3 {
                color: ${(props) => props.theme.color.main.font};
              }
            }
          }
        }

        .total {
          gap: 5px;
          .iva {
            h5 {
              color: ${(props) => props.theme.color.colorsPalette.lightBlack};
            }
          }
        }
      }
    }
  }
`;

class Shop extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isCostInfoOpen: false,
      //Shop Lines
      shopLines: null,
      shipmentCost: null,
      //Shop Steps Data
      step: 1,
      idBillingAddress: null,
      isShopDelivery: true,
      idDeliveryAddress: null,
      isDeliveryAddressIsland: false,
      observations: "",
      idPaymentMethod: null,
      disableNextStep: false,
    };

    this.costInfoPopupRef = React.createRef();
  }

  componentDidMount() {
    super.componentDidMount();
    this.getShopLines();
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevState.shopLines) !== JSON.stringify(this.state.shopLines) ||
      (prevState.isDeliveryAddressIsland ?? false) !== (this.state.isDeliveryAddressIsland ?? false)
    ) {
      this.getShipmentCost();
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = (e) => {
    if (this.state.isCostInfoOpen && !this.costInfoPopupRef?.current?.contains(e.target))
      this.setState({
        isCostInfoOpen: false,
      });
  };

  getShopLines = () => {
    shopService
      .getShopLines()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          this.clientLogout();
        }
      })
      .then((data) => {
        this.setState({ shopLines: data });
      });
  };

  getShipmentCost = () => {
    shopService
      .getShipmentCost(this.state.isDeliveryAddressIsland ?? false)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        this.setState({ shipmentCost: data });
      });
  };

  getShipmentCostFormat = () => {
    let shipmentCost = this.state.isShopDelivery ? 0 : this.state.shipmentCost;

    if (shipmentCost > 0) {
      return `${shipmentCost} €`;
    } else {
      return "Portes Gratis";
    }
  };

  getSubTotal = () => {
    if (this.state.shopLines?.length > 0) {
      return this.state.shopLines.reduce((acc, o) => acc + (o.promo ?? o.pvp) * o.quantity, 0);
    } else {
      return 0;
    }
  };

  getTotal = () => {
    if (this.state.isShopDelivery) {
      return this.getSubTotal();
    } else {
      return this.getSubTotal() + (this.state.shipmentCost ?? 0);
    }
  };

  getTitle = () => {
    switch (this.state.step) {
      case 1:
        return "O meu carrinho";
      case 2:
        return "Dados de faturação";
      case 3:
        return "Dados de entrega";
      case 4:
        return "Métodos de pagamento";
    }
  };

  getNextStepTitle = () => {
    if (this.state.step === 4) {
      return "Comprar";
    } else {
      return "Continuar";
    }
  };

  nextStep = () => {
    if (this.isNextStepDisabled() === false) {
      if (this.state.step !== 4) {
        this.setState({ step: this.state.step + 1 });
      } else {
        //Get Payment Gateway
        this.setState({ disableNextStep: true }, () => {
          shopService
            .getPaymentGateway({
              IdBillingAddress: this.state.idBillingAddress,
              IdDeliveryAddress: this.state.isShopDelivery ? null : this.state.idDeliveryAddress,
              IdPaymentMethod: this.state.idPaymentMethod,
              Observations: this.state.observations,
            })
            .then((response) => {
              if (response.ok) {
                return response.json();
              }
            })
            .then((data) => {
              if (data) {
                window.location.href = data;
              }
            });
        });
      }
    }
  };

  isNextStepDisabled = () => {
    if (this.state.disableNextStep) {
      return true;
    } else {
      switch (this.state.step) {
        case 2:
          return this.state.idBillingAddress === null;
        case 3:
          return this.state.idDeliveryAddress === null && this.state.isShopDelivery === false;
        case 4:
          return this.state.idPaymentMethod === null;
      }
      return false;
    }
  };

  render() {
    let subTotal = formatNumDefault(this.getSubTotal(), 2, 2);
    let total = formatNumDefault(this.getTotal(), 2, 2);
    return (
      <StyledShop flexDirection="column" isMobile={this._isMobile}>
        <NanoFlex className="titleContainer" flexDirection="column" alignItems="flex-start">
          <NanoFlex className="title">
            <Type.h1>{this.getTitle()}</Type.h1>
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="shopContainer" flexDirection={this._isMobile ? "column" : "row"} alignItems="flex-start">
          {this.state.step === 1 && <ShopItems shopLines={this.state.shopLines} onShopLinesChange={(l) => this.setState({ shopLines: l })} />}
          {this.state.step === 2 && (
            <ShopBillingAddress
              idBillingAddress={this.state.idBillingAddress}
              onBillingAddressChange={(id) => this.setState({ idBillingAddress: id })}
              onBackClick={() => this.setState({ step: 1 })}
            />
          )}
          {this.state.step === 3 && (
            <ShopDeliveryAddress
              idDeliveryAddress={this.state.idDeliveryAddress}
              onDeliveryAddressChange={(obj) => this.setState({ idDeliveryAddress: obj?.id, isDeliveryAddressIsland: obj?.isIsland })}
              isShopDelivery={this.state.isShopDelivery}
              onShopDeliveryChange={(val) => this.setState({ isShopDelivery: val })}
              observations={this.state.observations}
              onObservationsChange={(val) => this.setState({ observations: val })}
              onBackClick={() => this.setState({ step: 2 })}
            />
          )}
          {this.state.step === 4 && (
            <ShopPayment
              idPaymentMethod={this.state.idPaymentMethod}
              onPaymentMethodChange={(id) => this.setState({ idPaymentMethod: id })}
              onBackClick={() => this.setState({ step: 3 })}
            />
          )}
          {this.state.shopLines && this.state.shopLines.length > 0 && (
            <NanoFlex className="shopResumeContainer" flexDirection="column" alignItems="flex-start">
              <NanoFlex className="shopResume" flexDirection="column">
                <NanoFlex className="title" justifyContent="flex-start">
                  <Type.h3>RESUMO DA COMPRA</Type.h3>
                </NanoFlex>
                <NanoFlex className="costContainer" flexDirection="column">
                  <NanoFlex className="costItem" justifyContent="flex-start">
                    <NanoFlex justifyContent="flex-start">
                      <Type.h4>Subtotal</Type.h4>
                    </NanoFlex>
                    <NanoFlex justifyContent="flex-end">
                      <Type.h4>{subTotal} €</Type.h4>
                    </NanoFlex>
                  </NanoFlex>
                  <NanoFlex className="costItem" justifyContent="flex-start">
                    <NanoFlex justifyContent="flex-start">
                      <Type.h4>Custo de envio</Type.h4>
                      <NanoFlex className="costInfo" ref={this.costInfoPopupRef}>
                        <Manager>
                          <Reference>
                            {(targetProps) => {
                              return (
                                <NanoFlex ref={targetProps.ref} onClick={() => this.setState({ isCostInfoOpen: !this.state.isCostInfoOpen })}>
                                  <CircleInfo />
                                </NanoFlex>
                              );
                            }}
                          </Reference>
                          {this.state.isCostInfoOpen && (
                            <Popper placement={"bottom"}>
                              {({ ref, style, placement, arrowProps }) => (
                                <div className="costInfoPopup" ref={ref} style={style} data-placement={placement}>
                                  <NanoFlex flexDirection="column">
                                    <Type.h3>Portes grátis:</Type.h3>
                                    <Type.h4>Acima de 100€ e abaixo de 10kg</Type.h4>
                                    <Type.h4>Acima de 300€ e abaixo de 30kg</Type.h4>
                                    <Type.h4>Acima de 500€ e abaixo de 700k</Type.h4>
                                    <Type.h4>Acima de 2000€ e abaixo de 3000kg</Type.h4>
                                    <Type.h4>Atenção que existem exceções em que são cobrados</Type.h4>
                                    <Type.h4>portes, devido ao tamanho do produto.</Type.h4>
                                  </NanoFlex>
                                </div>
                              )}
                            </Popper>
                          )}
                        </Manager>
                      </NanoFlex>
                    </NanoFlex>
                    <NanoFlex justifyContent="flex-end">
                      <Type.h4>{this.getShipmentCostFormat()}</Type.h4>
                    </NanoFlex>
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="total" flexDirection="column" justifyContent="flex-start">
                  <NanoFlex>
                    <NanoFlex justifyContent="flex-start">
                      <Type.h3>Total</Type.h3>
                    </NanoFlex>
                    <NanoFlex justifyContent="flex-end">
                      <Type.h3>{total} €</Type.h3>
                    </NanoFlex>
                  </NanoFlex>
                  <NanoFlex className="iva" justifyContent="flex-end">
                    <Type.h5>Preços c/iva</Type.h5>
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="payContainer" justifyContent="flex-start">
                  <NanoFlex className={`btnPay ${this.isNextStepDisabled() ? "disabled" : ""}`} onClick={this.nextStep}>
                    <Type.h3>{this.getNextStepTitle()}</Type.h3>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          )}
        </NanoFlex>
      </StyledShop>
    );
  }
}

var shop = withParamsAndNavigate(Shop);
export { shop as Shop };
