import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";

import { ProductCard } from "./ProductCard.js";

const StyledProductGrid = styled(NanoFlex)`
  .gridContainer {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    grid-template-rows: 330px;
    grid-auto-flow: dense;
    grid-auto-rows: 380px;

    width: 100%;
    height: 100%;
  }
`;

export class ProductGrid extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <StyledProductGrid className="results">
        <div className="gridContainer">
          {this.props.data?.map((d, i) => (
            <ProductCard key={i} data={d} params={this.props.params} />
          ))}
        </div>
      </StyledProductGrid>
    );
  }
}
