import { BaseService } from "./BaseService";

export class PageService extends BaseService {
  getPages() {
    return this.request("api/Page/GetPages", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getContent(id) {
    return this.request(`api/Page/GetContent/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  contactUs(model) {
    return this.request(`api/Page/ContactUs`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(model),
    });
  }
}

const pageService = new PageService();

export default pageService;
