import { BaseService } from "./BaseService";

export class ClientService extends BaseService {
  authenticate(user) {
    return this.request("api/Client/Authenticate", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(user),
    });
  }

  register(user) {
    return this.request("api/Client/Register", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(user),
    });
  }

  resetPassword(model) {
    return this.request("api/Client/ResetPassword", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(model),
    });
  }

  useResetPassword(model) {
    return this.request("api/Client/UseResetPassword", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(model),
    });
  }

  getAddresses() {
    return this.request("api/Client/GetAddresses", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  updateAddress(model) {
    return this.request("api/Client/UpdateAddress", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(model),
    });
  }

  deleteAddress(idAddress) {
    return this.request(`api/Client/DeleteAddress/${idAddress}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
    });
  }
}

const clientService = new ClientService();

export default clientService;
