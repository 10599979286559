import React, { Component } from "react";
import BaseComponent from "../../BaseComponent.js";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import { withParamsAndNavigate, getQueryStringObj } from "../../Global/tools.js";

import productService from "./../../../services/ProductService.js";

import { ProductGrid } from "./ProductGrid.js";

//ICONS
import { ReactComponent as ArrowL } from "../../Global/Icons/arrowL.svg";
import { ReactComponent as ArrowR } from "../../Global/Icons/arrowR.svg";

//FORMS
import SingleSelectionDropdown from "../../Global/Elements/Forms/SingleSelectionDropdown";

//ICONS
// import { ReactComponent as Bars } from "../../Global/Icons/barsSolid.svg";

const StyledProductResults = styled(NanoFlex)`
  height: auto;
  padding: 25px;
  .scrollContainer {
    width: 100%;
    height: 100%;

    .navigation {
      height: 20px;
      a {
        display: flex;
        text-decoration: none;
        h4 {
          color: ${(props) => props.theme.color.colorsPalette.grey};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:not(:first-child) {
            padding: 0px 3px;
          }
          &:last-child {
            user-select: none;
            cursor: default;
          }
          &.active {
            font-weight: bold;
          }
        }

        &:last-child {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .header {
      gap: 10px;
      height: auto;
      padding-bottom: 20px;

      ${(props) =>
              !props.isMobile &&
              css`
            .resultsPerPageContainer {
              width: 200px;
            }
      `}
    }

    .results {
      .gridContainer {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
        grid-template-rows: 330px;
        grid-auto-flow: dense;
        grid-auto-rows: 380px;

        width: 100%;
        height: 100%;
      }
    }

    .pagging {
      .totalResults {
        padding-top: 20px;
      }
      .pageContainer {
        padding: 20px;
        .page {
          margin: 0px 3px;
          width: 40px;
          height: 40px;
          user-select: none;

          &.selected {
            background-color: ${(props) => props.theme.color.colorsPalette.lightBlack};
            h4 {
              color: white;
            }
          }

          &:hover:not(.space, .selected) {
            background-color: ${(props) => props.theme.color.colorsPalette.grey};
          }
        }

        .arrow {
          svg {
            height: 15px;
            width: 15px;
          }
        }
      }
    }
  }
`;

class ProductResults extends BaseComponent {
  constructor(props) {
    super(props);

    let params = getQueryStringObj(this.props.location.search);
    this.state = {
      results: null,
      page: Number(params?.page ?? 1),
      size: this.getPageSize(),
      orderBy: Number(params?.orderBy ?? 1),
    };
  }

  getPageSize = () => {
    return this._isMobile ? 15 : 50;
  };

  componentDidMount() {
    super.componentDidMount();
    window.addEventListener("isMobileChange", this.onMobileChange, false);

    if (this.props.filter) this.getProducts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.filter) !== JSON.stringify(this.props.filter) ||
      prevState.size !== this.state.size ||
      prevState.orderBy !== this.state.orderBy
    ) {
      this.setState({ page: 1 }, () => {
        this.getProducts();
      });
    }

    if (prevState.page !== this.state.page) {
      this.getProducts();
    }

    if (prevState.page !== this.state.page || prevState.orderBy !== this.state.orderBy) {
      let params = getQueryStringObj(this.props.location.search) ?? {};
      params.page = this.state.page;
      params.orderBy = this.state.orderBy;
      this.props.navigate("/produtos?" + new URLSearchParams(params).toString());
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener("isMobileChange", this.onMobileChange, false);
  }

  onMobileChange = () => {
    this.setState({ size: this.getPageSize() });
  };

  getProducts = () => {
    let orderBy = null;
    let orderByDirection = null;
    switch (this.state.orderBy ?? 1) {
      case 1:
        orderBy = "CREATIONDATE";
        orderByDirection = "DESC";
        break;
      case 2:
        orderBy = "PRICE";
        orderByDirection = "ASC";
        break;
      case 3:
        orderBy = "PRICE";
        orderByDirection = "DESC";
        break;
    }

    productService
      .getProducts({
        ...this.props.filter,
        ...{
          page: this.state.page,
          size: this.state.size,
          orderBy: orderBy,
          orderByDirection: orderByDirection,
        },
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        this.setState({ results: data });
      });
  };

  getPages = () => {
    if (this.state.results?.count > 0) {
      Math.ceil(this.state.results.count / this.state.size);
    } else {
      return [];
    }
  };

  render() {
    if (!this.state.results) return null;

    let totalPages = Math.ceil(this.state.results.count / this.state.size);
    let hasTerm = this.props.filter?.term && this.props.filter?.term?.trim() !== "";
    return (
      <StyledProductResults flexDirection={"column"} isMobile={this._isMobile}>
        <div className="scrollContainer">
          {this.props.parentCategories && this.props.parentCategories.length > 0 && (
            <NanoFlex className="navigation" justifyContent={"flex-start"}>
              {this.props.parentCategories.map((n, i) => (
                <Link key={i} to={`/produtos?${new URLSearchParams({ id: n.id, lvl: i + 1 }).toString()}`}>
                  <Type.h4 className={`${this.props.parentCategories.length - 1 === i ? "active" : ""}`}>{n.name}</Type.h4>
                  {this.props.parentCategories.length - 1 !== i && <Type.h4>/</Type.h4>}
                </Link>
              ))}
            </NanoFlex>
          )}
          <NanoFlex className="header" flexDirection={this._isMobile ? "column" : "row"}>
            <NanoFlex flexDirection={"row"} justifyContent={"flex-start"}>
              <Type.h5>
                <strong>{this.state.results?.count ?? 0}</strong> resultados {hasTerm ? "para" : ""}
              </Type.h5>
              {hasTerm && (
                <Type.h3>
                  <strong>&nbsp;"{this.props.filter?.term}"</strong>
                </Type.h3>
              )}
            </NanoFlex>
            <NanoFlex flexDirection={"row"} justifyContent={"flex-end"}>
              <NanoFlex className="resultsPerPageContainer">
                <SingleSelectionDropdown
                  hideSearch
                  preventUnselect
                  selected={this.state.orderBy}
                  options={[
                    { id: 1, name: "Mais Recentes" },
                    { id: 2, name: "Preço Ascendente" },
                    { id: 3, name: "Preço Descendente" },
                  ]}
                  onChangeSelection={(opt) => this.setState({ orderBy: opt.id })}
                />
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          <ProductGrid
            data={this.state.results?.products ?? []}
            params={{ id: this.props.filter?.categoryId, lvl: this.props.filter?.categoryLevel }}
          />
          {totalPages > 1 && (
            <NanoFlex className="pagging" flexDirection={"column"}>
              <NanoFlex className="totalResults">
                <Type.h5>
                  <strong>{this.state.results?.count ?? 0}</strong> resultados
                </Type.h5>
              </NanoFlex>
              <NanoFlex className="pageContainer">
                {this.state.page > 1 && (
                  <NanoFlex className="page arrow" onClick={() => this.setState({ page: this.state.page - 1 })}>
                    <ArrowL />
                  </NanoFlex>
                )}
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((v) => {
                  if (v <= this.state.page + 2 && v >= this.state.page - 2) {
                    return (
                      <NanoFlex
                        key={v}
                        className={`page ${this.state.page === v ? "selected" : ""}`}
                        onClick={() => {
                          if (v !== this.state.page) {
                            this.setState({ page: v });
                          }
                        }}
                      >
                        <Type.h4>{v}</Type.h4>
                      </NanoFlex>
                    );
                  }
                })}
                {this.state.page < totalPages && (
                  <NanoFlex className="page arrow" onClick={() => this.setState({ page: this.state.page + 1 })}>
                    <ArrowR />
                  </NanoFlex>
                )}
              </NanoFlex>
            </NanoFlex>
          )}
        </div>
      </StyledProductResults>
    );
  }
}

var productResults = withParamsAndNavigate(ProductResults);
export { productResults as ProductResults };
