import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import { withParamsAndNavigate, getQueryStringObj } from "../../Global/tools.js";
import clientService from "../../../services/ClientService.js";

//Icons
import { ReactComponent as ArrowL } from "../../Global/Icons/arrowL.svg";
import { ReactComponent as LocationDot } from "../../Global/Icons/locationDot.svg";
import { ReactComponent as Edit } from "../../Global/Icons/edit.svg";
import { ReactComponent as Delete } from "../../Global/Icons/delete.svg";

import { ClientAddressItem } from "./ClientAddressItem.js";

const StyledClientAddress = styled(NanoFlex)`
  padding: 20px;
  box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
  border: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};

  .back {
    cursor: pointer;
    width: auto;
    gap: 5px;
    padding: 5px 0px;

    .backIcon {
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .backTitle {
      border-bottom: 2px solid ${(props) => props.theme.color.main.font};
    }
  }

  .title {
    h2 {
      font-weight: bold;
    }

    .btnAdd {
      padding: 5px;
      cursor: pointer;
      user-select: none;
      max-width: 200px;
      height: 40px;
      background-color: ${(props) => props.theme.color.colorsPalette.red};
      gap: 10px;
      svg {
        height: 25px;
        width: 25px;

        fill: white;
      }
      h3,
      h4 {
        color: white;
        text-align: center;
      }
    }
  }

  .noAddressContainer {
    height: 25vh;
    gap: 20px;
    .icon {
      height: auto;
      svg {
        width: 24px;
        height: 24px;

        fill: ${(props) => props.theme.color.colorsPalette.lightBlack};
      }
    }

    .message {
      height: auto;
      h2 {
        font-weight: bold;
        color: ${(props) => props.theme.color.colorsPalette.lightBlack};
      }
    }
  }

  .addressContainer {
    padding: 20px 0px;
    gap: 20px;
    .addressItem {
      padding: 20px;
      box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
      border: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
      gap: 10px;

      .addressItemBlock {
        width: auto;
        gap: 10px;
        .name {
          h3 {
            font-weight: bold;
          }
        }

        .btnContainer {
          gap: 30px;
          .btn {
            cursor: pointer;
            width: 44px;
            height: 44px;
            border: ${(props) => props.theme.color.colorsPalette.lighterGrey} 1px solid;
            background: #fbfbfb;
            svg {
              width: 24px;
              height: 24px;
              path {
                fill: black;
              }
            }
          }
        }
      }
    }
  }
`;

class ClientAddress extends BaseComponent {
  constructor(props) {
    super(props);

    let param = getQueryStringObj(this.props.location.search);
    this.state = {
      addressItems: null,
      editAddress: null,
      createAddress: param?.create ? true : false,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.getAddresses();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getAddresses = () => {
    clientService
      .getAddresses()
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
            this.clientLogout();
        }
      })
      .then((data) => {
        this.setState({ addressItems: data ?? [] });
      });
  };

  deleteAddress = (id) => {
    clientService.deleteAddress(id).then((response) => {
      if (response.ok) {
        this.getAddresses();
      }
    });
  };

  onItemBack = (callback) => {
    this.setState({ createAddress: false, editAddress: null }, callback);
  };

  onSave = () => {
    this.onItemBack(() => {
      this.getAddresses();
    });
  };

  render() {
    if (this.state.createAddress || this.state.editAddress)
      return <ClientAddressItem address={this.state.editAddress} onBackClick={() => this.onItemBack()} onSave={this.onSave} />;

    return (
      <StyledClientAddress flexDirection={"column"} alignItems={"flex-start"}>
        {this._isMobile && (
          <NanoFlex className="back" justifyContent={"flex-start"} onClick={this.props.onBackClick}>
            <NanoFlex className="backIcon">
              <ArrowL />
            </NanoFlex>
            <NanoFlex className="backTitle">
              <Type.h4>Voltar</Type.h4>
            </NanoFlex>
          </NanoFlex>
        )}
        <NanoFlex className="title" justifyContent={"flex-start"}>
          <NanoFlex justifyContent={"flex-start"}>
            <Type.h2>As Minhas Moradas</Type.h2>
          </NanoFlex>
          <NanoFlex justifyContent={"flex-end"}>
            <NanoFlex className="btnAdd" onClick={() => this.setState({ createAddress: true })}>
              <LocationDot />
              {this._isMobile && <Type.h4>Adicionar Morada</Type.h4>}
              {!this._isMobile && <Type.h3>Adicionar Morada</Type.h3>}
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
        {this.state.addressItems && this.state.addressItems.length === 0 && (
          <NanoFlex className="noAddressContainer" flexDirection={"column"}>
            <NanoFlex className="icon">
              <LocationDot />
            </NanoFlex>
            <NanoFlex className="message">
              <Type.h2>Ainda não tens moradas.</Type.h2>
            </NanoFlex>
          </NanoFlex>
        )}
        {this.state.addressItems && this.state.addressItems.length > 0 && (
          <NanoFlex className="addressContainer" flexDirection="column">
            {this.state.addressItems.map((s) => {
              return (
                <NanoFlex key={s.id} className="addressItem" alignItems="flex-start" justifyContent="space-between">
                  <NanoFlex className="addressItemBlock" flexDirection="column">
                    <NanoFlex className="name" justifyContent="flex-start">
                      <Type.h3>{s.name}</Type.h3>
                    </NanoFlex>
                    <NanoFlex justifyContent="flex-start">
                      <Type.h3>NIF: {s.taxNumber}</Type.h3>
                    </NanoFlex>
                    <NanoFlex justifyContent="flex-start">
                      <Type.h3>
                        {s.address}, {s.postCode}
                      </Type.h3>
                    </NanoFlex>
                    <NanoFlex justifyContent="flex-start">
                      <Type.h3>Tel: {s.phone}</Type.h3>
                    </NanoFlex>
                  </NanoFlex>
                  <NanoFlex className="addressItemBlock" flexDirection="column">
                    <NanoFlex className="btnContainer" flexDirection="column">
                      <NanoFlex className="btn btnEdit" onClick={() => this.setState({ editAddress: s })}>
                        <Edit />
                      </NanoFlex>
                      <NanoFlex className="btn btnDelete" onClick={() => this.deleteAddress(s.id)}>
                        <Delete />
                      </NanoFlex>
                    </NanoFlex>
                  </NanoFlex>
                </NanoFlex>
              );
            })}
          </NanoFlex>
        )}
      </StyledClientAddress>
    );
  }
}

var clientAddress = withParamsAndNavigate(ClientAddress);
export { clientAddress as ClientAddress };
