import { Component } from "react";
// import "moment/locale/en-gb";
// import "moment/locale/fr";
// import t from "./Global/translate.json";
import { BaseService } from "../services/BaseService.js";

export default class BaseComponent extends Component {
  constructor(props) {
    super(props);

    // this.setLanguage(this.getLanguage());

    this._baseService = new BaseService();
    this._user = this._baseService.getUser();
    this._isMobile = this.isMobile();
  }

  componentDidMount() {
    // window.addEventListener("languageChange", this.onLanguageChange, false);
    window.addEventListener("resize", this.onResize, false);
    window.addEventListener("clientLogin", this.onClientLogin, false);
    window.addEventListener("clientLogout", this.onClientLogout, false);
  }

  //   onLanguageChange = (e) => {
  //     //Set Language
  //     this.setLanguage(e.detail);
  //     //Force Component Update
  //     this.forceUpdate();
  //   };

  onResize = (e) => {
    let newIsMobile = this.isMobile();
    if (newIsMobile !== this._isMobile) {
      //Set Is Mobile
      this._isMobile = newIsMobile;
      //Force Component Update
      this.forceUpdate();
      //Trigger onIsMobileChange
      window.dispatchEvent(new Event("isMobileChange"));
    }
  };

  componentWillUnmount() {
    // window.removeEventListener("languageChange", this.onLanguageChange, false);
    window.removeEventListener("resize", this.onResize, false);
    window.removeEventListener("clientLogin", this.onClientLogin, false);
    window.removeEventListener("clientLogout", this.onClientLogout, false);
  }

  isMobile = () => {
    return window.innerWidth <= 800;
  };

  onClientLogin = () => {
    this._user = this._baseService.getUser();
    /*Force Update*/
    this.forceUpdate();
  };

  onClientLogout = () => {
    this._user = this._baseService.getUser();
    /*Force Update*/
    this.forceUpdate();
  };

  clientLogin = (data) => {
    /* Set User */
    this._baseService.setToken(data);
    /* Trigger Client Login Event */
    window.dispatchEvent(new Event("clientLogin"));
  };

  clientLogout = (preventRedirect) => {
    /*Remove User From LocalStorage */
    this._baseService.removeToken();
    if (!preventRedirect) {
        /*Redirect To Homepage */
        this.props.navigate("/");
    }
    /* Trigger Client Logout Event */
    window.dispatchEvent(new Event("clientLogout"));
  };
}
