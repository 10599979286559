import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import moment from "moment";
import BaseComponent from "../../BaseComponent";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import Image from "../../Global/Elements/Image.js";
import {
  getQueryStringObj,
  withParamsAndNavigate,
  formatNumDefault,
  flattenByKey,
  findRecursive,
  downloadUrl,
  getVariationRefFormat,
} from "../../Global/tools.js";

import productService from "./../../../services/ProductService.js";
import categoriesService from "./../../../services/CategoriesService.js";
import shopService from "./../../../services/ShopService.js";

import { ReactComponent as Heart } from "../../Global/Icons/heart.svg";
import { ReactComponent as Cart } from "../../Global/Icons/cartShoppingSolid.svg";
import { ReactComponent as Pdf } from "../../Global/Icons/pdf.svg";

//FORMS
import SingleSelectionDropdown from "../../Global/Elements/Forms/SingleSelectionDropdown";

class ImagesContainer extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedImage: 1,
    };

    this.errorImages = [];
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.params) !== JSON.stringify(this.props.params)) {
      this.errorImages = [];
      this.setState({
        selectedImage: 1,
      });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <NanoFlex className="productImg" flexDirection={"column"}>
        <NanoFlex className="mainImage">
          <Image imageUrl={`/api/products/${this.props.productId}/${this.state.selectedImage}.jpg`} />
        </NanoFlex>
        <NanoFlex className="imagesContainerScroll">
          {this.errorImages.length < 7 && !this._isMobile && (
            <NanoFlex className="imagesContainer">
              {Array.from({ length: 8 }, (_, i) => i + 1)
                .filter((v) => !this.errorImages.includes(v))
                .map((v) => {
                  return (
                    <NanoFlex
                      key={v}
                      className={`image ${this.state.selectedImage === v ? "selected" : ""}`}
                      onClick={() => this.setState({ selectedImage: v })}
                    >
                      <Image
                        imageUrl={`/api/products/${this.props.productId}/${v}.jpg`}
                        onError={() => {
                          this.errorImages = [...this.errorImages, v];
                          this.forceUpdate();
                        }}
                      />
                    </NanoFlex>
                  );
                })}
            </NanoFlex>
          )}
        </NanoFlex>
      </NanoFlex>
    );
  }
}

const StyledProductDetails = styled(NanoFlex)`
  /* min-height: calc(100vh - 170px); */
  padding: 30px 5vw;
  .productDetailsContainer {
    .navigation {
      padding-bottom: 20px;
      height: 20px;
      a {
        display: flex;
        text-decoration: none;

        h4 {
          color: ${(props) => props.theme.color.colorsPalette.grey};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:not(:first-child) {
            padding: 0px 3px;
          }
          &.active {
            font-weight: bold;
          }
        }

        &:last-child {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .productContainer {
      padding: 40px;
      box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
      border: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};

      .productImg {
        gap: 10px;

        .mainImage {
          img {
            width: 100%;
            height: 100%;
            max-height: 400px;
            max-width: 500px;
            border: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
            object-fit: contain;
          }
        }

        .imagesContainerScroll {
          max-width: 500px;
          width: 40vw;
          overflow: auto;
          .imagesContainer {
            width: auto;
            gap: 15px;

            .image {
              border: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
              min-height: 100px;
              min-width: 120px;
              max-height: 100px;
              max-width: 120px;
              img {
                width: 100%;
                height: 100%;
                max-height: 90px;
                max-width: 110px;
                object-fit: contain;
              }

              &:not(.selected) {
                cursor: pointer;
                opacity: 0.7;
              }
            }
          }
        }
      }

      .productInfo {
        gap: 30px;
        padding: ${(props) => (props.isMobile ? "10px" : "30px")};
        .productTitle {
          /* padding: 20px 0px; */
        }

        .pdfContainer {
          gap: 10px;
          svg {
            cursor: pointer;
            height: 25px;
            width: 25px;
          }
        }

        .categoryContainer {
          .category {
            h4 {
              padding: 0px 3px 0px 0px;

              &.categoryItem {
                color: ${(props) => props.theme.color.colorsPalette.red};
              }
            }
          }
          .brand {
            h4 {
              padding: 0px 3px 0px 0px;
            }
          }
        }

        .variantContainer {
          max-width: 300px;
          /* padding: 30px 0px; */
          /* gap: 30px;
          .variantItem {
            width: auto;
            height: 50px;
            min-width: 200px;
            background-color: white;
            border: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};

            &:not(.active) {
              cursor: pointer;
            }

            &.active {
              background-color: ${(props) => props.theme.color.colorsPalette.lightBlack};

              h3 {
                color: white;
              }
            }
          } */
        }

        .priceContainer {
          gap: 5px;
          .price {
            width: auto;
            h2 {
              white-space: nowrap;
              font-size: 30px;
              color: ${(props) => props.theme.color.colorsPalette.red};
              font-weight: bold;
            }
            h5 {
              height: 25px;
              margin-left: 2px;
              color: ${(props) => props.theme.color.colorsPalette.red};
              font-weight: bold;
              vertical-align: top;
            }

            .iva {
              height: 25px;

              h5 {
                height: auto;
              }
            }

            &.hasPromo {
              h2 {
                text-decoration: line-through;
                font-size: 20px;
                color: ${(props) => props.theme.color.colorsPalette.grey};
                font-weight: normal;
              }
              h5 {
                height: 15px;
                color: ${(props) => props.theme.color.colorsPalette.grey};
                font-weight: normal;
              }
            }
          }

          .warningPromo {
            h4 {
              color: ${(props) => props.theme.color.colorsPalette.grey};
            }
          }
        }

        .warningPrice {
          h4 {
            color: ${(props) => props.theme.color.colorsPalette.grey};
          }
        }

        .addToCart {
          gap: 10px;
          .amountContainer {
            width: auto;
            gap: 10px;
            ${(props) =>
              props.isMobile &&
              css`
                padding: 10px 0px;
              `}
            .btnAmount {
              min-width: 40px;
              width: 40px;
              height: 40px;
              border: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
              user-select: none;

              &.btnMinus,
              &.btnPlus {
                cursor: pointer;
              }
            }
          }

          .btnAddToCart {
            cursor: pointer;
            user-select: none;
            max-width: 250px;
            height: 40px;
            background-color: ${(props) => props.theme.color.colorsPalette.red};
            gap: 10px;
            svg {
              height: 25px;
              width: 25px;

              fill: white;
            }
            h3 {
              color: white;
            }
          }

          .favorite {
            padding-left: 20px;
            width: auto;
            svg {
              fill: ${(props) => (props.isFavorite ? props.theme.color.colorsPalette.red : props.theme.color.colorsPalette.grey)};
              height: 30px;
            }
          }
        }
      }

      .productDescriptions {
        padding-top: 50px;
        .productTabs {
          gap: 20px;
          .tabItem {
            cursor: pointer;
            width: auto;
            border-bottom: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};

            h4 {
              color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
            }

            &.active {
              border-bottom: 2px solid ${(props) => props.theme.color.colorsPalette.red};

              h4 {
                color: black;
                font-weight: bold;
              }
            }
          }
        }

        .productTabsContent {
          padding: 20px 0px;
          h4 {
            white-space: break-spaces;
          }
        }
      }
    }
  }
`;

class ProductDetails extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      // idProduct: Number(this.props.params.id),
      tag: this.props.params.tag,
      data: null,
      selectedVariantion: null,
      categories: null,
      loadingAddShop: false,
      shopQuantity: 1,
    };
  };

  componentDidMount() {
    super.componentDidMount();
    this.GetProductDetails();
    this.getCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.params) !== JSON.stringify(this.props.params)) {
      this.setState(this.getDefaultState(), () => {
        this.GetProductDetails();
      });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  GetProductDetails = () => {
    productService
      .getProductDetails(this.state.tag)
      .then((response) => {
        if (response.ok) {
          if (response.status === 204) {
            this.props.navigate("/");
          } else {
            return response.json();
          }
        }
      })
      .then((data) => {
        this.setState({
          data: data,
          selectedVariantion: data.variations[0],
        });
      });
  };

  getNavigationModel = () => {
    var params = getQueryStringObj(this.props.location.search) ?? {};
    if (params && params.id && params.lvl) {
      return {
        categoryId: Number(params.id),
        categoryLevel: Number(params.lvl),
      };
    }
    return null;
  };

  hasNavigation = () => {
    return this.getNavigationModel() !== null;
  };

  getCategories = () => {
    if (this.hasNavigation()) {
      categoriesService
        .getCategories()
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          this.setState({ categories: data });
        });
    }
  };

  getParentCategories = () => {
    var nav = this.getNavigationModel();
    let currentCategory = findRecursive(this.state.categories ?? [], (c, l) => c.id === nav?.categoryId && l === nav?.categoryLevel);
    if (currentCategory) return [currentCategory, ...(flattenByKey(currentCategory, "parent") ?? [])].reverse();
    return [];
  };

  buildVariationsSelect = () => {
    return this.state.data.variations.map((v) => {
      return {
        id: v.idVariation,
        name: `${v.description} ${v.name}`,
      };
    });
  };

  addToShop = () => {
    if (this._user) {
      this.setState({ loadingAddShop: true }, () => {
        shopService
          .addShopLine({
            IdProductVariation: this.state.selectedVariantion.idVariation,
            Quantity: this.state.shopQuantity,
          })
          .then((response) => {
            this.setState({ loadingAddShop: false }, () => {
              if (response.ok) {
                window.dispatchEvent(new Event("updateShopCount"));
                this.props.navigate("/carrinho");
              }
              else if (response.status === 401) {
                  this.clientLogout(true);
                  window.dispatchEvent(new Event("openLoginModal"));
              }
            });
          })
          .then((data) => {});
      });
    } else {
      window.dispatchEvent(new Event("openLoginModal"));
    }
  };

  render() {
    if (!this.state.data) return null;
    let parentCategories = this.getParentCategories();
    let hasPromo = (this.state.selectedVariantion?.promo ?? 0) > 0;
    return (
      <StyledProductDetails isMobile={this._isMobile} alignItems={"flex-start"}>
        <NanoFlex className="productDetailsContainer" flexDirection={"column"}>
          {parentCategories && parentCategories.length > 1 && (
            <NanoFlex className="navigation" justifyContent={"flex-start"}>
              {parentCategories.map((n, i) => (
                <Link key={i} to={`/produtos?${new URLSearchParams({ id: n.id, lvl: i + 1 }).toString()}`}>
                  <Type.h4 className={`${parentCategories.length - 1 === i ? "active" : ""}`}>{n.name}</Type.h4>
                  {parentCategories.length - 1 !== i && <Type.h4>/</Type.h4>}
                </Link>
              ))}
            </NanoFlex>
          )}
          <NanoFlex className="productContainer" flexDirection={"column"}>
            <NanoFlex alignItems={"flex-start"} flexDirection={this._isMobile ? "column" : "row"}>
              <ImagesContainer productId={this.state.data.id} />
              <NanoFlex className="productInfo" flexDirection={"column"} alignItems={"flex-start"}>
                <NanoFlex className="productTitle" justifyContent={"flex-start"}>
                  <Type.h2>{this.state.data.name}</Type.h2>
                </NanoFlex>
                {this.state.data.pdfNames && this.state.data.pdfNames.length > 0 && (
                  <NanoFlex className="pdfContainer" justifyContent={"flex-start"}>
                    {this.state.data.pdfNames?.map((p) => {
                      return (
                        <Pdf
                          key={p}
                          onClick={() => {
                            window.open(`/api/products/${this.state.data.id}/pdf/${p}`, "_blank").focus();
                          }}
                        />
                      );
                    })}
                  </NanoFlex>
                )}
                <NanoFlex className="categoryContainer" flexDirection={"column"}>
                  <NanoFlex justifyContent={"flex-start"}>
                    <Type.h4>REF: {getVariationRefFormat(this.state.selectedVariantion?.ref)}</Type.h4>
                  </NanoFlex>
                  <NanoFlex className="brand" justifyContent={"flex-start"}>
                    <Type.h4>Marca:</Type.h4>
                    <Type.h4 className="brandItem">{this.state.data.brand}</Type.h4>
                  </NanoFlex>
                </NanoFlex>
                {this.state.data.hasVariations && (
                  <NanoFlex className="variantContainer">
                    <SingleSelectionDropdown
                      hideSearch
                      preventUnselect
                      selected={this.state.selectedVariantion?.idVariation}
                      options={this.buildVariationsSelect()}
                      onChangeSelection={(v) => {
                        this.setState({ selectedVariantion: this.state.data.variations.find((d) => d.idVariation === v.id) });
                      }}
                    />
                  </NanoFlex>
                )}
                <NanoFlex className="priceContainer" flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
                  <NanoFlex className={`price ${hasPromo ? "hasPromo" : ""}`} justifyContent={"flex-start"} alignItems={"flex-start"}>
                    <Type.h2>{formatNumDefault(this.state.selectedVariantion?.pvp ?? 0, 2, 2)}</Type.h2>
                    <Type.h5>€</Type.h5>
                    <NanoFlex className="iva" alignItems="flex-end">
                      <Type.h5>c/iva</Type.h5>
                    </NanoFlex>
                  </NanoFlex>
                  {hasPromo && (
                    <React.Fragment>
                      <NanoFlex className="price" justifyContent={"flex-start"} alignItems={"flex-start"}>
                        <Type.h2>{formatNumDefault(this.state.selectedVariantion?.promo ?? 0, 2, 2)}</Type.h2>
                        <Type.h5>€</Type.h5>
                      </NanoFlex>
                      {this.state.selectedVariantion?.promoDate && (
                        <NanoFlex className="warningPromo" flexDirection={"column"} alignItems={"flex-start"} justifyContent={"flex-start"}>
                          <Type.h4>
                            PROMOÇÃO DISPONIVEL ATÉ {moment(this.state.selectedVariantion?.promoDate).format(this.props.theme.moment.displayFormat)}
                          </Type.h4>
                        </NanoFlex>
                      )}
                    </React.Fragment>
                  )}
                </NanoFlex>
                <NanoFlex className="warningPrice" justifyContent={"flex-start"}>
                  <Type.h4>{(this.state.selectedVariantion?.stock ?? 0) > 0 ? "STOCK EM LOJA" : "DISPONÍVEL APENAS VIA ONLINE"}</Type.h4>
                </NanoFlex>
                <NanoFlex className="warningPrice" justifyContent={"flex-start"}>
                  <Type.h4>ESTES PREÇOS PODEM SER ALTERADOS SEM AVISO PREVIO</Type.h4>
                </NanoFlex>
                <NanoFlex className="addToCart" justifyContent={"flex-start"} flexDirection={this._isMobile ? "column" : "row"}>
                  <NanoFlex className="amountContainer">
                    <NanoFlex
                      className="btnAmount btnMinus"
                      onClick={() =>
                        this.setState({ shopQuantity: this.state.shopQuantity > 1 ? this.state.shopQuantity - 1 : this.state.shopQuantity })
                      }
                    >
                      <Type.h3>-</Type.h3>
                    </NanoFlex>
                    <NanoFlex className="btnAmount">
                      <Type.h3>{this.state.shopQuantity}</Type.h3>
                    </NanoFlex>
                    <NanoFlex className="btnAmount btnPlus" onClick={() => this.setState({ shopQuantity: this.state.shopQuantity + 1 })}>
                      <Type.h3>+</Type.h3>
                    </NanoFlex>
                  </NanoFlex>
                  <NanoFlex className="btnAddToCart" onClick={this.addToShop}>
                    <Cart />
                    <Type.h3>ADICIONAR</Type.h3>
                  </NanoFlex>
                  {/* <NanoFlex className="favorite" justifyContent={"flex-end"}>
                    <Heart />
                  </NanoFlex> */}
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="productDescriptions" flexDirection={"column"}>
              <NanoFlex className="productTabs" justifyContent={"flex-start"}>
                <NanoFlex className="tabItem active">
                  <Type.h4>DESCRIÇÃO</Type.h4>
                </NanoFlex>
                {/* <NanoFlex className="tabItem">
                  <Type.h4>INFORMAÇÃO ADICIONAL</Type.h4>
                </NanoFlex> */}
              </NanoFlex>
              <NanoFlex className="productTabsContent" justifyContent={"flex-start"}>
                <Type.h4>{this.state.data.description}</Type.h4>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledProductDetails>
    );
  }
}

var productDetails = withParamsAndNavigate(ProductDetails);
export { productDetails as ProductDetails };
