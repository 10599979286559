import React from "react";
import { urlGetHeadAsync } from "../tools.js";

class Image extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      error: false,
      loading: true,
      lastModified: "",
    };
  };

  async componentDidMount() {
    await this.validateImage();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.error !== this.state.error && this.props.onError) {
      this.props.onError(this.state.error);
    }
    if ((prevProps.id !== this.props.id || prevProps.imageUrl !== this.props.imageUrl) && this.state.error) {
      this.setState(this.getDefaultState(), async () => {
        await this.validateImage();
      });
    }
  }

  validateImage = async () => {
    var res = await urlGetHeadAsync(this.props.imageUrl);
    if (res != null) {
      let lastModified = res?.headers?.get("Last-Modified") ?? "";
      if (lastModified) {
        this.setState({ lastModified: new Date(lastModified).getTime(), loading: false });
      }
    } else this.setState({ error: true, loading: false });
  };

  handleImageErrored = () => {
    this.setState({ error: true });
  };

  render() {
    if (this.state.loading) return null;
    /*Fetch Last Modified to prevent file caching on update*/
    return (
      <img
        src={this.state.error ? "/Images/small-icon.png" : `${this.props.imageUrl}?last=${this.state.lastModified ?? -1}`}
        alt={this.props.alt}
        onError={this.handleImageErrored}
      />
    );
  }
}

export default Image;
