import React from "react";
import styled, { css } from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import { Link } from "react-router-dom";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import Modal from "../../Global/Elements/Modal.js";
import { withParamsAndNavigate } from "../../Global/tools.js";

/*Payment Methods*/
import { ReactComponent as Mb } from "../../Global/Icons/paymentMethods/mb.svg";
import { ReactComponent as MbWay } from "../../Global/Icons/paymentMethods/mbway.svg";
import { ReactComponent as Visa } from "../../Global/Icons/paymentMethods/visa.svg";
import { ReactComponent as Mastercard } from "../../Global/Icons/paymentMethods/mastercard.svg";
import { ReactComponent as Paypal } from "../../Global/Icons/paymentMethods/paypal.svg";

/*Social Media*/
import { ReactComponent as Facebook } from "../../Global/Icons/facebook.svg";
import { ReactComponent as Instagram } from "../../Global/Icons/instagram.svg";

import pageService from "./../../../services/PageService.js";

import ContactUs from "./ContactUs.js";

const StyledFooter = styled(NanoFlex)`
  .categories {
    padding: 30px;
    gap: 30px;
    height: 25vw;
    .categoryItem {
      background-size: cover;
      padding-bottom: 10px;

      .title {
        width: auto;
        height: auto;
        padding: 30px 50px;
        background: ${(props) => props.theme.color.colorsPalette.lightBlack};
        opacity: 0.8;
        h2 {
          color: ${(props) => props.theme.color.main.white};
        }
      }
    }
  }

  .partners {
    background: white;
    padding: 30px;

    .items {
      .itemsTitle {
        padding: 15px;
      }

      .itemsContainer {
        .itemsImg {
          width: auto;
          padding: 0px 10px;
        }
      }
    }
  }

  .contactUs {
    background: ${(props) => props.theme.color.colorsPalette.lighterGrey};
    ${(props) =>
      !props.isMobile &&
      css`
        padding: 45px 0px 45px 45px;
      `}
    ${(props) =>
      props.isMobile &&
      css`
        padding: 5vw;
      `}

    .location {
      .locationImage {
        width: auto;
        height: auto;
        ${(props) =>
          !props.isMobile &&
          css`
            padding: 0px 45px;
          `}
        img {
          max-height: 300px;
        }
      }

      .locationInfo {
        padding: 5px;
        .company,
        .address,
        .phone,
        .email {
          padding: 5px 0px;
        }

        .contact {
          cursor: pointer;
          padding: 10px 0px;
          font-weight: bold;
          text-decoration: underline;
        }
      }

      .socialMediaContainer {
        gap: 20px;
        .socialMedia {
          cursor: pointer;
          width: auto;
          svg {
            width: 32px;
            height: 32px;
          }
        }
      }
    }

    .contact {
      .contactInfo {
        width: auto;
        background: ${(props) => props.theme.color.colorsPalette.lightBlack};
        padding: 25px 50px;

        .icon {
          padding-right: 40px;
          svg {
            height: 50px;
            fill: ${(props) => props.theme.color.main.white};
          }
        }

        .info {
          white-space: pre;
          h1,
          h3 {
            color: ${(props) => props.theme.color.main.white};
          }
        }
      }
    }
  }

  .paymentMethods {
    background: white;
    padding: 30px;

    .methods {
      .methodsTitle {
        padding: 15px;
      }

      .methodsContainer {
        gap: 5px;
        .methodIcon {
          width: auto;
          padding: 0px 10px;
        }
      }
    }

    .complaints {
      padding: 15px;
      img {
        height: 70px;
      }
    }
  }

  .terms {
    background: ${(props) => props.theme.color.colorsPalette.lightBlack};
    padding: 30px;

    a {
      all: unset;
      cursor: pointer;
    }

    h4 {
      color: white;

      &:first-child {
        padding-bottom: 15px;
      }
    }
  }
`;

class Footer extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      instagramUrl: null,
      facebookUrl: null,
      customInstagramUrl: null,
      customFacebookUrl: null,
      openContactUsModal: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.getSocialMediaUrls();
    window.addEventListener("changeSocialMediaUrl", this.changeSocialMediaUrl, false);
    window.addEventListener("openContactUsModal", this.triggerOpenContactUsModal, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location?.pathname !== this.props.location?.pathname || prevProps.location?.search !== this.props.location?.search) {
      this.setState({
        customInstagramUrl: null,
        customFacebookUrl: null,
      });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener("changeSocialMediaUrl", this.changeSocialMediaUrl, false);
    window.removeEventListener("openContactUsModal", this.triggerOpenContactUsModal, false);
  }

  //window.dispatchEvent(new CustomEvent("changeSocialMediaUrl", { detail: { instagram: "<Url_Here>", facebook: "<Url_Here>" } }));
  changeSocialMediaUrl = (e) => {
    this.setState({
      customInstagramUrl: e.detail?.instagram,
      customFacebookUrl: e.detail?.facebook,
    });
  };

  getSocialMediaUrls = () => {
    pageService
      .getContent(3)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        if (data) {
          this.setState({
            instagramUrl: data.instagram,
            facebookUrl: data.facebook,
          });
        }
      });
  };

  triggerOpenContactUsModal = () => {
    this.setState({ openContactUsModal: true });
  };

  render() {
    return (
      <StyledFooter isMobile={this._isMobile} flexDirection={"column"} justifyContent={"flex-start"}>
        <NanoFlex className="contactUs" alignItems={"flex-end"}>
          <NanoFlex className="location" flexDirection={this._isMobile ? "column" : "row"}>
            <NanoFlex className="locationImage" justifyContent={"flex-start"}>
              <img src="/Images/location.png" />
            </NanoFlex>
            <NanoFlex className="locationInfo" flexDirection={"column"} alignItems={"flex-start"}>
              <Type.h1 className="company">
                <b>YNNOWA</b>
              </Type.h1>
              <NanoFlex className="address" flexDirection={"column"} alignItems={"flex-start"}>
                <Type.h3>Rua Paz Nº10 2830-046 Quinta das Gateiras</Type.h3>
                <Type.h3>2830-216 Barreiro</Type.h3>
              </NanoFlex>
              <Type.h3 className="phone">21 080 18 84</Type.h3>
              {/* <a className="email" href="mailto:remo.diversos@gmail.com">
                <Type.h3>
                  <b>remo.diversos@gmail.com</b>
                </Type.h3>
              </a> */}
              <Type.h3 className="contact" onClick={() => this.setState({ openContactUsModal: true })}>
                CONTACTE-NOS
              </Type.h3>
              <NanoFlex className="socialMediaContainer" justifyContent={"flex-start"}>
                <a
                  href={
                    this.state.customInstagramUrl && this.state.customInstagramUrl.trim() !== ""
                      ? this.state.customInstagramUrl
                      : this.state.instagramUrl
                  }
                  target="_blank"
                >
                  <NanoFlex className="socialMedia">
                    <Instagram />
                  </NanoFlex>
                </a>
                <NanoFlex className="socialMedia">
                  <a
                    href={
                      this.state.customFacebookUrl && this.state.customFacebookUrl.trim() !== ""
                        ? this.state.customFacebookUrl
                        : this.state.facebookUrl
                    }
                    target="_blank"
                  >
                    <Facebook />
                  </a>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="paymentMethods" flexDirection={this._isMobile ? "column" : "row"}>
          <NanoFlex className="methods" flexDirection={"column"}>
            <NanoFlex className="methodsTitle" justifyContent={"flex-start"}>
              <Type.h3>MÉTODOS DE PAGAMENTO</Type.h3>
            </NanoFlex>
            <NanoFlex className="methodsContainer" justifyContent={"flex-start"} flexWrap={"wrap"}>
              <NanoFlex className="methodIcon">
                <Mb />
              </NanoFlex>
              <NanoFlex className="methodIcon">
                <MbWay />
              </NanoFlex>
              <NanoFlex className="methodIcon">
                <Visa />
              </NanoFlex>
              <NanoFlex className="methodIcon">
                <Mastercard />
              </NanoFlex>
              <NanoFlex className="methodIcon">
                <Paypal />
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="complaints" justifyContent={"flex-end"}>
            <a href="https://www.livroreclamacoes.pt/" target={"_blank"}>
              <img src="/Images/livro_reclamacoes.png" />
            </a>
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="terms" flexDirection={"column"} alignItems={"flex-start"}>
          <Type.h4>
            <Link to="/termos-e-condicoes">Termos e Condições Gerais</Link> | <Link to="/politica-de-privacidade">Política de Privacidade e Cookies</Link>
          </Type.h4>
          <Type.h4>© 2023 YNNOWA</Type.h4>
        </NanoFlex>
        <Modal show={this.state.openContactUsModal} content={<ContactUs onCancel={() => this.setState({ openContactUsModal: false })} />} />
      </StyledFooter>
    );
  }
}

var footer = withParamsAndNavigate(Footer);
export { footer as Footer };
