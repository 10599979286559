import { BaseService } from "./BaseService";

export class CategoriesService extends BaseService {
  getCategories(filter) {
    return this.request("api/Categories/GetCategories", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ filter }),
    });
  }
}

const categoriesService = new CategoriesService();

export default categoriesService;
