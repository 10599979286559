import moment from "moment";
import { Component } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "styled-components";

export const elementMatches = (el, selector) => {
  return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(
    el,
    selector,
  );
};

export const elementMatchesAny = (el, selectorArray) => {
  for (let idx = 0; idx < selectorArray.length; idx++) {
    let v = selectorArray[idx];
    if (elementMatches(el, v) || el.parentElement.closest(v)) {
      return true;
    }
  }
  return false;
};

export const urlExists = (url, callback) => {
  try {
    fetch(url, {
      method: "HEAD",
      cache: "no-cache",
    }).then((response) => {
      callback(response.status === 200, response);
    });
  } catch (error) {
    callback(false);
  }
};

export const urlExistsAsync = async (url) => {
  try {
    var response = await fetch(url, {
      method: "HEAD",
      cache: "no-cache",
    });
    if (response.status === 200) return true;
  } catch (error) {}
  return false;
};

export const urlGetHeadAsync = async (url) => {
  try {
    var response = await fetch(url, {
      method: "HEAD",
      cache: "no-cache",
    });
    if (response.status === 200) return response;
  } catch (error) {}
  return null;
};

export const validateEmailFormat = function (val) {
  var regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(val.toLowerCase());
};

export const getByPath = function (path, val) {
  return path.split(".").reduce((p, c) => p?.[c] || null, val);
};

export const downloadBlob = function (blob, filename) {
  var url = window.URL.createObjectURL(blob);
  var a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const downloadUrl = function (url, filename) {
  var a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const flattenByKeyArr = function (arr, key) {
  return arr ? arr.reduce((r, i) => [...r, i, ...flattenByKeyArr(i[key], key)], []) : [];
};

export const flattenByKey = function (val, key, arr) {
  if (val && val[key]) {
    let arrM = [...(arr ?? []), val[key]];
    return flattenByKey(val[key], key, arrM);
  } else {
    return arr;
  }
};

export const flattenElements = function (data) {
  return data.reduce((r, { children, ...rest }) => {
    r.push(rest);
    if (children) r.push(...flattenElements(children));
    return r;
  }, []);
};

export const findRecursive = function (array, predicate, currentLevel) {
  var result;
  if (!currentLevel) currentLevel = 0;
  currentLevel = currentLevel + 1;
  array.some(
    (o) =>
      (predicate(o, currentLevel) && (result = o)) ||
      (result = findRecursive(
        (o.items ?? []).map((v) => {
          return { parent: o, ...v };
        }),
        predicate,
        currentLevel,
        o,
      )),
  );
  return result;
};

export const randomInteger = function (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const randomDecimal = function (min, max, decimalPlaces) {
  return (Math.random() * (max - min) + min).toFixed(decimalPlaces ?? 2) * 1;
};

export const formatNum = (num, separator, fraction, maximumFractionDigits, minimumFractionDigits) => {
  let opt = {};
  if (maximumFractionDigits) opt.maximumFractionDigits = maximumFractionDigits;
  if (minimumFractionDigits) opt.minimumFractionDigits = minimumFractionDigits;
  var str = num.toLocaleString("en-US", opt);
  if (separator !== ",") str = str.replace(/,/g, separator);
  if (fraction !== ".") str = str.replace(/\./, fraction);
  return str;
};

export const formatNumDefault = (num, maximumFractionDigits, minimumFractionDigits) => {
  return formatNum(num, " ", ".", maximumFractionDigits, minimumFractionDigits);
};

export const paramsToObject = (entries) => {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
};

export const categoryFieldByLevel = (level) => {
  switch (level) {
    case 1:
      return "idFamilies";
    case 2:
      return "idCategories";
    case 3:
      return "idSubCategories";
    case 4:
      return "idSubSubCategories";
  }
  return "";
};

export const getQueryStringObj = (query) => {
  return paramsToObject(new URLSearchParams(query).entries());
};

export function withParamsAndNavigate(Component) {
  return (props) => <Component {...props} params={useParams()} navigate={useNavigate()} location={useLocation()} theme={useTheme()} />;
}

export function removeEmptyProperties(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

export function getVariationRefFormat(ref) {
  if (ref) {
    return `${ref.substring(0, 3)}.${ref.substring(3, 6)}.${ref.substring(6)}`;
  }
  return "";
}
