import styled, { css } from "styled-components";

const h1 = styled.h1`
  color: ${(props) => props.theme.color.main.font};
  font-size: 25px;
  text-transform: uppercase;
  line-height: 35px;
  i {
    font-style: italic;
  }
  b,
  strong {
    font-weight: bold;
    font-size: 30px;
    @media only screen and (max-width: 1550px) {
      font-size: 25px;
    }
  }
`;

const h2 = styled.h2`
  color: ${(props) => props.theme.color.main.font};
  font-size: 20px;
  line-height: 25px;
  b,
  strong {
    color: black;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const h3 = styled.h3`
  color: ${(props) => props.theme.color.main.font};
  font-size: 18px;
  line-height: 24px;
  b,
  strong {
    font-weight: bold;
  }
`;

const h4 = styled.h4`
  color: ${(props) => props.theme.color.main.font};
  font-size: 15px;
  line-height: 20px;
  b,
  strong {
    font-weight: bold;
  }
`;

const h5 = styled.h5`
  color: ${(props) => props.theme.color.main.font};
  font-size: 14px;
  b,
  strong {
    font-weight: bold;
  }
`;

const h6 = styled.h6`
  color: ${(props) => props.theme.color.main.font};
  font-size: 10px;
  i {
    font-style: italic;
  }
  b,
  strong {
    font-weight: bold;
  }
`;

const p = styled.p`
  color: ${(props) => props.theme.color.main.font};
  font-size: 14px;
  line-height: 18px;
  white-space: pre-line;
  i {
    font-style: italic;
  }
  b,
  strong {
    font-weight: bold;
  }
  em {
    font-weight: bold;
    text-transform: uppercase;
  }
  ${(props) =>
    props.justify &&
    css`
      text-align: justify;
    `}
`;

const Type = {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
};

export default Type;

/*
Doc
import Type from './Global/Typography.js';

H1
<Type.h1>Teste <b>Teste Bold</b></Type.h1>
H1 WHITE
<Type.h1 white>Teste <b>Teste Bold</b></Type.h1>

H2
<Type.h2>Teste <b>Teste Bold</b></Type.h2>

H3
<Type.h3>Teste <b>Teste Bold</b></Type.h3>

H4
<Type.h4>Teste <b>Teste Bold</b></Type.h4>

H5
<Type.h5>Teste <b>Teste Bold</b></Type.h5>

H6
<Type.h6>Teste <b>Teste Bold</b> <i>Teste It�lico</i> <i><b>Teste It�lico Bold</b></i></Type.h6>
H6 WHITE
<Type.h6 white>Teste <b>Teste Bold</b> <i>Teste It�lico</i> <i><b>Teste It�lico Bold</b></i></Type.h6>

P
<Type.p>Teste <b>Teste Bold</b> <i>Teste It�lico</i> <i><b>Teste It�lico Bold</b></i> <em>Teste EM</em></Type.p>
P WHITE
<Type.p white>Teste <b>Teste Bold</b> <i>Teste It�lico</i> <i><b>Teste It�lico Bold</b></i> <em>Teste EM</em></Type.p>
*/
