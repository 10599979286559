import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import { withParamsAndNavigate } from "../../Global/tools.js";

const StyledPrivacyPolicy = styled(NanoFlex)`
  padding: 0px 5vw;
  .sectionContainer {
    border-right: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
    border-left: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
    padding: 20px;
    gap: 5px;

    .gap {
      height: 10px;
    }

    .bold {
      font-weight: bold;
    }

    ul {
      margin-left: 40px;
      li {
        list-style-type: disc;
      }

      &.secondLevel {
        li {
          list-style-type: decimal;
        }
      }
    }

    .table {
      .header {
        border-bottom: 1px solid black;
      }
    }
  }
`;

class PrivacyPolicy extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledPrivacyPolicy flexDirection="column">
            <NanoFlex className="sectionContainer" flexDirection="column" alignItems="flex-start">
                <Type.h3>
                    Esta Política de Privacidade descreve como a sua informação pessoal é recolhida, usada e partilhada ao visitar ou fazer compras da Loja.
                </Type.h3>
                <NanoFlex className="gap"></NanoFlex>
                <Type.h3 className="bold">A INFORMAÇÃO PESSOAL QUE RECOLHEMOS</Type.h3>
                <Type.h3>
                    Quando visita o Site, podemos recolher automaticamente certas informações sobre o seu dispositivo, incluindo informação sobre o seu
                    navegador de internet, endereço de IP, fuso horário e algumas das cookies que estão instaladas no seu dispositivo.
                </Type.h3>
                <Type.h3>
                    Adicionalmente, enquanto navega pelo Site, podemos recolher informação sobre as páginas individuais ou os produtos que visualiza, os sites
                    ou termos de pesquisa que o direcionaram para o Site e a informação sobre como interage com o Site. Referimo-nos a esta informação
                    recolhida automaticamente como <span className="bold">Informação do Dispositivo</span>
                </Type.h3>
                <Type.h3>Recolhemos Informação do Dispositivo usando as tecnologias seguintes</Type.h3>
                <ul>
                    <li>
                        <Type.h3>
                            <span className="bold">Cookies</span> são ficheiros de dados colocados no seu dispositivo ou computador e frequentemente incluem um
                            identificador anónimo único.
                        </Type.h3>
                    </li>
                </ul>
                <Type.h3>
                    Além disso, quando realiza uma compra ou tenta realizar uma compra através do Site, recolhemos certa informação sua, incluindo o seu nome,
                    endereço de faturação, endereço de expedição.
                </Type.h3>
                <NanoFlex className="gap"></NanoFlex>
                <Type.h3>
                    Por <span className="bold">Informação Pessoal</span>, nesta Política de Privacidade, referimo-nos a Informação do Dispositivo e Informação
                    da Encomenda.
                </Type.h3>
                <Type.h3 className="bold">COMO USAMOS A SUA INFORMAÇÃO PESSOAL</Type.h3>
                <Type.h3>
                    Geralmente, usamos a Informação da Encomenda recolhida para cumprir qualquer encomenda realizada através do Site (incluindo o
                    processamento da sua informação de pagamento, tratar da expedição e fornecer-lhe as faturas e/ou confirmações da encomenda).
                </Type.h3>
                <Type.h3>Adicionalmente, usamos esta Informação da Encomenda para:</Type.h3>
                <ul>
                    <li>
                        <Type.h3>Comunicar consigo</Type.h3>
                    </li>
                    <li>
                        <Type.h3>Analisar as nossas encomendas em busca de potenciais riscos ou fraudes</Type.h3>
                    </li>
                    <li>
                        <Type.h3>
                            Quando alinhadas com as preferências que partilhou connosco, fornecer-lhe informação ou publicidade relacionada com os nossos produtos
                            ou serviços.
                        </Type.h3>
                    </li>
                </ul>
                <Type.h3>
                    Usamos a Informação de Dispositivo que recolhemos para nos ajudar a eliminar potenciais riscos e fraudes (especialmente, o seu endereço de
                    IP) e, mais frequentemente, para melhorar e otimizar o nosso Site.
                </Type.h3>
                <NanoFlex className="gap"></NanoFlex>
                <Type.h3 className="bold">PARTILHAR A SUA INFORMAÇÃO PESSOAL</Type.h3>
                <Type.h3>Também usamos a Google Analytics para nos ajudar a perceber como os nossos clientes usam a Ynnowa.</Type.h3>
                <Type.h3>
                    Finalmente, também podemos partilhar a sua Informação Pessoal para cumprir leis e regulamentos aplicáveis, para responder a intimações,
                    mandados de busca ou outros pedidos legais relativos a informação que recebemos, ou para proteger os nossos direitos de qualquer outra
                    forma.
                </Type.h3>
                <NanoFlex className="gap"></NanoFlex>
                <Type.h3 className="bold">PUBLICIDADE COMPORTAMENTAL</Type.h3>
                <Type.h3>
                    Usamos a sua Informação Pessoal para lhe fornecer anúncios direcionados ou comunicações de marketing que acreditamos que possam ser do seu
                    interesse.
                </Type.h3>
                <NanoFlex className="gap"></NanoFlex>
                <Type.h3 className="bold">OS SEUS DIREITOS</Type.h3>
                <Type.h3>
                    Se for residente na Europa, tem o direito de aceder à informação pessoal que temos sobre si e de pedir que a sua informação pessoal seja
                    corrigida, atualizada ou eliminada. Se desejar exercer este direito, por favor, contacte-nos.
                </Type.h3>
                <Type.h3>
                    Além disso, se for residente na Europa, realçamos que processamos a sua informação para cumprir contratos que possamos ter consigo (por
                    exemplo, se realizar uma encomenda através do Site) ou para, de outra qualquer forma, perseguir os nossos interesses comerciais legítimos
                    listados acima.
                </Type.h3>
                <Type.h3>
                    Tenha em atenção que a sua informação será transferida para fora da Europa, incluindo para o Canadá e para os Estados Unidos.
                </Type.h3>
                <NanoFlex className="gap"></NanoFlex>
                <Type.h3 className="bold">RETENÇÃO DE DADOS</Type.h3>
                <Type.h3>
                    Quando realizar uma encomenda através do Site, manteremos a sua Informação de Encomenda nos nossos registos, a não ser que nos peça para
                    eliminar a informação.
                </Type.h3>
                <NanoFlex className="gap"></NanoFlex>
                <Type.h3 className="bold">ALTERAÇÕES</Type.h3>
                <Type.h3>
                    Podemos atualizar esta política de privacidade ocasionalmente de forma a refletir, por exemplo, mudanças nas nossas práticas ou devido a
                    outros motivos operacionais, legais ou regulatórios.
                </Type.h3>
                <Type.h3>
                    Caso tenha mais questões e/ou precise de mais informação, não hesite em contactar-nos através do nosso site, www.ynnowa.com
                </Type.h3>
            </NanoFlex>
      </StyledPrivacyPolicy>
    );
  }
}

var privacyPolicy = withParamsAndNavigate(PrivacyPolicy);
export { privacyPolicy as PrivacyPolicy };
