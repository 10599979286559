import React from "react";
import { Link } from "react-router-dom";
import BaseComponent from "../../BaseComponent.js";
import styled, { css } from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import Modal from "../../Global/Elements/Modal.js";
import { withParamsAndNavigate } from "./../../Global/tools.js";

//Services
import clientService from "./../../../services/ClientService.js";

import { ClientAddressItemModal } from "../Client/ClientAddressItemModal.js";

import { ReactComponent as ArrowL } from "../../Global/Icons/arrowL.svg";
import { ReactComponent as LocationDot } from "../../Global/Icons/locationDot.svg";

const StyledShopBillingAddress = styled(NanoFlex)`
  gap: 10px;
  .back {
    cursor: pointer;
    width: auto;
    gap: 5px;

    .backIcon {
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .backTitle {
      border-bottom: 2px solid ${(props) => props.theme.color.main.font};
    }
  }

  .btnAddLink {
    text-decoration: none;
    .btnAdd {
      padding: 5px;
      cursor: pointer;
      user-select: none;
      max-width: 200px;
      height: 40px;
      background-color: ${(props) => props.theme.color.colorsPalette.red};
      gap: 10px;
      svg {
        height: 25px;
        width: 25px;

        fill: white;
      }
      h3,
      h4 {
        color: white;
        text-align: center;
      }
    }
  }

  .noAddressItemsContainer {
    height: 25vh;
    gap: 20px;
    .icon {
      height: auto;
      svg {
        width: 24px;
        height: 24px;

        fill: ${(props) => props.theme.color.colorsPalette.lightBlack};
      }
    }

    .message {
      height: auto;
      h2 {
        text-align: center;
        font-weight: bold;
        color: ${(props) => props.theme.color.colorsPalette.lightBlack};
      }
    }
  }

  .addressItemsContainer {
    gap: 20px;
    .addressItem {
      cursor: pointer;
      padding: 20px;
      box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
      border: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
      gap: 10px;

      &.selected {
        box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.red};
        border: 2px solid ${(props) => props.theme.color.colorsPalette.red};
      }

      .addressItemBlock {
        width: auto;
        gap: 10px;

        .name {
          h3 {
            font-weight: bold;
          }
        }

        .btnContainer {
          gap: 30px;
          .btn {
            cursor: pointer;
            width: 44px;
            height: 44px;
            border: ${(props) => props.theme.color.colorsPalette.lighterGrey} 1px solid;
            background: #fbfbfb;
            svg {
              width: 24px;
              height: 24px;
              path {
                fill: black;
              }
            }
          }
        }
      }
    }
  }
`;

class ShopBillingAddress extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      addressItems: null,
      idBillingAddress: this.props.idBillingAddress,
      openClientAddressModal: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.getAddresses();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.idBillingAddress !== this.props.idBillingAddress) {
      this.setState({ idBillingAddress: this.props.idBillingAddress });
    }

    if (prevState.idBillingAddress !== this.state.idBillingAddress && this.props.onBillingAddressChange) {
      this.props.onBillingAddressChange(this.state.idBillingAddress);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getAddresses = () => {
    clientService
      .getAddresses()
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        let items = data ?? [];
        this.setState({ addressItems: items, idBillingAddress: items[0]?.id });
      });
  };

  render() {
    return (
      <StyledShopBillingAddress flexDirection="column" alignItems="flex-start" isMobile={this._isMobile}>
        <NanoFlex justifyContent={"space-between"}>
          <NanoFlex className="back" justifyContent={"flex-start"} onClick={this.props.onBackClick}>
            <NanoFlex className="backIcon">
              <ArrowL />
            </NanoFlex>
            <NanoFlex className="backTitle">
              <Type.h4>Voltar</Type.h4>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="btnAddLink" justifyContent={"flex-end"} onClick={() => this.setState({ openClientAddressModal: true })}>
            <NanoFlex className="btnAdd">
              <LocationDot />
              {this._isMobile && <Type.h4>Adicionar Morada</Type.h4>}
              {!this._isMobile && <Type.h3>Adicionar Morada</Type.h3>}
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
        {this.state.addressItems && this.state.addressItems.length === 0 && (
          <NanoFlex className="noAddressItemsContainer" flexDirection={"column"}>
            <NanoFlex className="icon">
              <LocationDot />
            </NanoFlex>
            <NanoFlex className="message">
              <Type.h2>Ainda não tens moradas.</Type.h2>
            </NanoFlex>
          </NanoFlex>
        )}
        {this.state.addressItems && this.state.addressItems.length > 0 && (
          <NanoFlex className="addressItemsContainer" flexDirection="column">
            {this.state.addressItems?.length > 0 &&
              this.state.addressItems.map((s) => {
                return (
                  <NanoFlex
                    key={s.id}
                    className={`addressItem ${s.id === this.state.idBillingAddress ? "selected" : ""}`}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    onClick={() => this.setState({ idBillingAddress: s.id })}
                  >
                    <NanoFlex className="addressItemBlock" flexDirection="column">
                      <NanoFlex className="name" justifyContent="flex-start">
                        <Type.h3>{s.name}</Type.h3>
                      </NanoFlex>
                      <NanoFlex justifyContent="flex-start">
                        <Type.h3>NIF: {s.taxNumber}</Type.h3>
                      </NanoFlex>
                      <NanoFlex justifyContent="flex-start">
                        <Type.h3>
                          {s.address}, {s.postCode}
                        </Type.h3>
                      </NanoFlex>
                      <NanoFlex justifyContent="flex-start">
                        <Type.h3>Tel: {s.phone}</Type.h3>
                      </NanoFlex>
                    </NanoFlex>
                  </NanoFlex>
                );
              })}
          </NanoFlex>
        )}
        <Modal
          show={this.state.openClientAddressModal}
          content={
            <ClientAddressItemModal
              onBackClick={() => this.setState({ openClientAddressModal: false })}
              onSave={() => {
                this.setState({ openClientAddressModal: false }, () => this.getAddresses());
              }}
            />
          }
        />
      </StyledShopBillingAddress>
    );
  }
}

var shopBillingAddress = withParamsAndNavigate(ShopBillingAddress);
export { shopBillingAddress as ShopBillingAddress };
