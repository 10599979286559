import React, { Component } from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import { withParamsAndNavigate, validateEmailFormat } from "./../../Global/tools.js";
import Type from "../../Global/Typography";
import Input from "../../Global/Elements/Forms/Input.js";
import Textarea from "../../Global/Elements/Forms/Textarea.js";


import { ReactComponent as XMark } from "../../Global/Icons/xmark.svg";

import pageService from "./../../../services/PageService.js";

const StyledContactUs = styled(NanoFlex)`
  background-color: ${(props) => props.theme.color.main.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;

  .title {
    padding: 20px 20px 0px 20px;
    height: auto;
    .titleContainer {
      gap: 4vw;
      .titleBtnContainer {
        gap: 10px;
        h1 {
          text-transform: none;
          color: ${(props) => props.theme.color.colorsPalette.red};
          font-weight: bold;
        }
      }
      .closeContainer {
        width: auto;
        cursor: pointer;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
    .underline {
      height: 0px;
      border-bottom: 1px solid ${(props) => props.theme.color.colorsPalette.grey};
    }
  }

  .contactUsContainer {
    padding: 20px;
    gap: 20px;
    .fieldContainer {
      gap: 20px;
      .field {
        height: auto;
        gap: 10px;
        .fieldTitle {
          h3 {
            color: ${(props) => props.theme.color.colorsPalette.grey};
          }
        }
      }
    }

    .emailPasswordInvalid {
      h4 {
        color: ${(props) => props.theme.color.colorsPalette.red};
      }
    }

    .btnContainer {
      gap: 30px;
      .btn {
        cursor: pointer;
        width: 200px;
        height: 40px;
        border-radius: 30px;
        background-color: ${(props) => props.theme.color.main.font};
        h3 {
          color: ${(props) => props.theme.color.main.white};
        }

        &.disabled {
          cursor: default;
          background-color: ${(props) => props.theme.color.colorsPalette.grey};
          h3 {
            color: ${(props) => props.theme.color.main.font};
          }
        }
      }
    }
  }
`;

class ContactUs extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();

    this.nodeRef = React.createRef();
  }

  getDefaultState = () => {
    return {
      name: this.props.name ?? "",
      nameError: false,
      phone: this.props.phone ?? "",
      phoneError: false,
      email: this.props.email ?? "",
      emailError: false,
      orderNumber: this.props.orderNumber ?? "",
      orderNumberError: false,
      postCode: this.props.postCode ?? "",
      postCodeError: false,
      city: this.props.city ?? "",
      cityError: false,
      subject: this.props.subject ?? "",
      subjectError: false,
      message: this.props.message ?? "",
      messageError: false,
      disableSubmitBtn: false,
    };
  };

    componentDidMount() {
    super.componentDidMount();
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.email !== this.props.email) this.setState({ email: this.props.email });
    if (prevProps.orderNumber !== this.props.orderNumber) this.setState({ orderNumber: this.props.orderNumber });
    if (prevProps.postCode !== this.props.postCode) this.setState({ postCode: this.props.postCode });
    if (prevProps.city !== this.props.city) this.setState({ city: this.props.city });
    if (prevProps.subject !== this.props.subject) this.setState({ subject: this.props.subject });
    if (prevProps.message !== this.props.message) this.setState({ message: this.props.message });
  }

    componentWillUnmount() {
        super.componentWillUnmount();
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = (e) => {
    if (!this.nodeRef.current.contains(e.target)) this.onCancel();
  };

  validateFields = () => {
    let nameError = false;
    let phoneError = false;
    let emailError = false;
    let orderNumberError = false;
    let postCodeError = false;
    let cityError = false;
    let subjectError = false;
    let messageError = false;
    let isValid = true;
    if (!this.state.name || this.state.name.trim() === "") {
      nameError = true;
      isValid = false;
    }
    if (!this.state.phone || this.state.phone.trim() === "") {
      phoneError = true;
      isValid = false;
    }
    if (!this.state.email || this.state.email.trim() === "" || !validateEmailFormat(this.state.email)) {
      emailError = true;
      isValid = false;
    }
    // if (!this.state.orderNumber || this.state.orderNumber.trim() === "") {
    //   orderNumberError = true;
    //   isValid = false;
    // }
    // if (!this.state.postCode || this.state.postCode.trim() === "") {
    //   postCodeError = true;
    //   isValid = false;
    // }
    if (!this.state.city || this.state.city.trim() === "") {
      cityError = true;
      isValid = false;
    }
    // if (!this.state.subject || this.state.subject.trim() === "") {
    //   subjectError = true;
    //   isValid = false;
    // }
    if (!this.state.message || this.state.message.trim() === "") {
      messageError = true;
      isValid = false;
    }

    this.setState({
      nameError: nameError,
      phoneError: phoneError,
      emailError: emailError,
      orderNumberError: orderNumberError,
      postCodeError: postCodeError,
      cityError: cityError,
      subjectError: subjectError,
      messageError: messageError,
    });

    return isValid;
  };

  onSubmit = () => {
    if (this.validateFields()) {
      this.setState({ disableSubmitBtn: true }, () => {
        pageService
          .contactUs({
            isPageContent: this.props.isPageContent ? true : false,
            name: this.state.name ?? "",
            phone: this.state.phone ?? "",
            email: this.state.email ?? "",
            orderNumber: this.state.orderNumber ?? "",
            postCode: this.state.postCode ?? "",
            city: this.state.city ?? "",
            subject: this.state.subject ?? "",
            message: this.state.message ?? "",
          })
          .then((response) => {
            if (response.ok) {
              this.setState(this.getDefaultState(), () => {
                this.props.onCancel();
              });
            }
          });
      });
    }
  };

  onCancel = () => {
    let defaultState = this.getDefaultState();
    if (JSON.stringify(this.state) !== JSON.stringify(defaultState)) {
      this.setState(defaultState, () => {
        this.props.onCancel();
      });
    }
  };

  render() {
    return (
      <StyledContactUs ref={this.nodeRef} flexDirection="column">
        <NanoFlex className="title" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          <NanoFlex className="titleContainer" justifyContent="space-between">
            <NanoFlex className="titleBtnContainer" justifyContent="flex-start">
              <Type.h1 className="titleBtn" onClick={() => this.setState({ isLogin: true })}>
                CONTACTE-NOS
              </Type.h1>
            </NanoFlex>
            <NanoFlex className="closeContainer" justifyContent="flex-end" onClick={this.props.onCancel}>
              <XMark />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="underline"></NanoFlex>
        </NanoFlex>
        <NanoFlex className="contactUsContainer" flexDirection="column" justifyContent="flex-start">
          <NanoFlex className="fieldContainer">
            <NanoFlex className="field" flexDirection="column" justifyContent="flex-start">
              <NanoFlex className="fieldTitle" justifyContent="flex-start">
                <Type.h3>Nome*</Type.h3>
              </NanoFlex>
              <Input
                type="text"
                placeholder="Nome"
                defaultValue={this.state.name}
                error={this.state.nameError}
                onChangeAction={(val) => this.setState({ name: val })}
              />
            </NanoFlex>
            <NanoFlex className="field" flexDirection="column" justifyContent="flex-start">
              <NanoFlex className="fieldTitle" justifyContent="flex-start">
                <Type.h3>Telefone*</Type.h3>
              </NanoFlex>
              <Input
                isNumeric
                type="text"
                placeholder="Telefone"
                defaultValue={this.state.phone}
                error={this.state.phoneError}
                onChangeAction={(val) => this.setState({ phone: val?.toString() })}
              />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="fieldContainer">
            <NanoFlex className="field" flexDirection="column" justifyContent="flex-start">
              <NanoFlex className="fieldTitle" justifyContent="flex-start">
                <Type.h3>Email*</Type.h3>
              </NanoFlex>
              <Input
                type="text"
                placeholder="Email"
                defaultValue={this.state.email}
                error={this.state.emailError}
                onChangeAction={(val) => this.setState({ email: val })}
              />
            </NanoFlex>
            {!this.props.isPageContent && (
              <NanoFlex className="field" flexDirection="column" justifyContent="flex-start">
                <NanoFlex className="fieldTitle" justifyContent="flex-start">
                  <Type.h3>Nº Encomenda</Type.h3>
                </NanoFlex>
                <Input
                  type="text"
                  placeholder="Nº Encomenda"
                  defaultValue={this.state.orderNumber}
                  error={this.state.orderNumberError}
                  onChangeAction={(val) => this.setState({ orderNumber: val })}
                />
              </NanoFlex>
            )}
          </NanoFlex>
          <NanoFlex className="fieldContainer">
            <NanoFlex className="fieldContainer">
              <NanoFlex className="field" flexDirection="column" justifyContent="flex-start">
                 <NanoFlex className="fieldTitle" justifyContent="flex-start">
                 {this._isMobile && <Type.h3>C.Postal</Type.h3>}
                 {!this._isMobile && <Type.h3>Código Postal</Type.h3>}
                </NanoFlex>
                <Input
                  type="text"
                  placeholder="Código Postal"
                  defaultValue={this.state.postCode}
                  error={this.state.postCodeError}
                  onChangeAction={(val) => this.setState({ postCode: val })}
                />
              </NanoFlex>
              <NanoFlex className="field" flexDirection="column" justifyContent="flex-start">
                <NanoFlex className="fieldTitle" justifyContent="flex-start">
                  <Type.h3>Cidade*</Type.h3>
                </NanoFlex>
                <Input
                  type="text"
                  placeholder="Cidade"
                  defaultValue={this.state.city}
                  error={this.state.cityError}
                  onChangeAction={(val) => this.setState({ city: val })}
                />
              </NanoFlex>
            </NanoFlex>
            {!this.props.isPageContent && (
              <NanoFlex className="field" flexDirection="column" justifyContent="flex-start">
                <NanoFlex className="fieldTitle" justifyContent="flex-start">
                  <Type.h3>Tema</Type.h3>
                </NanoFlex>
                <Input
                  type="text"
                  placeholder="Tema"
                  defaultValue={this.state.subject}
                  error={this.state.subjectError}
                  onChangeAction={(val) => this.setState({ subject: val })}
                />
              </NanoFlex>
            )}
          </NanoFlex>
          <NanoFlex className="fieldContainer">
            <NanoFlex className="field" flexDirection="column" justifyContent="flex-start">
              <NanoFlex className="fieldTitle" justifyContent="flex-start">
                <Type.h3>Assunto*</Type.h3>
              </NanoFlex>
              <Textarea
                defaultValue={this.state.message}
                error={this.state.messageError}
                onChangeAction={(val) => this.setState({ message: val })}
                placeholder={"Assunto"}
              />
            </NanoFlex>
          </NanoFlex>
          {this.state.errorMessage && this.state.errorMessage.trim() !== "" && (
            <NanoFlex className="emailPasswordInvalid" justifyContent="flex-start">
              <Type.h4>{this.state.errorMessage}</Type.h4>
            </NanoFlex>
          )}
          <NanoFlex className="btnContainer" justifyContent="center">
            <NanoFlex className={`btn ${this.state.disableSubmitBtn ? "disabled" : ""}`} onClick={() => this.onSubmit()}>
              <Type.h3>Enviar</Type.h3>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledContactUs>
    );
  }
}

export default withParamsAndNavigate(ContactUs);
