import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import { withParamsAndNavigate } from "../../Global/tools.js";
import Type from "../../Global/Typography";
import Input from "../../Global/Elements/Forms/Input.js";

import { ReactComponent as XMark } from "../../Global/Icons/xmark.svg";

import clientService from "../../../services/ClientService.js";

const StyledUseRecoverPassword = styled(NanoFlex)`
  background-color: ${(props) => props.theme.color.main.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;

  .title {
    padding: 20px 20px 0px 20px;
    height: auto;
    .titleContainer {
      gap: 4vw;
      .titleBtnContainer {
        gap: 10px;
        h1 {
          text-transform: none;
          color: ${(props) => props.theme.color.colorsPalette.red};
          font-weight: bold;
        }
      }
      .closeContainer {
        width: auto;
        cursor: pointer;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
    .underline {
      height: 0px;
      border-bottom: 1px solid ${(props) => props.theme.color.colorsPalette.grey};
    }
  }

  .fieldContainer {
    padding: 20px;
    gap: 20px;
    .field {
      height: auto;
      gap: 10px;
      .fieldTitle {
        h3 {
          color: ${(props) => props.theme.color.colorsPalette.grey};
        }
      }
      .forgotPassword {
        cursor: pointer;
        h5 {
          color: ${(props) => props.theme.color.colorsPalette.red};
          text-decoration: underline;
        }
      }
    }

    .emailPasswordInvalid {
      h4 {
        color: ${(props) => props.theme.color.colorsPalette.red};
      }
    }

    .btnContainer {
      gap: 30px;
      .btn {
        cursor: pointer;
        width: 200px;
        height: 40px;
        border-radius: 30px;
        background-color: ${(props) => props.theme.color.main.font};
        h3 {
          color: ${(props) => props.theme.color.main.white};
        }
      }
    }
  }
`;

class UseRecoverPassword extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();

    this.nodeRef = React.createRef();
  }

  getDefaultState = () => {
    return {
      password: "",
      passwordError: "",
      repeatPassword: "",
      repeatPasswordError: "",
    };
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = (e) => {
    if (!this.nodeRef.current.contains(e.target)) this.onClose();
  };

  validateFields = () => {
    let passwordError = false;
    let repeatPasswordError = false;
    let isValid = true;

    if (!this.state.password || this.state.password.trim() === "") {
      passwordError = true;
      isValid = false;
    }

    if (!this.state.repeatPassword || this.state.repeatPassword.trim() === "" || this.state.repeatPassword !== this.state.password) {
      repeatPasswordError = true;
      isValid = false;
    }

    this.setState({
      passwordError: passwordError,
      repeatPasswordError: repeatPasswordError,
    });

    return isValid;
  };

  onRecoverPassword = () => {
    if (this.validateFields()) {
      clientService
        .useResetPassword({
          Token: this.props.token,
          Password: this.state.password,
        })
        .then((response) => {
          if (response.ok || response.status === 400) {
            return response.json();
          }
        })
        .then((data) => {
          if (data.message) {
            this.setState({ errorMessage: data.message });
          } else {
            this.setState(this.getDefaultState(), () => {
              this.clientLogin(data);
              //Call back on login prop
              this.props.onClose();
            });
          }
        });
    }
  };

  onClose = () => {
    this.setState(this.getDefaultState(), () => {
      this.props.onClose();
    });
  };

  render() {
    return (
      <StyledUseRecoverPassword ref={this.nodeRef} flexDirection="column">
        <NanoFlex className="title" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          <NanoFlex className="titleContainer" justifyContent="space-between">
            <NanoFlex className="titleBtnContainer" justifyContent="flex-start">
              <Type.h1 className="titleBtn" onClick={() => this.setState({ isLogin: true })}>
                Alterar Palavra-Passe
              </Type.h1>
            </NanoFlex>
            <NanoFlex className="closeContainer" justifyContent="flex-end" onClick={this.props.onClose}>
              <XMark />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="underline"></NanoFlex>
        </NanoFlex>
        <NanoFlex className="fieldContainer" flexDirection="column">
          <NanoFlex className="field" flexDirection="column" justifyContent="flex-start">
            <NanoFlex className="fieldTitle" justifyContent="flex-start">
              <Type.h3>Palavra-Passe</Type.h3>
            </NanoFlex>
            <Input
              type="text"
              eye={true}
              placeholder="Palavra-Passe"
              defaultValue={this.state.password}
              error={this.state.passwordError}
              onChangeAction={(val) => this.setState({ password: val })}
            />
          </NanoFlex>
          <NanoFlex className="field" flexDirection="column" justifyContent="flex-start">
            <NanoFlex className="fieldTitle" justifyContent="flex-start">
              <Type.h3>Confirmar Palavra-Passe</Type.h3>
            </NanoFlex>
            <Input
              type="text"
              eye={true}
              placeholder="Palavra-Passe"
              defaultValue={this.state.repeatPassword}
              error={this.state.repeatPasswordError}
              onChangeAction={(val) => this.setState({ repeatPassword: val })}
            />
          </NanoFlex>
          {this.state.errorMessage && this.state.errorMessage.trim() !== "" && (
            <NanoFlex className="emailPasswordInvalid" justifyContent="flex-start">
              <Type.h4>{this.state.errorMessage}</Type.h4>
            </NanoFlex>
          )}
          <NanoFlex className="btnContainer" justifyContent="center">
            <NanoFlex className="btn" onClick={() => this.onRecoverPassword()}>
              <Type.h3>Confirmar</Type.h3>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledUseRecoverPassword>
    );
  }
}

export default withParamsAndNavigate(UseRecoverPassword);
