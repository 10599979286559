import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import { withParamsAndNavigate } from "../../Global/tools.js";

import { CategoriesGrid } from "../Categories/CategoriesGrid.js";

const StyledCategoriesNavigation = styled(NanoFlex)`
  padding: 20px 20px;

  .navigation {
    height: 20px;
    a {
      display: flex;
      text-decoration: none;

      h4 {
        color: ${(props) => props.theme.color.colorsPalette.grey};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:not(:first-child) {
          padding: 0px 3px;
        }
        &:last-child {
          user-select: none;
          cursor: default;
        }
        &.active {
          font-weight: bold;
        }
      }

      &:last-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .title {
    padding-bottom: 20px;
    font-weight: bold;
  }
`;

class CategoriesNavigation extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <StyledCategoriesNavigation flexDirection={"column"}>
        {this.props.parentCategories && this.props.parentCategories.length > 1 && (
          <NanoFlex className="navigation" justifyContent={"flex-start"}>
            {this.props.parentCategories.map((n, i) => (
              <Link key={i} to={`/produtos?${new URLSearchParams({ id: n.id, lvl: i + 1 }).toString()}`}>
                <Type.h4 className={`${this.props.parentCategories.length - 1 === i ? "active" : ""}`}>{n.name}</Type.h4>
                {this.props.parentCategories.length - 1 !== i && <Type.h4>/</Type.h4>}
              </Link>
            ))}
          </NanoFlex>
        )}
        <NanoFlex>
          <Type.h1 className="title">{this.props.parentCategories.at(-1).name}</Type.h1>
        </NanoFlex>
        <CategoriesGrid data={this.props.data} lvl={this.props.lvl} maxColSize={300} />
      </StyledCategoriesNavigation>
    );
  }
}

var categoriesNavigation = withParamsAndNavigate(CategoriesNavigation);
export { categoriesNavigation as CategoriesNavigation };
