import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import Input from "../../Global/Elements/Forms/Input.js";
import SingleSelectionDropdown from "../../Global/Elements/Forms/SingleSelectionDropdown.js";
import { withParamsAndNavigate } from "../../Global/tools.js";
import clientService from "../../../services/ClientService.js";

//Icons
import { ReactComponent as ArrowL } from "../../Global/Icons/arrowL.svg";
import { ReactComponent as LocationDot } from "../../Global/Icons/locationDot.svg";
import { ReactComponent as Edit } from "../../Global/Icons/edit.svg";
import { ReactComponent as Delete } from "../../Global/Icons/delete.svg";

const StyledClientAddressItem = styled(NanoFlex)`
  padding: 20px;
  box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
  border: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};

  .back {
    cursor: pointer;
    width: auto;
    gap: 5px;
    padding: 5px 0px;

    .backIcon {
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .backTitle {
      border-bottom: 2px solid ${(props) => props.theme.color.main.font};
    }
  }

  .title {
    h2 {
      font-weight: bold;
    }
  }

  .addressContainer {
    padding: 20px 0px;
    gap: 20px;

    .fieldBlock {
      gap: 10px;
      .field {
        height: auto;
        gap: 10px;

        .dropDownContainer {
          border: 1px solid ${(props) => props.theme.color.main.font};

          .inputDropDownWrapper {
            height: 33px;
          }
        }
      }
    }

    .btnAdd {
      padding: 5px;
      cursor: pointer;
      user-select: none;
      max-width: 200px;
      height: 40px;
      background-color: ${(props) => props.theme.color.colorsPalette.red};
      gap: 10px;
      svg {
        height: 25px;
        width: 25px;

        fill: white;
      }
      h3,
      h4 {
        color: white;
        text-align: center;
      }
    }

    /* .addressItem {
      padding: 20px;
      box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
      border: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
      gap: 10px;

      .addressItemBlock {
        width: auto;
        gap: 10px;
        .name {
          h3 {
            font-weight: bold;
          }
        }

        .btnContainer {
          gap: 30px;
          .btn {
            cursor: pointer;
            width: 44px;
            height: 44px;
            border: #e5e5e5 1px solid;
            background: #fbfbfb;
            svg {
              width: 24px;
              height: 24px;
              path {
                fill: black;
              }
            }
          }
        }
      }
    } */
  }
`;

class ClientAddressItem extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      id: this.props.address?.id,
      name: this.props.address?.name ?? this._user.Name ?? "",
      nameError: false,
      address: this.props.address?.address ?? "",
      addressError: false,
      postCode: this.props.address?.postCode ?? "",
      postCodeError: false,
      taxNumber: this.props.address?.taxNumber ?? "",
      taxNumberError: false,
      phone: this.props.address?.phone ?? "",
      phoneError: false,
      isIsland: this.props.address?.isIsland ?? false,
    };
  };

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.address) !== JSON.stringify(this.props.address)) {
      this.setState(this.getDefaultState());
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  validateFields = () => {
    let nameError = false;
    let addressError = false;
    let postCodeError = false;
    let taxNumberError = false;
    let phoneError = false;
    let isValid = true;
    if (!this.state.name || this.state.name.trim() === "") {
      nameError = true;
      isValid = false;
    }

    if (!this.state.address || this.state.address.trim() === "") {
      addressError = true;
      isValid = false;
    }

    if (!this.state.postCode || this.state.postCode.trim() === "") {
      postCodeError = true;
      isValid = false;
    }

    if (!this.state.taxNumber || this.state.taxNumber.trim() === "") {
      taxNumberError = true;
      isValid = false;
    }

    if (!this.state.phone || this.state.phone.trim() === "") {
      phoneError = true;
      isValid = false;
    }

    this.setState({
      nameError: nameError,
      addressError: addressError,
      postCodeError: postCodeError,
      taxNumberError: taxNumberError,
      phoneError: phoneError,
    });

    return isValid;
  };

  onSave = () => {
    if (this.validateFields()) {
      clientService
        .updateAddress({
          id: this.state.id,
          name: this.state.name,
          address: this.state.address,
          postCode: this.state.postCode,
          taxNumber: this.state.taxNumber,
          phone: this.state.phone,
          isIsland: this.state.isIsland,
        })
        .then((response) => {
          if (response.ok) {
            this.props.onSave();
          }
        });
    }
  };

  render() {
    let btnText = this.state.id ? "Editar Morada" : "Adicionar Morada";
    return (
      <StyledClientAddressItem flexDirection={"column"} alignItems={"flex-start"}>
        <NanoFlex className="back" justifyContent={"flex-start"} onClick={this.props.onBackClick}>
          <NanoFlex className="backIcon">
            <ArrowL />
          </NanoFlex>
          <NanoFlex className="backTitle">
            <Type.h4>Voltar</Type.h4>
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="title" justifyContent={"flex-start"}>
          <NanoFlex justifyContent={"flex-start"}>
            {!this.state.id && <Type.h2>Adicionar Morada</Type.h2>}
            {this.state.id && <Type.h2>Editar Morada</Type.h2>}
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="addressContainer" flexDirection="column" alignItems="flex-start">
          <NanoFlex className="fieldBlock">
            <NanoFlex className="field" flexDirection="column" justifyContent="flex-start">
              <NanoFlex className="fieldTitle" justifyContent="flex-start">
                <Type.h3>Nome*</Type.h3>
              </NanoFlex>
              <Input
                type="text"
                placeholder="Nome"
                defaultValue={this.state.name}
                error={this.state.nameError}
                onChangeAction={(val) => this.setState({ name: val })}
              />
            </NanoFlex>
            <NanoFlex className="field" flexDirection="column" justifyContent="flex-start">
              <NanoFlex className="fieldTitle" justifyContent="flex-start">
                <Type.h3>Telemóvel*</Type.h3>
              </NanoFlex>
              <Input
                type="text"
                placeholder="Telemóvel"
                isNumeric
                defaultValue={this.state.phone}
                error={this.state.phoneError}
                onChangeAction={(val) => this.setState({ phone: val?.toString() })}
              />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="fieldBlock">
            <NanoFlex className="field" flexDirection="column" justifyContent="flex-start">
              <NanoFlex className="fieldTitle" justifyContent="flex-start">
                <Type.h3>Morada*</Type.h3>
              </NanoFlex>
              <Input
                type="text"
                placeholder="Morada"
                defaultValue={this.state.address}
                error={this.state.addressError}
                onChangeAction={(val) => this.setState({ address: val })}
              />
            </NanoFlex>
            <NanoFlex className="field" flexDirection="column" justifyContent="flex-start">
              <NanoFlex className="fieldTitle" justifyContent="flex-start">
                <Type.h3>Região*</Type.h3>
              </NanoFlex>
              <SingleSelectionDropdown
                hideSearch
                preventUnselect
                selected={this.state.isIsland ? 2 : 1}
                options={[
                  { id: 1, name: "Portugal continental" },
                  { id: 2, name: "Ilhas portuguesas" },
                ]}
                onChangeSelection={(opt) => this.setState({ isIsland: opt.id === 2 })}
              />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="fieldBlock">
            <NanoFlex className="field" flexDirection="column" justifyContent="flex-start">
              <NanoFlex className="fieldTitle" justifyContent="flex-start">
                <Type.h3>Código Postal*</Type.h3>
              </NanoFlex>
              <Input
                type="text"
                placeholder="Código Postal"
                defaultValue={this.state.postCode}
                error={this.state.postCodeError}
                onChangeAction={(val) => this.setState({ postCode: val })}
              />
            </NanoFlex>
            <NanoFlex className="field" flexDirection="column" justifyContent="flex-start">
              <NanoFlex className="fieldTitle" justifyContent="flex-start">
                <Type.h3>Nº Contribuinte*</Type.h3>
              </NanoFlex>
              <Input
                type="text"
                placeholder="Nº Contribuinte"
                isNumeric
                defaultValue={this.state.taxNumber}
                error={this.state.taxNumberError}
                onChangeAction={(val) => this.setState({ taxNumber: val?.toString() })}
              />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="btnAdd" onClick={this.onSave}>
            <LocationDot />
            {this._isMobile && <Type.h4>{btnText}</Type.h4>}
            {!this._isMobile && <Type.h3>{btnText}</Type.h3>}
          </NanoFlex>
        </NanoFlex>
      </StyledClientAddressItem>
    );
  }
}

var clientAddressItem = withParamsAndNavigate(ClientAddressItem);
export { clientAddressItem as ClientAddressItem };
