import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import { withParamsAndNavigate } from "../../Global/tools.js";

import { ClientAddressItem } from "./ClientAddressItem.js";

const StyledClientAddressItemModal = styled(NanoFlex)`
  background-color: ${(props) => props.theme.color.main.white};
  min-width: 50vw;
`;

class ClientAddressItemModal extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  render() {
    // return <ClientAddressItem address={this.state.editAddress} onBackClick={() => this.onItemBack()} onSave={this.onSave} />;
    return (
      <StyledClientAddressItemModal>
        <ClientAddressItem onBackClick={this.props.onBackClick} onSave={this.props.onSave} />
      </StyledClientAddressItemModal>
    );
  }
}

var clientAddressItemModal = withParamsAndNavigate(ClientAddressItemModal);
export { clientAddressItemModal as ClientAddressItemModal };
