import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import moment from "moment";
import Type from "../../Global/Typography.js";
import { withParamsAndNavigate } from "./../../Global/tools.js";
import shopService from "./../../../services/ShopService.js";

//Icons
import { ReactComponent as TruckFast } from "../../Global/Icons/truckFast.svg";
import { ReactComponent as ArrowL } from "../../Global/Icons/arrowL.svg";
import { ReactComponent as XMark } from "../../Global/Icons/xmark.svg";
import { ReactComponent as CartPlus } from "../../Global/Icons/cartPlus.svg";
import { ReactComponent as CartShoppingSolid } from "../../Global/Icons/cartShoppingSolid.svg";

import { ClientShopHistoryItem } from "./ClientShopHistoryItem.js";

const StyledClientShopHistory = styled(NanoFlex)`
  padding: 20px;
  box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
  border: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};

  .back {
    cursor: pointer;
    width: auto;
    gap: 5px;
    padding: 5px 0px;

    .backIcon {
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .backTitle {
      border-bottom: 2px solid ${(props) => props.theme.color.main.font};
    }
  }

  .title {
    h2 {
      font-weight: bold;
    }
  }

  .noHistoryContainer {
    height: 25vh;
    gap: 20px;
    .icon {
      height: auto;
      svg {
        width: 24px;
        height: 24px;

        fill: ${(props) => props.theme.color.colorsPalette.lightBlack};
      }
    }

    .message {
      height: auto;
      h2 {
        font-weight: bold;
        color: ${(props) => props.theme.color.colorsPalette.lightBlack};
      }
    }
  }

  .shopContainer {
    padding: 20px 0px;
    gap: 20px;
    .shopItem {
      padding: 20px;
      box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
      border: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
      gap: ${(props) => (props.isMobile ? "10px" : "20px")};

      .shopItemBlockContainer {
        gap: 10px;
        .shopItemBlock {
          width: auto;
          gap: 10px;
          .shopNumber {
            gap: 5px;
            h3 {
              font-weight: bold;
            }
          }

          .btn {
            padding: 10px;
            cursor: pointer;
            user-select: none;
            max-width: 200px;
            height: 40px;
            background-color: ${(props) => props.theme.color.colorsPalette.red};
            gap: 10px;
            svg {
              height: 25px;
              width: 25px;

              fill: white;
            }
            h3,
            h4 {
              color: white;
              text-align: center;
            }

            &:hover {
              background-color: ${(props) => props.theme.color.colorsPalette.lightBlack};
            }
          }
        }
      }
    }
  }
`;

class ClientShopHistory extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      shopItems: null,
      selectedShopId: null,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.getShops();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getShops = () => {
    shopService
      .getShops(true)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
            this.clientLogout();
        }
      })
      .then((data) => {
        this.setState({ shopItems: data ?? [] });
      });
  };

  cancelShop = (id) => {
    shopService.cancelShopState(id).then((response) => {
      if (response.ok) {
        this.getShops();
      }
    });
  };

  copyShop = (id) => {
    shopService.copyShopLines(id).then((response) => {
      if (response.ok) {
        window.dispatchEvent(new Event("updateShopCount"));
        this.props.navigate("/carrinho");
      }
    });
  };

  render() {
    if (this.state.selectedShopId)
      return <ClientShopHistoryItem id={this.state.selectedShopId} onBackClick={() => this.setState({ selectedShopId: null })} />;

    return (
      <StyledClientShopHistory isMobile={this._isMobile} flexDirection={"column"} alignItems={"flex-start"}>
        {this._isMobile && (
          <NanoFlex className="back" justifyContent={"flex-start"} onClick={this.props.onBackClick}>
            <NanoFlex className="backIcon">
              <ArrowL />
            </NanoFlex>
            <NanoFlex className="backTitle">
              <Type.h4>Voltar</Type.h4>
            </NanoFlex>
          </NanoFlex>
        )}
        <NanoFlex className="title" justifyContent={"flex-start"}>
          <Type.h2>As Minhas Compras</Type.h2>
        </NanoFlex>
        {this.state.shopItems && this.state.shopItems.length === 0 && (
          <NanoFlex className="noHistoryContainer" flexDirection={"column"}>
            <NanoFlex className="icon">
              <TruckFast />
            </NanoFlex>
            <NanoFlex className="message">
              <Type.h2>Ainda não tens encomendas.</Type.h2>
            </NanoFlex>
          </NanoFlex>
        )}
        {this.state.shopItems && this.state.shopItems.length > 0 && (
          <NanoFlex className="shopContainer" flexDirection="column">
            {this.state.shopItems.map((s) => {
              return (
                <NanoFlex key={s.id} className="shopItem" flexDirection="column">
                  <NanoFlex
                    className="shopItemBlockContainer"
                    flexDirection={this._isMobile ? "column" : "row"}
                    alignItems={"center"}
                    justifyContent="space-between"
                  >
                    <NanoFlex className="shopItemBlock" flexDirection="column" justifyContent="flex-start">
                      <NanoFlex className="shopNumber" justifyContent={"flex-start"}>
                        <Type.h4>Nº Encomenda:</Type.h4>
                        <Type.h3>{s.ref}</Type.h3>
                      </NanoFlex>
                      <NanoFlex className="shopNumber" justifyContent="flex-start">
                        <Type.h4>Data da Compra:</Type.h4>
                        <Type.h3>{moment(s.buyDate).format(this.props.theme.moment.displayFormat)}</Type.h3>
                      </NanoFlex>
                      <NanoFlex className="shopNumber" justifyContent={"flex-start"}>
                        <Type.h4>Valor:</Type.h4>
                        <Type.h3>{s.total}€</Type.h3>
                      </NanoFlex>
                      <NanoFlex className="shopNumber" justifyContent="flex-start">
                        <Type.h4>Estado:</Type.h4>
                        <Type.h3>{s.shopStateDescription}</Type.h3>
                      </NanoFlex>
                    </NanoFlex>
                    <NanoFlex className="shopItemBlock" flexDirection="column" alignItems={this._isMobile ? "flex-start" : "flex-end"}>
                      <NanoFlex className="btn" onClick={() => this.setState({ selectedShopId: s.id })}>
                        <CartShoppingSolid />
                        {this._isMobile && <Type.h4>Ver Detalhes</Type.h4>}
                        {!this._isMobile && <Type.h3>Ver Detalhes</Type.h3>}
                      </NanoFlex>
                      <NanoFlex className="btn" onClick={() => this.copyShop(s.id)}>
                        <CartPlus />
                        {this._isMobile && <Type.h4>Voltar a Comprar</Type.h4>}
                        {!this._isMobile && <Type.h3>Voltar a Comprar</Type.h3>}
                      </NanoFlex>
                      {s.idShopState === 2 && (
                        <NanoFlex className="btn" onClick={() => this.cancelShop(s.id)}>
                          <XMark />
                          {this._isMobile && <Type.h4>Cancelar Compra</Type.h4>}
                          {!this._isMobile && <Type.h3>Cancelar Compra</Type.h3>}
                        </NanoFlex>
                      )}
                    </NanoFlex>
                  </NanoFlex>
                </NanoFlex>
              );
            })}
          </NanoFlex>
        )}
      </StyledClientShopHistory>
    );
  }
}

var clientShopHistory = withParamsAndNavigate(ClientShopHistory);
export { clientShopHistory as ClientShopHistory };
