import React from "react";
import styled from "styled-components";
import NanoFlex from "../NanoFlex";

const StyledModal = styled.div`
  opacity: ${(props) => (props.show ? "1" : "0")};
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  height: 100%;
  top: ${(props) => (props.show ? "0%" : "100%")};
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 20;
  transition: opacity 0.5s ease 0s;
  overflow: auto;
  .modalBody {
    min-height: 230px;
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 320px) {
    .modalBody {
      width: 90%;
    }
  }
`;

const Modal = (props) => {
  return (
    <StyledModal show={props.show}>
      <NanoFlex flexDirection={"column"}>
        <div className="modalBody">{props.content ? props.content : null}</div>
      </NanoFlex>
    </StyledModal>
  );
};

export default Modal;

// --- // Documentation // --- //
/*
Element Props
show: boolean
content: <ReactComponent/>
closeAction: function

import Modal from '../../Global/Modal.js';

<Modal
  show={true}
  content={<div/>}
  closeAction={() => this.setState({ modalOpen: false }) }
/>
*/
