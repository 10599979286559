// import BaseComponent from "../components/BaseComponent.js";
import jwt_decode from "jwt-decode";

const _authKey = "YNNOWA_USER_AUTH";

export class BaseService {
  getAuthKey = () => {
    return _authKey;
  };

  getAuthorization = () => {
    let token = this.getToken();
    if (token !== null) return `Bearer ${token}`;
    return null;
  };

  getUser = () => {
    let token = this.getToken();
    if (token !== null) {
      let jwt = jwt_decode(localStorage.getItem(_authKey));
      if (jwt !== null && jwt.LogonInfo !== null) {
        return JSON.parse(jwt.LogonInfo);
      }
    }
    return null;
  };

  getToken = () => {
    return localStorage.getItem(_authKey);
  };

  setToken = (token) => {
    localStorage.setItem(_authKey, token);
  };

  removeToken = () => {
    if (this.getToken() != null) {
      localStorage.removeItem(_authKey);
    }
  };

  request = (url, args) => {
    let authArgs = JSON.parse(JSON.stringify(args));
    //Prevent on FormData Uploads
    if (args.body instanceof FormData) {
      authArgs.body = args.body;
    }
    //Set Auth Key
    let authKey = this.getAuthorization();
    if (authKey != null) authArgs.headers.Authorization = authKey;
    // let langId = new BaseComponent().getLanguage();
    // authArgs.headers.LanguageId = langId;
    return fetch(`${window.location.origin}/${url}`, authArgs);
  };
}
