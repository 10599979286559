import React from "react";
import { Link } from "react-router-dom";
import BaseComponent from "../../BaseComponent.js";
import styled, { css } from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import Modal from "../../Global/Elements/Modal.js";
import { withParamsAndNavigate } from "./../../Global/tools.js";
import Textarea from "../../Global/Elements/Forms/Textarea.js";

//Services
import clientService from "./../../../services/ClientService.js";

import { ClientAddressItemModal } from "../Client/ClientAddressItemModal.js";

import { ReactComponent as ArrowL } from "../../Global/Icons/arrowL.svg";
import { ReactComponent as LocationDot } from "../../Global/Icons/locationDot.svg";

const StyledShopDeliveryAddress = styled(NanoFlex)`
  gap: 10px;
  .back {
    cursor: pointer;
    width: auto;
    gap: 5px;

    .backIcon {
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .backTitle {
      border-bottom: 2px solid ${(props) => props.theme.color.main.font};
    }
  }

  .btnAddLink {
    text-decoration: none;
    .btnAdd {
      padding: 5px;
      cursor: pointer;
      user-select: none;
      max-width: 200px;
      height: 40px;
      background-color: ${(props) => props.theme.color.colorsPalette.red};
      gap: 10px;
      svg {
        height: 25px;
        width: 25px;

        fill: white;
      }
      h3,
      h4 {
        color: white;
        text-align: center;
      }
    }
  }

  .noAddressItemsContainer {
    height: 25vh;
    gap: 20px;
    .icon {
      height: auto;
      svg {
        width: 24px;
        height: 24px;

        fill: ${(props) => props.theme.color.colorsPalette.lightBlack};
      }
    }

    .message {
      height: auto;
      h2 {
        text-align: center;
        font-weight: bold;
        color: ${(props) => props.theme.color.colorsPalette.lightBlack};
      }
    }
  }

  .addressItemsContainer {
    gap: 20px;
    .addressItem {
      cursor: pointer;
      padding: 20px;
      box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
      border: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
      gap: 10px;

      &.selected {
        box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.red};
        border: 2px solid ${(props) => props.theme.color.colorsPalette.red};
      }

      .shopAddressBlock {
        gap: 20px;

        .icon {
          width: auto;
          svg {
            width: 24px;
            height: 24px;

            fill: ${(props) => props.theme.color.colorsPalette.lightBlack};
          }
        }

        .name {
          h3 {
            font-weight: bold;
          }
        }
      }

      .addressItemBlock {
        width: auto;
        gap: 10px;

        .name {
          h3 {
            font-weight: bold;
          }
        }
      }
    }
  }
`;

class ShopDeliveryAddress extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      addressItems: null,
      idDeliveryAddress: this.props.idDeliveryAddress,
      isShopDelivery: this.props.isShopDelivery,
      observations: this.props.observations,
      openClientAddressModal: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.getAddresses();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.idDeliveryAddress !== this.props.idDeliveryAddress) {
      this.setState({ idDeliveryAddress: this.props.idDeliveryAddress });
    }

    if (prevState.idDeliveryAddress !== this.state.idDeliveryAddress && this.props.onDeliveryAddressChange) {
      this.props.onDeliveryAddressChange(this.state.addressItems?.find((a) => a.id === this.state.idDeliveryAddress));
    }

    if (prevProps.isShopDelivery !== this.props.isShopDelivery) {
      this.setState({ isShopDelivery: this.props.isShopDelivery });
    }

    if (prevState.isShopDelivery !== this.state.isShopDelivery && this.props.onShopDeliveryChange) {
      this.props.onShopDeliveryChange(this.state.isShopDelivery);
    }

    if (prevProps.observations !== this.props.observations) {
      this.setState({ observations: this.props.observations });
    }

    if (prevState.observations !== this.state.observations && this.props.onObservationsChange) {
      this.props.onObservationsChange(this.state.observations);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getAddresses = () => {
    clientService
      .getAddresses()
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        this.setState({ addressItems: data ?? [] });
      });
  };

  render() {
    return (
      <StyledShopDeliveryAddress flexDirection="column" alignItems="flex-start" isMobile={this._isMobile}>
        <NanoFlex justifyContent={"space-between"}>
          <NanoFlex className="back" justifyContent={"flex-start"} onClick={this.props.onBackClick}>
            <NanoFlex className="backIcon">
              <ArrowL />
            </NanoFlex>
            <NanoFlex className="backTitle">
              <Type.h4>Voltar</Type.h4>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="btnAddLink" justifyContent={"flex-end"} onClick={() => this.setState({ openClientAddressModal: true })}>
            <NanoFlex className="btnAdd">
              <LocationDot />
              {this._isMobile && <Type.h4>Adicionar Morada</Type.h4>}
              {!this._isMobile && <Type.h3>Adicionar Morada</Type.h3>}
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="addressItemsContainer" flexDirection="column">
          <NanoFlex
            className={`addressItem ${this.state.isShopDelivery ? "selected" : ""}`}
            alignItems="flex-start"
            justifyContent="flex-start"
            onClick={() => this.setState({ isShopDelivery: true, idDeliveryAddress: null })}
          >
            <NanoFlex className="shopAddressBlock">
              <NanoFlex className="icon">
                <LocationDot />
              </NanoFlex>
              <NanoFlex className="name" justifyContent="flex-start">
                <Type.h3>Entrega Em Loja</Type.h3>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          {this.state.addressItems?.length > 0 &&
            this.state.addressItems.map((s) => {
              return (
                <NanoFlex
                  key={s.id}
                  className={`addressItem ${s.id === this.state.idDeliveryAddress ? "selected" : ""}`}
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  onClick={() => this.setState({ idDeliveryAddress: s.id, isShopDelivery: false })}
                >
                  <NanoFlex className="addressItemBlock" flexDirection="column">
                    <NanoFlex className="name" justifyContent="flex-start">
                      <Type.h3>{s.name}</Type.h3>
                    </NanoFlex>
                    <NanoFlex justifyContent="flex-start">
                      <Type.h3>NIF: {s.taxNumber}</Type.h3>
                    </NanoFlex>
                    <NanoFlex justifyContent="flex-start">
                      <Type.h3>
                        {s.address}, {s.postCode}
                      </Type.h3>
                    </NanoFlex>
                    <NanoFlex justifyContent="flex-start">
                      <Type.h3>Tel: {s.phone}</Type.h3>
                    </NanoFlex>
                  </NanoFlex>
                </NanoFlex>
              );
            })}
          {this.state.addressItems && this.state.addressItems.length === 0 && (
            <NanoFlex className="noAddressItemsContainer" flexDirection={"column"}>
              <NanoFlex className="icon">
                <LocationDot />
              </NanoFlex>
              <NanoFlex className="message">
                <Type.h2>Ainda não tens moradas.</Type.h2>
              </NanoFlex>
            </NanoFlex>
          )}
        </NanoFlex>
        <NanoFlex className="fieldContainer">
          <NanoFlex className="field" flexDirection="column" justifyContent="flex-start">
            <Textarea
              defaultValue={this.state.observations}
              onChangeAction={(val) => this.setState({ observations: val })}
              placeholder={"Observações"}
            />
          </NanoFlex>
        </NanoFlex>
        <Modal
          show={this.state.openClientAddressModal}
          content={
            <ClientAddressItemModal
              onBackClick={() => this.setState({ openClientAddressModal: false })}
              onSave={() => {
                this.setState({ openClientAddressModal: false }, () => this.getAddresses());
              }}
            />
          }
        />
      </StyledShopDeliveryAddress>
    );
  }
}

var shopDeliveryAddress = withParamsAndNavigate(ShopDeliveryAddress);
export { shopDeliveryAddress as ShopDeliveryAddress };
