import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography";
import Input from "../../Global/Elements/Forms/Input.js";
import { withParamsAndNavigate, validateEmailFormat } from "../../Global/tools.js";

import { ReactComponent as XMark } from "../../Global/Icons/xmark.svg";
import { ReactComponent as ArrowL } from "../../Global/Icons/arrowL.svg";

import clientService from "../../../services/ClientService.js";

const StyledRecoverPasswordSection = styled(NanoFlex)`
  padding: 5px 20px 20px 20px;
  gap: 10px;
  .back {
    cursor: pointer;
    width: auto;
    gap: 5px;
    padding: 5px 0px;

    .backIcon {
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .backTitle {
      border-bottom: 2px solid ${(props) => props.theme.color.main.font};
    }
  }

  .field {
    height: auto;
    gap: 10px;
    .fieldTitle {
      h3 {
        color: ${(props) => props.theme.color.colorsPalette.grey};
      }
    }
    .forgotPassword {
      cursor: pointer;
      h5 {
        color: ${(props) => props.theme.color.colorsPalette.red};
        text-decoration: underline;
      }
    }
  }

  .emailPasswordInvalid {
    h4 {
      color: ${(props) => props.theme.color.colorsPalette.red};
    }
  }

  .btnContainer {
    gap: 30px;
    .btn {
      cursor: pointer;
      width: 200px;
      height: 40px;
      border-radius: 30px;
      background-color: ${(props) => props.theme.color.main.font};
      h3 {
        color: ${(props) => props.theme.color.main.white};
      }
    }
  }
`;

class RecoverPasswordSection extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      email: "",
      emailError: false,
      errorMessage: "",
      result: null,
    };
  };

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  validateFields = () => {
    let emailError = false;
    let isValid = true;
    if (!this.state.email || this.state.email.trim() === "" || !validateEmailFormat(this.state.email)) {
      emailError = true;
      isValid = false;
    }

    this.setState({
      emailError: emailError,
    });

    return isValid;
  };

  login = () => {
    if (this.validateFields()) {
      clientService
        .resetPassword(this.state.email)
        .then((response) => {
          if (response.ok || response.status === 400) {
            return response.json();
          }
        })
        .then((data) => {
          if (data.message) {
            this.setState({ errorMessage: data.message });
          } else {
            this.setState({ result: data ? true : false });
          }
        });
    }
  };

  render() {
    return (
      <StyledRecoverPasswordSection flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
        <NanoFlex className="back" justifyContent={"flex-start"} onClick={this.props.onCancel}>
          <NanoFlex className="backIcon">
            <ArrowL />
          </NanoFlex>
          <NanoFlex className="backTitle">
            <Type.h4>Voltar</Type.h4>
          </NanoFlex>
        </NanoFlex>
        {this.state.result === null && (
          <NanoFlex className="field" flexDirection="column" justifyContent="flex-start">
            <NanoFlex className="fieldTitle" justifyContent="flex-start">
              <Type.h3>Email*</Type.h3>
            </NanoFlex>
            <Input
              type="text"
              placeholder="Email"
              defaultValue={this.state.email}
              error={this.state.emailError}
              onChangeAction={(val) => this.setState({ email: val })}
            />
          </NanoFlex>
        )}
        {this.state.errorMessage && this.state.errorMessage.trim() !== "" && (
          <NanoFlex className="emailPasswordInvalid" justifyContent="flex-start">
            <Type.h4>{this.state.errorMessage}</Type.h4>
          </NanoFlex>
        )}
        {this.state.result === true && (
          <NanoFlex className="emailPasswordSuccess" justifyContent="flex-start">
            <Type.h4>Email enviado com sucesso!</Type.h4>
          </NanoFlex>
        )}
        <NanoFlex className="btnContainer" justifyContent="center">
          <NanoFlex className="btn" onClick={() => this.login()}>
            <Type.h3>Enviar</Type.h3>
          </NanoFlex>
        </NanoFlex>
      </StyledRecoverPasswordSection>
    );
  }
}

export default withParamsAndNavigate(RecoverPasswordSection);
