import { BaseService } from "./BaseService";

export class ShopService extends BaseService {
  getShops(filterByCompleted) {
    return this.request(`api/Shop/GetShops/${filterByCompleted}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getShopDetail(id) {
    return this.request(`api/Shop/GetShopDetail/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getShopLines() {
    return this.request("api/Shop/GetShopLines", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getShopLinesCount() {
    return this.request("api/Shop/GetShopLinesCount", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  addShopLine(line) {
    return this.request("api/Shop/AddShopLine", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(line),
    });
  }

  removeShopLine(idProductVariation) {
    return this.request(`api/Shop/RemoveShopLine/${idProductVariation}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "DELETE",
    });
  }

  getShipmentCost(isIsland) {
    return this.request(`api/Shop/GetShipmentCost/${isIsland}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getShopState(ref) {
    return this.request(`api/Shop/GetShopState/${ref}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  copyShopLines(id) {
    return this.request(`api/Shop/CopyShopLines/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  cancelShopState(id) {
    return this.request(`api/Shop/CancelShopState/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getPaymentGateway(model) {
    return this.request("api/Shop/GetPaymentGateway", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(model),
    });
  }
}

const shopService = new ShopService();

export default shopService;
