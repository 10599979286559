import { BaseService } from "./BaseService";

export class BrandService extends BaseService {
  getBrands(filter) {
    return this.request("api/Brand/GetBrands", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(filter),
    });
  }
}

const brandService = new BrandService();

export default brandService;
