import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import { withParamsAndNavigate } from "./../../Global/tools.js";
import Type from "../../Global/Typography";

import { ReactComponent as XMark } from "../../Global/Icons/xmark.svg";

import LoginSection from "./LoginSection.js";
import RegisterSection from "./RegisterSection.js";
import RecoverPasswordSection from "./RecoverPasswordSection.js";

const StyledLogin = styled(NanoFlex)`
  background-color: ${(props) => props.theme.color.main.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;

  .title {
    padding: 20px 20px 0px 20px;
    height: auto;
    .titleContainer {
      gap: 4vw;
      .titleBtnContainer {
        gap: 10px;
        h1 {
          text-transform: none;
          color: ${(props) => props.theme.color.colorsPalette.grey};
          font-weight: bold;

          &.active {
            color: ${(props) => props.theme.color.colorsPalette.red};
          }
          &.titleBtn:not(.active) {
            cursor: pointer;
          }
        }
      }
      .closeContainer {
        width: auto;
        cursor: pointer;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
    .underline {
      height: 0px;
      border-bottom: 1px solid ${(props) => props.theme.color.colorsPalette.grey};
    }
  }
`;

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();

    this.nodeRef = React.createRef();
  }

  getDefaultState = () => {
    return {
      isLogin: true,
      isRecoverPassword: false,
    };
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = (e) => {
    if (!this.nodeRef.current.contains(e.target)) this.onCancel();
  };

  onLogin = () => {
    this.setState(this.getDefaultState(), () => {
      this.props.onLogin();
    });
  };

  onCancel = () => {
    this.setState(this.getDefaultState(), () => {
      this.props.onCancel();
    });
  };

  render() {
    return (
      <StyledLogin ref={this.nodeRef} flexDirection="column">
        <NanoFlex className="title" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          <NanoFlex className="titleContainer" justifyContent="space-between">
            {!this.state.isRecoverPassword && (
              <NanoFlex className="titleBtnContainer" justifyContent="flex-start">
                <Type.h1 className={`titleBtn ${this.state.isLogin ? "active" : ""}`} onClick={() => this.setState({ isLogin: true })}>
                  Iniciar Sessão
                </Type.h1>
                <Type.h1>|</Type.h1>
                <Type.h1 className={`titleBtn ${!this.state.isLogin ? "active" : ""}`} onClick={() => this.setState({ isLogin: false })}>
                  Registar
                </Type.h1>
              </NanoFlex>
            )}
            {this.state.isRecoverPassword && (
              <NanoFlex className="titleBtnContainer" justifyContent="flex-start">
                <Type.h1 className="titleBtn active">Recuperar palavra passe</Type.h1>
              </NanoFlex>
            )}
            <NanoFlex className="closeContainer" justifyContent="flex-end" onClick={this.props.onCancel}>
              <XMark />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="underline"></NanoFlex>
        </NanoFlex>
        <NanoFlex className="leftSection">
          {!this.state.isRecoverPassword && this.state.isLogin && (
            <LoginSection onRecoverPassword={() => this.setState({ isRecoverPassword: true })} onCancel={this.onCancel} onLogin={this.onLogin} />
          )}
          {!this.state.isRecoverPassword && !this.state.isLogin && <RegisterSection onCancel={this.onCancel} onLogin={this.onLogin} />}
          {this.state.isRecoverPassword && <RecoverPasswordSection onCancel={() => this.setState({ isRecoverPassword: false })} />}
        </NanoFlex>
      </StyledLogin>
    );
  }
}

export default withParamsAndNavigate(Login);
