import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";

const StyledTextarea = styled(NanoFlex)`
  .title {
    margin-bottom: 4px;
    color: ${(props) => (props.error ? props.theme.color.colorsPalette.red : props.theme.color.main.font)};
    font-weight: bold;
    text-transform: uppercase;
  }
  textarea {
    border: 1px solid ${(props) => (props.error ? props.theme.color.colorsPalette.red : props.theme.color.main.font)};
    padding: 8px;
    width: 100%;
    height: 136px;
    font-size: 16px;
    color: ${(props) => props.theme.color.main.font};
    font-family: ${(props) => props.theme.font};
    resize: none;
    background-color: ${(props) =>
      props.error ? "#FFFDDC" : props.disabled ? props.theme.color.colorsPalette.lighterGrey : props.theme.color.main.white};
    pointer-events: ${(props) => (props.disabled ? "none" : "unset")};
    &::placeholder {
      font-family: ${(props) => props.theme.font};
      color: ${(props) => props.theme.color.colorsPalette.grey};
    }
    &:focus {
      border: 1px solid ${(props) => (props.error ? props.theme.color.colorsPalette.red : props.theme.color.main.font)};
    }
  }
`;

class Textarea extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.inputRef.current.value = this.props.defaultValue;
    }
  }

  render() {
    return (
      <StyledTextarea
        className="textAreaContainer"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        error={this.props.error}
        disabled={this.props.disabled}
      >
        {this.props.title && (
          <Type.p className="title">
            <b>{this.props.title}</b>
          </Type.p>
        )}
        <NanoFlex className="textAreaWrapper">
          <textarea
            ref={this.inputRef}
            type={this.props.title}
            placeholder={this.props.placeholder}
            defaultValue={this.props.defaultValue}
            onChange={(e) => {
              if (this.props.onChangeAction) {
                this.props.onChangeAction(e.target.value);
              }
            }}
          ></textarea>
        </NanoFlex>
      </StyledTextarea>
    );
  }
}

// Set default props
Textarea.defaultProps = {
  title: null,
  placeholder: null,
};

export default Textarea;
