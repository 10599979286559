import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import { withParamsAndNavigate } from "../../Global/tools.js";

import { ReactComponent as TruckFast } from "../../Global/Icons/truckFast.svg";

//Services
import shopService from "./../../../services/ShopService.js";

const StyledShopSuccess = styled(NanoFlex)`
  padding: 0px 5vw;

  height: 35vh;
  gap: 20px;
  .icon {
    height: auto;
    svg {
      width: 24px;
      height: 24px;

      fill: ${(props) => props.theme.color.colorsPalette.lightBlack};
    }
  }

  .message {
    gap: 5px;
    height: auto;
    h2 {
      text-align: center;
      font-weight: bold;
      color: ${(props) => props.theme.color.colorsPalette.lightBlack};
      text-align: center;
    }

    h3 {
      text-align: center;
    }

    .underline {
      cursor: pointer;
      height: 0px;
      border-bottom: 1px solid ${(props) => props.theme.color.colorsPalette.grey};
    }

    .bold {
      font-weight: bold;
    }

    a {
      all: unset;
    }
  }
`;

class ShopSuccess extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      shopState: null,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.getShopState();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getShopState = () => {
    shopService
      .getShopState(this.props.params.order)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        this.setState({ shopState: data });
      });
  };

  render() {
    return (
      <StyledShopSuccess flexDirection={"column"}>
        <NanoFlex className="icon">
          <TruckFast />
        </NanoFlex>
        <NanoFlex className="message" flexDirection="column">
          <Type.h2>Encomenda efetuada com sucesso</Type.h2>
          <Type.h3>
            Nº Encomenda:{" "}
            <Link to="/cliente?menu=0">
              <span className="underline bold">{this.props.params.order}</span>
            </Link>
          </Type.h3>
          {this.state.shopState && (
            <Type.h3>
              Estado: <span className="bold">{this.state.shopState}</span>
            </Type.h3>
          )}
          <Type.h3>
            Para mais informações dirija-se a{" "}
            <Link to="/cliente?menu=0">
              <span className="underline">área de cliente</span>
            </Link>{" "}
            ou{" "}
            <span className="underline" onClick={() => window.dispatchEvent(new Event("openContactUsModal"))}>
              contate-nos
            </span>
          </Type.h3>
        </NanoFlex>
      </StyledShopSuccess>
    );
  }
}

var shopSuccess = withParamsAndNavigate(ShopSuccess);
export { shopSuccess as ShopSuccess };
