import React from "react";
import styled, { css } from "styled-components";
import DOMPurify from "dompurify";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex";
import Modal from "../../Global/Elements/Modal.js";
import Type from "../../Global/Typography.js";
import Image from "../../Global/Elements/Image.js";
import { urlExistsAsync } from "../../Global/tools.js";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import pageService from "./../../../services/PageService.js";

import ContactUs from "../Shared/ContactUs.js";

const StyledHome = styled(NanoFlex)`
  padding: 0px 5vw;

  .sectionContainer {
    position: relative;
    border-right: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
    border-left: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
    .btnContactUs {
      ${(props) =>
        !props.isMobile &&
        css`
          position: absolute;
          top: 15px;
          right: 15px;
        `}

      cursor: pointer;
      user-select: none;
      max-width: ${(props) => (props.isMobile ? "100%" : "250px")};
      height: 40px;
      background-color: ${(props) => props.theme.color.colorsPalette.red};
      gap: 10px;
      h3 {
        color: ${(props) => props.theme.color.main.white};
      }
    }

    .titleContainer {
      padding: 0px 20px 20px 20px;
      h1,
      h2 {
        white-space: break-spaces;
      }
    }

    .carouselWrapper {
      padding: 0px 20px;
      .slider-wrapper {
        height: 100% !important;
      }
      .thumbs-wrapper {
        text-align: center;
      }
      .control-arrow,
      .carousel-status {
        display: none;
      }
      .imgContainer {
        max-height: 500px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .pageContentContainer {
      padding: 20px;
      gap: 40px;
      .pageContent {
        gap: 20px;
        .imageContainer {
          img {
            width: 100%;
            height: 100%;
            max-height: 500px;
            object-fit: contain;
          }
        }

        ${(props) =>
          !props.isMobile &&
          css`
            &:nth-child(even) {
              .descriptionContainer {
                order: 1;
              }
              .imageContainer {
                order: 2;
              }
            }
          `}
      }
    }

    .htmlDiv {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        font: revert;
        margin: revert !important;
        padding: revert;
      }
      strong {
        font-weight: bold;
      }
      em {
        font-style: italic;
      }
    }
  }
`;

export class PageContent extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      pageContent: null,
      loadingImages: true,
      sliderImages: null,
      openContactUsModal: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.getContent();
    this.loadImages().then();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState.pageContent) !== JSON.stringify(this.state.pageContent)) {
      this.loadSocialMedia();
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getContent = () => {
    pageService
      .getContent(this.props.id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        this.setState({ pageContent: data });
      });
  };

  loadSocialMedia = () => {
    window.dispatchEvent(
      new CustomEvent("changeSocialMediaUrl", {
        detail: {
          instagram: this.state.pageContent?.instagram,
          facebook: this.state.pageContent?.facebook,
        },
      }),
    );
  };

  loadImages = async () => {
    let sliderImages = [];

    for (let c = 1; c <= 10; c++)
      if (await urlExistsAsync(`/api/${this.props.imgPath}/Slider/Slide${c}.jpg`))
        sliderImages.push(`/api/${this.props.imgPath}/Slider/Slide${c}.jpg`);

    this.setState({ loadingImages: false, sliderImages: sliderImages });
  };

  openContactUs = () => {};

  render() {
    return (
      <StyledHome flexDirection={"column"} isMobile={this._isMobile}>
        <NanoFlex className="sectionContainer" flexDirection={"column"}>
          <NanoFlex className="btnContactUs" onClick={() => this.setState({ openContactUsModal: true })}>
            <Type.h3>PEDIR ORÇAMENTO</Type.h3>
          </NanoFlex>
          <NanoFlex className="titleContainer">
            <div className="htmlDiv" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.pageContent?.title) }}></div>
          </NanoFlex>
          {!this.state.loadingImages && this.state.sliderImages.length > 0 && (
            <NanoFlex className="carouselWrapper">
              <Carousel autoPlay infiniteLoop emulateTouch useKeyboardArrows stopOnHover dynamicHeight={true}>
                {this.state.sliderImages.map((s) => {
                  return (
                    <NanoFlex key={s} className="imgContainer">
                      <Image imageUrl={s} />
                    </NanoFlex>
                  );
                })}
              </Carousel>
            </NanoFlex>
          )}
          {this.state.pageContent?.items?.length > 0 && (
            <NanoFlex className="pageContentContainer" flexDirection={"column"}>
              {this.state.pageContent.items.map((pc) => {
                return (
                  <NanoFlex key={pc.id} className="pageContent" alignItems={"flex-start"} flexDirection={this._isMobile ? "column" : "row"}>
                    <NanoFlex className="imageContainer">
                      <Image imageUrl={`/api/${this.props.imgPath}/${pc.position}.jpg`} />
                    </NanoFlex>
                    <NanoFlex className="descriptionContainer" justifyContent={"flex-start"}>
                      <div className="htmlDiv" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(pc.text) }}></div>
                    </NanoFlex>
                  </NanoFlex>
                );
              })}
            </NanoFlex>
          )}
        </NanoFlex>
        <Modal
          show={this.state.openContactUsModal}
          content={<ContactUs isPageContent subject={this.state.pageContent?.name} onCancel={() => this.setState({ openContactUsModal: false })} />}
        />
      </StyledHome>
    );
  }
}
