import { BaseService } from "./BaseService";

export class ProductService extends BaseService {
  getProducts(filter) {
    return this.request("api/Product/GetProducts", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(filter),
    });
  }

  getProductDetails(tag) {
    return this.request(`api/Product/GetProductDetails/${tag}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }
}

const productService = new ProductService();

export default productService;
