import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled, { css } from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import { withParamsAndNavigate, getQueryStringObj } from "./../../Global/tools.js";

//Icons
import { ReactComponent as TruckFast } from "../../Global/Icons/truckFast.svg";
import { ReactComponent as LocationDot } from "../../Global/Icons/locationDot.svg";
import { ReactComponent as Logout } from "../../Global/Icons/logout.svg";
import { ReactComponent as ArrowR } from "../../Global/Icons/arrowR.svg";

//Nav Items
import { ClientShopHistory } from "./ClientShopHistory.js";
import { ClientAddress } from "./ClientAddress.js";

const StyledClient = styled(NanoFlex)`
  padding: 30px 5vw 30px 5vw;
  gap: 20px;

  .navContainer {
    padding: 20px;
    box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
    border: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
    gap: 10px;
    ${(props) =>
      !props.isMobile &&
      css`
        max-width: 350px;
      `}

    .clientName {
      padding-bottom: 10px;
      h2 {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: bold;
      }
    }

    .navItem {
      gap: 20px;
      height: 50px;
      cursor: pointer;
      .navIcon {
        width: auto;
        svg {
          width: 24px;
          height: 24px;
        }
      }
      .navArrow {
        width: auto;
        svg {
          width: 24px;
          height: 24px;
        }
      }

      &:not(.preventBorder) {
        border-bottom: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
      }
      &.active {
        border-color: ${(props) => props.theme.color.colorsPalette.red};
        .navArrow {
          svg {
            path {
              fill: ${(props) => props.theme.color.colorsPalette.red};
            }
          }
        }
      }
    }
  }
`;

class Client extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onLogoutClick = () => {
    this.clientLogout();
  };

  render() {
    let param = getQueryStringObj(this.props.location.search);
    let selectedNav = (param?.menu ? Number(param?.menu) : null) ?? (this._isMobile ? null : 0);
    return (
      <StyledClient alignItems={"flex-start"} isMobile={this._isMobile}>
        {(!this._isMobile || selectedNav === null) && (
          <NanoFlex className="navContainer" flexDirection={"column"}>
            <NanoFlex className="clientName">
              <Type.h2>{`Olá ${this._user.Name}`}</Type.h2>
            </NanoFlex>
            <NanoFlex
              className={`navItem ${selectedNav === 0 ? "active" : ""}`}
              justifyContent={"flex-start"}
              onClick={() => this.props.navigate("/cliente?menu=0")}
            >
              <NanoFlex className="navIcon">
                <TruckFast />
              </NanoFlex>
              <NanoFlex className="navTitle" justifyContent={"flex-start"}>
                <Type.h3>Compras</Type.h3>
              </NanoFlex>
              <NanoFlex className="navArrow">
                <ArrowR />
              </NanoFlex>
            </NanoFlex>
            <NanoFlex
              className={`navItem ${selectedNav === 1 ? "active" : ""}`}
              justifyContent={"flex-start"}
              onClick={() => this.props.navigate("/cliente?menu=1")}
            >
              <NanoFlex className="navIcon">
                <LocationDot />
              </NanoFlex>
              <NanoFlex className="navTitle" justifyContent={"flex-start"}>
                <Type.h3>Moradas</Type.h3>
              </NanoFlex>
              <NanoFlex className="navArrow">
                <ArrowR />
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="navItem preventBorder" justifyContent={"flex-start"} onClick={this.onLogoutClick}>
              <NanoFlex className="navIcon">
                <Logout />
              </NanoFlex>
              <NanoFlex className="navTitle" justifyContent={"flex-start"}>
                <Type.h3>Terminar Sessão</Type.h3>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        )}
        {selectedNav !== null && (
          <NanoFlex className="selectedNavContainer">
            {selectedNav === 0 && <ClientShopHistory onBackClick={() => this.props.navigate("/cliente")} />}
            {selectedNav === 1 && <ClientAddress onBackClick={() => this.props.navigate("/cliente")} />}
          </NanoFlex>
        )}
      </StyledClient>
    );
  } //Terminar Sessão
}

var client = withParamsAndNavigate(Client);
export { client as Client };
