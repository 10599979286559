import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import Image from "../../Global/Elements/Image.js";
import { randomInteger, randomDecimal, formatNumDefault } from "../../Global/tools.js";

const StyledCategoryItem = styled.div`
  box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
  border: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};

  a {
    text-decoration: none;
  }

  .imgContainer {
    overflow: hidden;
    width: 100%;
    height: 100%;
    img {
      padding: 10px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .infoContainer {
    height: 75px;
    padding: 10px 20px;
    border-top: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
  }
`;

export class CategoryItem extends Component {
  constructor(props) {
    super(props);
  }

  getImageUrl = () => {
    switch (this.props.lvl) {
      case 1:
        return `/api/families/${this.props.data.tableId}.jpg`;
      case 2:
        return `/api/categories/${this.props.data.tableId}.jpg`;
      case 3:
        return `/api/subCategories/${this.props.data.tableId}.jpg`;
      case 4:
        return `/api/subSubCategories/${this.props.data.tableId}.jpg`;
    }
  };

  render() {
    if (!this.props.data || !this.props.data.id) return null;
    return (
      <StyledCategoryItem isFavorite={randomInteger(0, 1) === 1}>
        <Link
          to={`/produtos?${new URLSearchParams({
            id: this.props.data.id,
            lvl: this.props.lvl,
          }).toString()}`}
        >
          <NanoFlex flexDirection={"column"}>
            <div className="imgContainer">
              <Image imageUrl={this.getImageUrl()} />
            </div>
            <NanoFlex className="infoContainer" flexDirection={"column"}>
              <NanoFlex className="title" justifyContent={"center"}>
                <Type.h2>{this.props.data.name}</Type.h2>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </Link>
      </StyledCategoryItem>
    );
  }
}
