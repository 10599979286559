import React from "react";
import BaseComponent from "../../BaseComponent.js";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import moment from "moment";
import Type from "../../Global/Typography.js";
import Image from "../../Global/Elements/Image.js";
import { withParamsAndNavigate, formatNumDefault, getVariationRefFormat } from "./../../Global/tools.js";
import shopService from "./../../../services/ShopService.js";

//Icons
import { ReactComponent as ArrowL } from "../../Global/Icons/arrowL.svg";

const StyledClientShopHistoryItem = styled(NanoFlex)`
  gap: 10px;
  padding: 20px;
  box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
  border: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};

  .back {
    cursor: pointer;
    width: auto;
    gap: 5px;
    padding: 5px 0px;

    .backIcon {
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .backTitle {
      border-bottom: 2px solid ${(props) => props.theme.color.main.font};
    }
  }

  .shopDetailContainer {
    gap: 20px;
    .shopItem {
      padding: 20px;
      box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
      border: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
      gap: ${(props) => (props.isMobile ? "10px" : "20px")};

      .shopItemBlockContainer {
        gap: 10px;
        .shopItemBlock {
          width: auto;
          gap: 10px;
          .shopNumber {
            gap: 5px;
            h3 {
              font-weight: bold;
            }
          }

          .btn {
            padding: 10px;
            cursor: pointer;
            user-select: none;
            max-width: 200px;
            height: 40px;
            background-color: ${(props) => props.theme.color.colorsPalette.red};
            gap: 10px;
            svg {
              height: 25px;
              width: 25px;

              fill: white;
            }
            h3,
            h4 {
              color: white;
              text-align: center;
            }

            &:hover {
              background-color: ${(props) => props.theme.color.colorsPalette.lightBlack};
            }
          }
        }
      }
    }
  }

  .shopItemsContainer {
    gap: 20px;
    .shopItem {
      height: 170px;
      box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
      border: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
      .imgContainer {
        max-width: 150px;
        overflow: hidden;
        width: 100%;
        height: 100%;
        img {
          padding: 10px;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .productInfo {
        padding: 20px 20px;
        gap: 10px;
        .info {
          .ref {
            height: auto;
          }

          .title {
            height: auto;

            h3,
            h4 {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
  }

  .shopInfoContainer {
    gap: ${(props) => (props.isMobile ? "10px" : "20px")};

    .shopInfoLine {
      gap: ${(props) => (props.isMobile ? "10px" : "20px")};
      .shopInfoItem {
        height: auto;
        gap: 5px;
        padding: 20px;
        box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
        border: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};

        .title {
          color: ${(props) => props.theme.color.colorsPalette.red};
        }

        .bold {
          font-weight: bold;
        }
        /* background-color: red; */
      }
    }
  }
`;

class ClientShopHistoryItem extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      shopDetail: null,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });

    this.getShopDetail();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getShopDetail = () => {
    shopService
      .getShopDetail(this.props.id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        this.setState({ shopDetail: data });
      });
  };

  render() {
    console.log(this.state.shopDetail);
    return (
      <StyledClientShopHistoryItem isMobile={this._isMobile} flexDirection={"column"} alignItems={"flex-start"}>
        <NanoFlex className="back" justifyContent={"flex-start"} onClick={this.props.onBackClick}>
          <NanoFlex className="backIcon">
            <ArrowL />
          </NanoFlex>
          <NanoFlex className="backTitle">
            <Type.h4>Voltar</Type.h4>
          </NanoFlex>
        </NanoFlex>
        {this.state.shopDetail && (
          <NanoFlex className="shopDetailContainer" flexDirection={"column"}>
            <NanoFlex className="shopItem" flexDirection="column">
              <NanoFlex
                className="shopItemBlockContainer"
                flexDirection={this._isMobile ? "column" : "row"}
                alignItems={"center"}
                justifyContent="space-between"
              >
                <NanoFlex className="shopItemBlock" flexDirection="column" justifyContent="flex-start">
                  <NanoFlex className="shopNumber" justifyContent={"flex-start"}>
                    <Type.h4>Nº Encomenda:</Type.h4>
                    <Type.h3>{this.state.shopDetail.ref}</Type.h3>
                  </NanoFlex>
                  <NanoFlex className="shopNumber" justifyContent="flex-start">
                    <Type.h4>Data da Compra:</Type.h4>
                    <Type.h3>{moment(this.state.shopDetail.buyDate).format(this.props.theme.moment.displayFormat)}</Type.h3>
                  </NanoFlex>
                  <NanoFlex className="shopNumber" justifyContent={"flex-start"}>
                    <Type.h4>Valor:</Type.h4>
                    <Type.h3>{this.state.shopDetail.total}€</Type.h3>
                  </NanoFlex>
                  <NanoFlex className="shopNumber" justifyContent="flex-start">
                    <Type.h4>Estado:</Type.h4>
                    <Type.h3>{this.state.shopDetail.shopStateDescription}</Type.h3>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="shopItemsContainer" flexDirection="column">
              {this.state.shopDetail.products.map((l) => {
                return (
                  <NanoFlex key={l.idProductVariation} className="shopItem">
                    <Link to={`/produtos/${l.productTag}`}>
                      <div className="imgContainer">
                        <Image id={l.idProduct} imageUrl={`/api/products/${l.idProduct}/1.jpg`} />
                      </div>
                    </Link>
                    <NanoFlex className="productInfo" justifyContent="flex-start">
                      <NanoFlex className="info" flexDirection="column">
                        <NanoFlex className="title" justifyContent="flex-start">
                          <Type.h3>{l.productName}</Type.h3>
                        </NanoFlex>
                        <NanoFlex className="title" justifyContent="flex-start">
                          <Type.h4>{l.variationName}</Type.h4>
                        </NanoFlex>
                        <NanoFlex className="ref" justifyContent="flex-start">
                          <Type.h4>REF: {getVariationRefFormat(l.ref)}</Type.h4>
                        </NanoFlex>
                        <NanoFlex className="title" justifyContent="flex-start">
                          <Type.h4>Quantidade: {l.quantity}</Type.h4>
                        </NanoFlex>
                        <NanoFlex className="title" justifyContent="flex-start">
                          <Type.h4>Preço: {formatNumDefault((l.promo ?? l.pvp) * l.quantity, 2, 2)} €</Type.h4>
                        </NanoFlex>
                      </NanoFlex>
                    </NanoFlex>
                  </NanoFlex>
                );
              })}
            </NanoFlex>
            <NanoFlex className="shopInfoContainer" flexDirection={"column"}>
              <NanoFlex className="shopInfoLine" flexDirection={this._isMobile ? "column" : "row"} alignItems={"stretch"}>
                <NanoFlex className="shopInfoItem" flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
                  <Type.h3 className="title bold">Dados de faturação:</Type.h3>
                  <Type.h4 className="bold">{this.state.shopDetail.billingAddress?.name}</Type.h4>
                  <Type.h4>{this.state.shopDetail.billingAddress?.address}</Type.h4>
                  <Type.h4>{this.state.shopDetail.billingAddress?.postCode}</Type.h4>
                  <Type.h4>T: {this.state.shopDetail.billingAddress?.phone}</Type.h4>
                </NanoFlex>
                <NanoFlex className="shopInfoItem" flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
                  <Type.h3 className="title bold">Dados de entrega:</Type.h3>
                  <Type.h4 className="bold">{this.state.shopDetail.deliveryAddress?.name ?? this._user.Name}</Type.h4>
                  {this.state.shopDetail.deliveryAddress && (
                    <React.Fragment>
                      <Type.h4>{this.state.shopDetail.deliveryAddress.address}</Type.h4>
                      <Type.h4>{this.state.shopDetail.deliveryAddress.postCode}</Type.h4>
                      <Type.h4>T: {this.state.shopDetail.deliveryAddress.phone}</Type.h4>
                    </React.Fragment>
                  )}
                  {!this.state.shopDetail.deliveryAddress && (
                    <React.Fragment>
                      <Type.h4>Rua Paz Nº10</Type.h4>
                      <Type.h4>2830 - 046 Quinta das Gateiras, Barreiro</Type.h4>
                    </React.Fragment>
                  )}
                </NanoFlex>
              </NanoFlex>
              <NanoFlex className="shopInfoLine" flexDirection={this._isMobile ? "column" : "row"} alignItems={"stretch"}>
                <NanoFlex className="shopInfoItem" flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
                  <Type.h3 className="title bold">Meio de pagamento:</Type.h3>
                  <Type.h4 className="bold">{this.state.shopDetail.paymentMethod}</Type.h4>
                </NanoFlex>
                <NanoFlex className="shopInfoItem" flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
                  <Type.h3 className="title bold">Forma de entrega:</Type.h3>
                  <Type.h4 className="bold">{this.state.shopDetail.deliveryAddress ? "Entrega ao domicilio" : "Levantamento em loja"}</Type.h4>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        )}
        {/* {this.state.shopItems && this.state.shopItems.length > 0 && (
          <NanoFlex className="shopContainer" flexDirection="column">
            {this.state.shopItems.map((s) => {
              return (
                <NanoFlex key={s.id} className="shopItem" flexDirection="column">
                  <NanoFlex
                    className="shopItemBlockContainer"
                    flexDirection={this._isMobile ? "column" : "row"}
                    alignItems={"center"}
                    justifyContent="space-between"
                  >
                    <NanoFlex className="shopItemBlock" flexDirection="column" justifyContent="flex-start">
                      <NanoFlex className="shopNumber" justifyContent={"flex-start"}>
                        <Type.h4>Nº Encomenda:</Type.h4>
                        <Type.h3>{s.ref}</Type.h3>
                      </NanoFlex>
                      <NanoFlex className="shopNumber" justifyContent="flex-start">
                        <Type.h4>Data da Compra:</Type.h4>
                        <Type.h3>{moment(s.buyDate).format(this.props.theme.moment.displayFormat)}</Type.h3>
                      </NanoFlex>
                      <NanoFlex className="shopNumber" justifyContent={"flex-start"}>
                        <Type.h4>Valor:</Type.h4>
                        <Type.h3>{s.total}€</Type.h3>
                      </NanoFlex>
                      <NanoFlex className="shopNumber" justifyContent="flex-start">
                        <Type.h4>Estado:</Type.h4>
                        <Type.h3>{s.shopStateDescription}</Type.h3>
                      </NanoFlex>
                    </NanoFlex>
                    <NanoFlex className="shopItemBlock" flexDirection="column" alignItems={this._isMobile ? "flex-start" : "flex-end"}>
                      <NanoFlex className="btn" onClick={() => this.copyShop(s.id)}>
                        <CartShoppingSolid />
                        {this._isMobile && <Type.h4>Ver Detalhes</Type.h4>}
                        {!this._isMobile && <Type.h3>Ver Detalhes</Type.h3>}
                      </NanoFlex>
                      <NanoFlex className="btn" onClick={() => this.copyShop(s.id)}>
                        <CartPlus />
                        {this._isMobile && <Type.h4>Voltar a Comprar</Type.h4>}
                        {!this._isMobile && <Type.h3>Voltar a Comprar</Type.h3>}
                      </NanoFlex>
                      {s.idShopState === 2 && (
                        <NanoFlex className="btn" onClick={() => this.cancelShop(s.id)}>
                          <XMark />
                          {this._isMobile && <Type.h4>Cancelar Compra</Type.h4>}
                          {!this._isMobile && <Type.h3>Cancelar Compra</Type.h3>}
                        </NanoFlex>
                      )}
                    </NanoFlex>
                  </NanoFlex>
                </NanoFlex>
              );
            })}
          </NanoFlex>
        )} */}
      </StyledClientShopHistoryItem>
    );
  }
}

var clientShopHistoryItem = withParamsAndNavigate(ClientShopHistoryItem);
export { clientShopHistoryItem as ClientShopHistoryItem };
