import React, { Component } from "react";
import BaseComponent from "../../BaseComponent.js";
import styled, { css } from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import { Popper, Reference, Manager } from "react-popper";
import { elementMatchesAny, withParamsAndNavigate } from "./../../Global/tools.js";

import categoriesService from "./../../../services/CategoriesService.js";

import { ReactComponent as Bars } from "../../Global/Icons/barsSolid.svg";
import { ReactComponent as ArrowR } from "../../Global/Icons/arrowR.svg";
import { ReactComponent as ArrowL } from "../../Global/Icons/arrowL.svg";
import { ReactComponent as Notch } from "../../Global/Icons/notch.svg";

const StyledCategoriesItem = styled(NanoFlex)`
  border-bottom: 2px solid ${(props) => (props.active ? props.theme.color.colorsPalette.red : props.theme.color.colorsPalette.lighterGrey)};
  padding: 15px 5px 15px 15px;
  .titleContainer {
    user-select: none;
    padding-right: 10px;
    min-width: 200px;
    white-space: nowrap;
    ${(props) =>
      props.active &&
      css`
        font-weight: bold;
      `}
  }

  .iconContainer {
    cursor: pointer;
    width: auto;
    svg {
      width: 12px;
      height: 12px;

      path {
        fill: ${(props) => (props.active ? props.theme.color.colorsPalette.red : "black")};
      }
    }
  }
`;

class CategoriesItem extends Component {
  constructor(props) {
    super(props);
  }

  onItemClick = (e) => {
    if (elementMatchesAny(e.target, [".iconContainer"])) return;
    this.props.onItemClick();
  };

  render() {
    return (
      <StyledCategoriesItem active={this.props.active} onClick={this.onItemClick}>
        <NanoFlex className="titleContainer" justifyContent={"flex-start"}>
          <Type.h4>
            {this.props.title}
            {/* {this.props.count ? `(${this.props.count})` : ""} */}
          </Type.h4>
        </NanoFlex>
        {this.props.hasNavigation && (
          <NanoFlex className="iconContainer" onClick={this.props.onNavigationClick}>
            <ArrowR />
          </NanoFlex>
        )}
      </StyledCategoriesItem>
    );
  }
}

const StyledCategoriesMenu = styled(NanoFlex)`
  width: auto;
  padding: 0px 10px 0px 20px;
  .categories {
    cursor: pointer;
    user-select: none;
    height: auto;
    gap: 2px;
    h3 {
      margin-bottom: 0px;
      text-transform: uppercase;
    }
    .iconContainer {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }

  .categoriesPopup {
    z-index: 1;
    padding-left: 20px;
    .categoriesPopupNotch {
      height: 10px;
      transform: rotate(270deg);
      width: auto;
      margin-left: 50px;
      svg {
        width: 10px;
        path {
          fill: white;
        }
      }
    }
    .categoriesPopupContent {
      border: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
      box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
      background-color: white;
      padding: 10px 15px 15px 15px;
      max-height: calc(90vh - 50px);
      overflow: auto;

      .goBack {
        width: auto;
        padding: 20px 5px 0px 0px;
        .iconContainer {
          cursor: pointer;
          width: auto;
          svg {
            width: 12px;
            height: 12px;

            path {
              fill: ${(props) => (props.active ? props.theme.color.colorsPalette.red : "black")};
            }
          }
        }
      }

      .categoriesItemsContainer:not(:last-child) {
        padding-right: 20px;
      }
    }
  }
`;

class CategoriesMenu extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isPopupOpen: false,
      categories: [],
      selectedLevel1: null,
      selectedLevel2: null,
      selectedLevel3: null,
    };

    this.nodeRef = React.createRef();
  }

  componentDidMount() {
    super.componentDidMount();
    document.addEventListener("mousedown", this.handleOutsideClick);

    this.getCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location?.pathname !== this.props.location?.pathname || prevProps.location?.search !== this.props.location?.search) {
      this.setState({ isPopupOpen: false });
    }

    if (prevState.isPopupOpen !== this.state.isPopupOpen && !this.state.isPopupOpen) {
      this.setState({ selectedLevel1: null, selectedLevel2: null, selectedLevel3: null });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = (e) => {
    if (!this.nodeRef.current.contains(e.target))
      this.setState({
        isPopupOpen: false,
        selectedLevel1: null,
        selectedLevel2: null,
        selectedLevel3: null,
      });
  };

  getCategories = () => {
    categoriesService
      .getCategories()
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        this.setState({ categories: data });
      });
  };

  onFilterClick = (filter) => {
    this.props.navigate("/produtos?" + new URLSearchParams(filter).toString());
    this.setState({ isPopupOpen: false });
  };

  onGoBackClick = () => {
    if (this.state.selectedLevel3) this.setState({ selectedLevel3: null });
    else if (this.state.selectedLevel2) this.setState({ selectedLevel2: null });
    else if (this.state.selectedLevel1) this.setState({ selectedLevel1: null });
  };

  render() {
    return (
      <StyledCategoriesMenu ref={this.nodeRef} className="categoriesContainer">
        <Manager>
          <Reference>
            {(targetProps) => {
              return (
                <NanoFlex
                  ref={targetProps.ref}
                  className="categories"
                  justifyContent={"center"}
                  flexDirection={"column"}
                  onClick={() => this.setState({ isPopupOpen: !this.state.isPopupOpen })}
                >
                  <NanoFlex className="iconContainer">
                    <Bars />
                  </NanoFlex>
                  <Type.h3>Menu</Type.h3>
                </NanoFlex>
              );
            }}
          </Reference>
          {this.state.isPopupOpen && (
            <Popper placement={"bottom"}>
              {({ ref, style, placement, arrowProps }) => (
                <div className="categoriesPopup" ref={ref} style={style} data-placement={placement}>
                  <NanoFlex flexDirection={"column"} justifyContent={"flex-start"}>
                    {/* <NanoFlex justifyContent={"flex-start"}>
                      <NanoFlex className="categoriesPopupNotch">
                        <Notch />
                      </NanoFlex>
                    </NanoFlex> */}
                    <NanoFlex className="categoriesPopupContent" alignItems={"flex-start"}>
                      {this._isMobile && this.state.selectedLevel1 && (
                        <NanoFlex className="goBack">
                          <NanoFlex className="iconContainer" onClick={this.onGoBackClick}>
                            <ArrowL />
                          </NanoFlex>
                        </NanoFlex>
                      )}
                      {this.state.categories && (!this._isMobile || !this.state.selectedLevel1) && (
                        <NanoFlex className="categoriesItemsContainer" flexDirection={"column"}>
                          {this.state.categories.map((c, i) => {
                            return (
                              <CategoriesItem
                                key={i}
                                hasNavigation={c.items?.length > 0}
                                title={c.name}
                                count={c.productsCount}
                                active={c.id === this.state.selectedLevel1?.id}
                                onNavigationClick={() =>
                                  this.setState({
                                    selectedLevel1: this.state.selectedLevel1?.id !== c.id ? c : null,
                                    selectedLevel2: null,
                                    selectedLevel3: null,
                                  })
                                }
                                onItemClick={() =>
                                  this.onFilterClick({
                                    id: c.id,
                                    lvl: 1,
                                  })
                                }
                              />
                            );
                          })}
                        </NanoFlex>
                      )}
                      {this.state.selectedLevel1 && (!this._isMobile || !this.state.selectedLevel2) && (
                        <NanoFlex className="categoriesItemsContainer" flexDirection={"column"}>
                          {this.state.selectedLevel1.items.map((c, i) => {
                            return (
                              <CategoriesItem
                                key={i}
                                hasNavigation={c.items?.length > 0}
                                title={c.name}
                                count={c.productsCount}
                                active={c.id === this.state.selectedLevel2?.id}
                                onNavigationClick={() =>
                                  this.setState({
                                    selectedLevel2: this.state.selectedLevel2?.id !== c.id ? c : null,
                                    selectedLevel3: null,
                                  })
                                }
                                onItemClick={() =>
                                  this.onFilterClick({
                                    id: c.id,
                                    lvl: 2,
                                  })
                                }
                              />
                            );
                          })}
                        </NanoFlex>
                      )}
                      {this.state.selectedLevel2 && (!this._isMobile || !this.state.selectedLevel3) && (
                        <NanoFlex className="categoriesItemsContainer" flexDirection={"column"}>
                          {this.state.selectedLevel2.items.map((c, i) => {
                            return (
                              <CategoriesItem
                                key={i}
                                hasNavigation={c.items?.length > 0}
                                title={c.name}
                                count={c.productsCount}
                                active={c.id === this.state.selectedLevel3?.id}
                                onNavigationClick={() =>
                                  this.setState({
                                    selectedLevel3: this.state.selectedLevel3?.id !== c.id ? c : null,
                                  })
                                }
                                onItemClick={() =>
                                  this.onFilterClick({
                                    id: c.id,
                                    lvl: 3,
                                  })
                                }
                              />
                            );
                          })}
                        </NanoFlex>
                      )}
                      {this.state.selectedLevel3 && (
                        <NanoFlex className="categoriesItemsContainer" flexDirection={"column"}>
                          {this.state.selectedLevel3.items.map((c, i) => {
                            return (
                              <CategoriesItem
                                key={i}
                                hasNavigation={false}
                                title={c.name}
                                count={c.productsCount}
                                onItemClick={() =>
                                  this.onFilterClick({
                                    id: c.id,
                                    lvl: 4,
                                  })
                                }
                              />
                            );
                          })}
                        </NanoFlex>
                      )}
                    </NanoFlex>
                  </NanoFlex>
                </div>
              )}
            </Popper>
          )}
        </Manager>
      </StyledCategoriesMenu>
    );
  }
}

var categoriesMenu = withParamsAndNavigate(CategoriesMenu);
export { categoriesMenu as CategoriesMenu };
