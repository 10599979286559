import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography";
import Input from "../../Global/Elements/Forms/Input.js";
import { withParamsAndNavigate, validateEmailFormat } from "./../../Global/tools.js";

import { ReactComponent as XMark } from "../../Global/Icons/xmark.svg";

import clientService from "./../../../services/ClientService.js";

const StyledLoginSection = styled(NanoFlex)`
  padding: 20px;
  gap: 20px;
  .field {
    height: auto;
    gap: 10px;
    .fieldTitle {
      h3 {
        color: ${(props) => props.theme.color.colorsPalette.grey};
      }
    }
    .forgotPassword {
      cursor: pointer;
      h5 {
        color: ${(props) => props.theme.color.colorsPalette.red};
        text-decoration: underline;
      }
    }
  }

  .emailPasswordInvalid {
    h4 {
      color: ${(props) => props.theme.color.colorsPalette.red};
    }
  }

  .btnContainer {
    gap: 30px;
    .btn {
      cursor: pointer;
      width: 200px;
      height: 40px;
      border-radius: 30px;
      background-color: ${(props) => props.theme.color.main.font};
      h3 {
        color: ${(props) => props.theme.color.main.white};
      }
    }
  }
`;

class LoginSection extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      email: "",
      emailError: false,
      password: "",
      passwordError: false,
      errorMessage: "",
    };
  };

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  validateFields = () => {
    let emailError = false;
    let passwordError = false;
    let isValid = true;
    if (!this.state.email || this.state.email.trim() === "" || !validateEmailFormat(this.state.email)) {
      emailError = true;
      isValid = false;
    }

    if (!this.state.password || this.state.password.trim() === "") {
      passwordError = true;
      isValid = false;
    }

    this.setState({
      emailError: emailError,
      passwordError: passwordError,
    });

    return isValid;
  };

  login = () => {
    if (this.validateFields()) {
      clientService
        .authenticate({
          Email: this.state.email,
          Password: this.state.password,
        })
        .then((response) => {
          if (response.ok || response.status === 400) {
            return response.json();
          }
        })
        .then((data) => {
          if (data.message) {
            this.setState({ errorMessage: data.message });
          } else {
            this.setState(this.getDefaultState(), () => {
              this.clientLogin(data);
              //Call back on login prop
              this.props.onLogin();
            });
          }
        });
    }
  };

  render() {
    return (
      <StyledLoginSection flexDirection="column" justifyContent="flex-start">
        <NanoFlex className="field" flexDirection="column" justifyContent="flex-start">
          <NanoFlex className="fieldTitle" justifyContent="flex-start">
            <Type.h3>Email*</Type.h3>
          </NanoFlex>
          <Input
            type="text"
            placeholder="Email"
            defaultValue={this.state.email}
            error={this.state.emailError}
            onChangeAction={(val) => this.setState({ email: val })}
          />
        </NanoFlex>
        <NanoFlex className="field" flexDirection="column" justifyContent="flex-start">
          <NanoFlex className="fieldTitle" justifyContent="flex-start">
            <Type.h3>Palavra-Passe*</Type.h3>
          </NanoFlex>
          <Input
            type="text"
            eye={true}
            placeholder="Palavra-Passe"
            defaultValue={this.state.password}
            error={this.state.passwordError}
            onChangeAction={(val) => this.setState({ password: val })}
          />
          <NanoFlex className="forgotPassword" justifyContent="flex-start" onClick={this.props.onRecoverPassword}>
            <Type.h5>Esqueceste-te da palavra-passe?</Type.h5>
          </NanoFlex>
        </NanoFlex>
        {this.state.errorMessage && this.state.errorMessage.trim() !== "" && (
          <NanoFlex className="emailPasswordInvalid" justifyContent="flex-start">
            <Type.h4>{this.state.errorMessage}</Type.h4>
          </NanoFlex>
        )}
        <NanoFlex className="btnContainer" justifyContent="center">
          <NanoFlex className="btn" onClick={() => this.login()}>
            <Type.h3>Iniciar Sessão</Type.h3>
          </NanoFlex>
        </NanoFlex>
      </StyledLoginSection>
    );
  }
}

export default withParamsAndNavigate(LoginSection);
