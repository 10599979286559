import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import { findRecursive, flattenByKey } from "./../../Global/tools.js";
import categoriesService from "./../../../services/CategoriesService.js";

import { Filter } from "./Filter.js";
import { ProductResults } from "./ProductResults.js";

const StyledProduct = styled(NanoFlex)`
  display: ${(props) => (props.hide ? "none" : "flex")};
`;

export class Product extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      categories: null,
      filter: null,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.getCategories();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getCategories = () => {
    categoriesService
      .getCategories()
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        this.setState({ categories: data });
      });
  };

  getParentCategories = () => {
    let currentCategory = findRecursive(
      this.state.categories ?? [],
      (c, l) => c.id === this.state.filter?.categoryId && l === this.state.filter?.categoryLevel,
    );
    if (currentCategory) return [currentCategory, ...(flattenByKey(currentCategory, "parent") ?? [])].reverse();
    return [];
  };

  getCurrentCategories = () => {
    return (
      findRecursive(this.state.categories ?? [], (c, l) => c.id === this.state.filter.categoryId && l === this.state.filter.categoryLevel)?.items ??
      []
    );
  };

  render() {
    let currentCategories = this.getCurrentCategories();
    let parentCategories = this.getParentCategories();
    let hasCurrentCategories = currentCategories && currentCategories.length > 0;
    return (
      <StyledProduct flexDirection={"column"} hide={this.state.categories === null}>
        <NanoFlex flexDirection={this._isMobile ? "column" : "row"} alignItems={"start"} justifyContent={"flex-start"}>
          <Filter
            filter={this.state.filter}
            onFilterChange={(f) => this.setState({ filter: f })}
            currentCategories={currentCategories}
            parentCategories={parentCategories}
            hasCurrentCategories={hasCurrentCategories}
          />
          {!hasCurrentCategories && <ProductResults filter={this.state.filter} parentCategories={parentCategories} />}
        </NanoFlex>
      </StyledProduct>
    );
  }
}
