import React from "react";
import BaseComponent from "../../BaseComponent.js";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { Header } from "../../Views/Shared/Header";
import { Footer } from "../../Views/Shared/Footer";
import NanoFlex from "../../Global/NanoFlex";
import Modal from "../../Global/Elements/Modal.js";
import { withParamsAndNavigate } from "./../../Global/tools.js";

import UseRecoverPassword from "../Client/UseRecoverPassword.js";

const StyledLayout = styled(NanoFlex)`
  height: auto;
  .layoutBodyContainer {
    /* min-height: calc(100vh - 170px); */
    -webkit-box-pack: justify;
    justify-content: space-between;
    min-width: 0px;
  }
`;

class Layout extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      openRecoverPassword: this.props.recoverPassword,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location?.pathname !== this.props.location?.pathname || prevProps.location?.search !== this.props.location?.search) {
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    if (this.props.authorize && !this._user) return <Navigate to="/" />;
    return (
      <StyledLayout flexDirection={"column"}>
        <Header />
        <NanoFlex className="layoutBodyContainer">{React.cloneElement(this.props.children, { render: this.props.render })}</NanoFlex>
        <Footer />
        {this.state.openRecoverPassword && (
          <Modal
            show={this.state.openRecoverPassword}
            content={
              <UseRecoverPassword
                token={this.props.params.token}
                onClose={() => this.setState({ openRecoverPassword: false }, () => this.props.navigate("/"))}
              />
            }
          />
        )}
      </StyledLayout>
    );
  }
}

export default withParamsAndNavigate(Layout);
