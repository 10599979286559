import React, { Component } from "react";
import BaseComponent from "../../BaseComponent.js";
import styled, { css } from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import { withParamsAndNavigate, getQueryStringObj, categoryFieldByLevel, removeEmptyProperties } from "./../../Global/tools.js";

import brandService from "./../../../services/BrandService.js";

import { ReactComponent as CircleMark } from "../../Global/Icons/circleMark.svg";
import { ReactComponent as ArrowUp } from "../../Global/Icons/arrowUp.svg";
import { ReactComponent as ArrowDown } from "../../Global/Icons/arrowDown.svg";

import { CategoriesNavigation } from "./CategoriesNavigation.js";

import Checkbox from "../../Global/Elements/Forms/Checkbox.js";
import Input from "../../Global/Elements/Forms/Input.js";

const StyledFilter = styled(NanoFlex)`
  ${(props) =>
    !props.isMobile &&
    css`
      width: 300px;
    `}
  border-right: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
  border-bottom: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
  overflow: auto;

  .filterType {
    height: auto;
    .typeTitle {
      height: auto;
      padding: 15px 25px;
      background: ${(props) => props.theme.color.colorsPalette.lightBlack};

      h3 {
        color: white;
        font-weight: bold;
      }

      .toggleFilter {
        svg {
          width: 24px;
          height: 24px;
        }
      }

      &.filterBy {
        background: ${(props) => props.theme.color.colorsPalette.red};
        h3 {
          color: black;
        }
      }
    }

    .typeContent {
      padding: 10px;

      &.filterGroup:not(:last-child) {
        border-bottom: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
      }

      .filterTypeTitle {
        height: 25px;
        margin-bottom: 5px;
        padding-left: 15px;

        h5 {
          font-weight: bold;
        }
      }

      .filterTypeItem {
        height: 25px;
        margin-bottom: 5px;

        .leftContainer {
          width: 50px;

          svg {
            fill: ${(props) => props.theme.color.colorsPalette.red};
            height: 18px;
          }
        }

        .priceContainer {
          gap: 10px;
        }

        /* .rightContainer {
          h5 {
            font-weight: bold;
          }
        } */
      }

      .clearAllFilters {
        padding: 10px;

        h6 {
          color: ${(props) => props.theme.color.colorsPalette.red};
        }
      }
    }
  }
`;

class Filter extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();

    this.requestId = 0;
  }

  getDefaultState = () => {
    return {
      isFilterOpen: false,
      ...this.getDefaultFiltersState(),
      ...this.getDefaultSelectionState(),
    };
  };

  getDefaultFiltersState = () => {
    return {
      brands: null,
    };
  };

  getDefaultSelectionState = () => {
    let params = getQueryStringObj(this.props.location.search);
    return {
      term: params?.term,
      selectedBrands: params.brands?.split(",")?.map(Number) ?? [],
      minPrice: params.minPrice ?? null,
      maxPrice: params.maxPrice ?? null,
      hasPromo: params.hasPromo ? Boolean(params.hasPromo) : null,
      ...this.getCategoryLevel(),
    };
  };

  getCategoryLevel = (search) => {
    var params = getQueryStringObj(search ?? this.props.location.search) ?? {};
    return {
      categoryId: params.id ? Number(params.id) : null,
      categoryLevel: params.lvl ? Number(params.lvl) : null,
    };
  };

  componentDidMount() {
    super.componentDidMount();
    this.onFilter();
    if (!this.props.hasCurrentCategories) {
      this.getFilters();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    /*On Search Change */
    if (prevProps.location.search !== this.props.location.search) {
      let obj = null;
      /*On Category Level or Id change */
      let currentCategoryLevel = this.getCategoryLevel(this.props.location.search);
      if (JSON.stringify(this.getCategoryLevel(prevProps.location.search)) !== JSON.stringify(currentCategoryLevel)) {
        obj = { ...obj, ...currentCategoryLevel };
      }
      let prevParam = getQueryStringObj(prevProps.location.search);
      let param = getQueryStringObj(this.props.location.search);

      /*On Term Change */
      if (prevParam?.term !== param?.term) {
        obj = { ...obj, term: param?.term };
      }

      /*Update State if search changed */
      if (obj !== null) {
        this.setState(obj);
      }
    }

    if (
      prevState.categoryId !== this.state.categoryId ||
      prevState.categoryLevel !== this.state.categoryLevel ||
      prevState.hasPromo !== this.state.hasPromo ||
      (prevState.term ?? "") !== (this.state.term ?? "")
    ) {
      this.onFilter();
    }

    if (
      JSON.stringify(prevState.selectedBrands) !== JSON.stringify(this.state.selectedBrands) ||
      prevState.minPrice !== this.state.minPrice ||
      prevState.maxPrice !== this.state.maxPrice
    ) {
      let params = getQueryStringObj(this.props.location.search) ?? {};
      if (this.state.selectedBrands && this.state.selectedBrands.length > 0) {
        params.brands = this.state.selectedBrands.join(",");
      } else {
        params.brands = null;
      }
      params.minPrice = this.state.minPrice;
      params.maxPrice = this.state.maxPrice;

      this.props.navigate("/produtos?" + new URLSearchParams(removeEmptyProperties(params)).toString());
      this.onFilterDelay();
    }

    if (prevProps.hasCurrentCategories !== this.props.hasCurrentCategories && !this.props.hasCurrentCategories) {
      this.getFilters();
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getFilterModel = () => {
    let filter = {
      categoryId: this.state.categoryId,
      categoryLevel: this.state.categoryLevel,
      hasPromo: this.state.hasPromo,
      term: this.state.term,
      brands: this.state.selectedBrands,
      minPrice: this.state.minPrice,
      maxPrice: this.state.maxPrice,
    };

    filter[categoryFieldByLevel(this.state.categoryLevel)] = `${this.state.categoryId}`;
    return filter;
  };

  onFilter = () => {
    this.props.onFilterChange(this.getFilterModel());
  };

  onFilterDelay = () => {
    this.requestId++;
    let requestId = this.requestId;
    setTimeout(() => {
      if (this.requestId === requestId) {
        this.props.onFilterChange(this.getFilterModel());
      }
    }, 1000);
  };

  toggleIsFilterOpen = () => {
    this.setState({ isFilterOpen: !this.state.isFilterOpen });
  };

  getFilters = () => {
    this.setState(this.getDefaultFiltersState(), () => {
      this.getBrands();
    });
  };

  getBrands = () => {
    brandService
      .getBrands({ ...this.getFilterModel(), brands: null })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        this.setState({ brands: data });
      });
  };

  render() {
    if (this.props.hasCurrentCategories)
      return (
        <CategoriesNavigation data={this.props.currentCategories} lvl={this.state.categoryLevel + 1} parentCategories={this.props.parentCategories} />
      );
    else
      return (
        <StyledFilter flexDirection={"column"} isMobile={this._isMobile}>
          <NanoFlex className="typeContainer" flexDirection={"column"} justifyContent={"flex-start"}>
            {/* <NanoFlex className="filterType" flexDirection={"column"} alignItems={"start"} justifyContent={"flex-start"}>
            <NanoFlex className="typeTitle filterBy" justifyContent={"flex-start"}>
              <Type.h3>FILTRADO POR</Type.h3>
            </NanoFlex>
            <NanoFlex className="typeContent" flexDirection={"column"}>
              <NanoFlex flexDirection={"column"}>
                <NanoFlex className="filterTypeItem">
                  <NanoFlex className="leftContainer">
                    <CircleMark />
                  </NanoFlex>
                  <NanoFlex className="rightContainer" justifyContent={"flex-start"}>
                    <Type.h5>Argamassa</Type.h5>
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="filterTypeItem">
                  <NanoFlex className="leftContainer">
                    <CircleMark />
                  </NanoFlex>
                  <NanoFlex className="rightContainer" justifyContent={"flex-start"}>
                    <Type.h5>Argamassa</Type.h5>
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="filterTypeItem">
                  <NanoFlex className="leftContainer">
                    <CircleMark />
                  </NanoFlex>
                  <NanoFlex className="rightContainer" justifyContent={"flex-start"}>
                    <Type.h5>Argamassa</Type.h5>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              <NanoFlex className="clearAllFilters">
                <Type.h6>Limpar Tudo</Type.h6>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex> */}
            {/* {this.props.currentCategories && this.props.currentCategories.length > 0 && (
              <NanoFlex className="filterType" flexDirection={"column"} alignItems={"start"} justifyContent={"flex-start"}>
                <NanoFlex className="typeTitle" justifyContent={"flex-start"}>
                  <Type.h3>CATEGORIA</Type.h3>
                </NanoFlex>
                <NanoFlex className="typeContent" flexDirection={"column"}>
                  <NanoFlex flexDirection={"column"}>
                    {this.props.currentCategories.map((c, i) => {
                      return (
                        <NanoFlex className="filterTypeItem" key={i}>
                          <NanoFlex className="leftContainer">
                            <Checkbox toggle />
                          </NanoFlex>
                          <NanoFlex className="rightContainer" justifyContent={"flex-start"}>
                            <Type.h5>{c.name}</Type.h5>
                          </NanoFlex>
                        </NanoFlex>
                      );
                    })}
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
            )} */}
            <NanoFlex className="filterType" flexDirection={"column"} alignItems={"start"} justifyContent={"flex-start"}>
              <NanoFlex className="typeTitle" justifyContent={"flex-start"}>
                <Type.h3>FILTROS</Type.h3>
                {this._isMobile && (
                  <NanoFlex className="toggleFilter" justifyContent={"flex-end"}>
                    {this.state.isFilterOpen && <ArrowUp onClick={() => this.toggleIsFilterOpen()} />}
                    {!this.state.isFilterOpen && <ArrowDown onClick={() => this.toggleIsFilterOpen()} />}
                  </NanoFlex>
                )}
              </NanoFlex>
              {(!this._isMobile || this.state.isFilterOpen) && (
                <React.Fragment>
                  <NanoFlex className="typeContent filterGroup" flexDirection={"column"}>
                    <NanoFlex className="filterTypeTitle" justifyContent="flex-start">
                      <Type.h5>Preço</Type.h5>
                    </NanoFlex>
                    <NanoFlex className="filterTypeItem">
                      <NanoFlex className="priceContainer">
                        <Input
                          isNumeric
                          placeholder="€ Min"
                          defaultValue={this.state.minPrice}
                          onChangeAction={(val) => this.setState({ minPrice: val })}
                        />
                        <Input
                          isNumeric
                          placeholder="€ Max"
                          defaultValue={this.state.maxPrice}
                          onChangeAction={(val) => this.setState({ maxPrice: val })}
                        />
                      </NanoFlex>
                    </NanoFlex>
                    {/* <NanoFlex className="filterTypeItem">
                      <NanoFlex className="leftContainer">
                        <Checkbox />
                      </NanoFlex>
                      <NanoFlex className="rightContainer" justifyContent={"flex-start"}>
                        <Type.h5>0€ - 50€</Type.h5>
                      </NanoFlex>
                    </NanoFlex>
                    <NanoFlex className="filterTypeItem">
                      <NanoFlex className="leftContainer">
                        <Checkbox toggle />
                      </NanoFlex>
                      <NanoFlex className="rightContainer" justifyContent={"flex-start"}>
                        <Type.h5>50€ - 100€</Type.h5>
                      </NanoFlex>
                    </NanoFlex> */}
                  </NanoFlex>
                  {this.state.brands && this.state.brands.length > 0 && (
                    <NanoFlex className="typeContent filterGroup" flexDirection={"column"}>
                      <NanoFlex className="filterTypeTitle" justifyContent="flex-start">
                        <Type.h5>Marca</Type.h5>
                      </NanoFlex>
                      {this.state.brands.map((b) => {
                        return (
                          <NanoFlex key={b.id} className="filterTypeItem">
                            <NanoFlex className="leftContainer">
                              <Checkbox
                                toggle={this.state.selectedBrands.includes(b.id)}
                                onChangeAction={(id) => {
                                  if (this.state.selectedBrands.includes(b.id)) {
                                    this.setState({ selectedBrands: this.state.selectedBrands.filter((br) => br !== b.id) });
                                  } else {
                                    this.setState({ selectedBrands: [...this.state.selectedBrands, b.id] });
                                  }
                                }}
                              />
                            </NanoFlex>
                            <NanoFlex className="rightContainer" justifyContent={"flex-start"}>
                              <Type.h5>{b.name}</Type.h5>
                            </NanoFlex>
                          </NanoFlex>
                        );
                      })}
                    </NanoFlex>
                  )}
                  {/* <NanoFlex className="typeContent filterGroup" flexDirection={"column"}>
                    <NanoFlex className="filterTypeTitle" justifyContent="flex-start">
                      <Type.h5>Stock</Type.h5>
                    </NanoFlex>
                    <NanoFlex className="filterTypeItem">
                      <NanoFlex className="leftContainer">
                        <Checkbox />
                      </NanoFlex>
                      <NanoFlex className="rightContainer" justifyContent={"flex-start"}>
                        <Type.h5>Em Stock</Type.h5>
                      </NanoFlex>
                    </NanoFlex>
                    <NanoFlex className="filterTypeItem">
                      <NanoFlex className="leftContainer">
                        <Checkbox toggle />
                      </NanoFlex>
                      <NanoFlex className="rightContainer" justifyContent={"flex-start"}>
                        <Type.h5>Sem Stock</Type.h5>
                      </NanoFlex>
                    </NanoFlex>
                  </NanoFlex> */}
                </React.Fragment>
              )}
            </NanoFlex>
          </NanoFlex>
        </StyledFilter>
      );
  }
}

var filter = withParamsAndNavigate(Filter);
export { filter as Filter };
