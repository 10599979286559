import React from "react";
import styled, { css } from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import Modal from "../../Global/Elements/Modal.js";
import { withParamsAndNavigate, getQueryStringObj } from "./../../Global/tools.js";

import Login from "../Client/Login.js";

import { ReactComponent as MagnifyingGlass } from "../../Global/Icons/magnifyingGlassSolid.svg";
import { ReactComponent as CartShopping } from "../../Global/Icons/cartShoppingSolid.svg";
import { ReactComponent as User } from "../../Global/Icons/userSolid.svg";

import { CategoriesMenu } from "../Categories/CategoriesMenu.js";

//Services
import shopService from "./../../../services/ShopService.js";

const StyledHeader = styled(NanoFlex)`
  width: 100%;
  position: sticky;
  z-index: 9;
  left: 0px;
  top: 0px;
  height: 75px;
  .header {
    height: 75px;
    background-color: #ffffff;
    border-bottom: ${(props) => props.theme.color.colorsPalette.lighterGrey} 2px solid;

    .imgContainer {
      max-width: 270px;
      cursor: pointer;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .rightContainer {
      padding-right: 48px;
      .searchWrapper {
        position: relative;
        max-width: 500px;

        input {
          width: 100%;
          height: 44px;
          padding: 9px 22px;
          padding-right: 40px;
          border: ${(props) => props.theme.color.colorsPalette.lighterGrey} 1px solid;
          background: #fbfbfb;
          &:focus {
            outline: none;
          }
        }
        .searchIconWrapper {
          position: absolute;
          height: auto;
          right: 8px;
          width: 24px;
          svg {
            width: 18px;
            height: 18px;
            path {
              fill: #9f9f9f;
            }
          }
        }
      }
      .btnLogin {
        cursor: pointer;
        width: auto;
        gap: 10px;
      }
      .iconContainer {
        cursor: pointer;
        width: 44px;
        margin-left: 12px;
        .icon {
          width: 44px;
          height: 44px;
          border: ${(props) => props.theme.color.colorsPalette.lighterGrey} 1px solid;
          background: #fbfbfb;
          svg {
            width: 24px;
            height: 24px;
            path {
              fill: black;
            }
          }

          &.shop {
            position: relative;

            .shopCount {
              width: 20px;
              height: 20px;
              z-index: 2;
              position: absolute;
              top: 0;
              right: 0;
              background-color: ${(props) => props.theme.color.colorsPalette.red};
              border-radius: 50%;

              h5 {
                color: ${(props) => props.theme.color.main.white};
                text-align: center;
              }
            }

            svg {
              z-index: 1;
            }
          }
        }
      }
    }
  }

  .navBar {
    height: 75px;

    .freeShipping {
      width: 100%;
      background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
      h4 {
        margin-bottom: 0px;
        text-transform: uppercase;
      }
    }
  }

  ${(props) =>
    props.isMobile &&
    css`
      height: 120px;

      .header {
        height: 120px;
        padding: 10px 0px;
        gap: 10px;
        .rightContainer {
          padding: 0px 20px;

          .searchWrapper {
            max-width: 100%;
          }
        }
      }
    `}
`;

class Header extends BaseComponent {
  constructor(props) {
    super(props);

    let param = getQueryStringObj(this.props.location.search);
    this.state = {
      term: param?.term ?? "",
      openLoginModal: false,
      shopLinesCount: 0,
    };

    this.termRequestId = 1;
  }

  componentDidMount() {
    super.componentDidMount();
    window.addEventListener("updateShopCount", this.getShopLinesCount, false);
    window.addEventListener("clientLogin", this.getShopLinesCount, false);
    window.addEventListener("clientLogout", this.getShopLinesCount, false);
    window.addEventListener("openLoginModal", this.openLoginModal, false);

    this.getShopLinesCount();
  }

  componentDidUpdate(prevProps, prevState) {
    /*On Search Change */
    if (prevProps.location.search !== this.props.location.search) {
      let param = getQueryStringObj(this.props.location.search);
      this.setState({ term: param?.term });
    }

    if ((prevState.term ?? "") !== (this.state.term ?? "")) {
      if (this.state.term?.trim() !== "") {
        this.termRequestId++;
        let requestId = this.termRequestId;
        setTimeout(() => {
          if (this.termRequestId === requestId) {
            this.redirectToSearch();
          }
        }, 1000);
      } else {
        this.props.navigate("/");
      }
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener("updateShopCount", this.getShopLinesCount, false);
    window.removeEventListener("clientLogin", this.getShopLinesCount, false);
    window.removeEventListener("clientLogout", this.getShopLinesCount, false);
    window.removeEventListener("openLoginModal", this.openLoginModal, false);
  }

  getShopLinesCount = () => {
    if (this._user) {
      shopService
        .getShopLinesCount()
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
              this.clientLogout();
          }
        })
        .then((data) => {
          this.setState({ shopLinesCount: data ?? 0 });
        });
    } else {
      this.setState({ shopLinesCount: 0 });
    }
  };

  redirectToHome = () => {
    this.props.navigate("/");
  };

  redirectToSearch = () => {
    if ((this.state.term ?? "")?.trim() !== "") {
      this.props.navigate("/produtos?" + new URLSearchParams({ term: this.state.term }).toString());
    } else {
      if (this.props.location.pathname.toUpperCase().startsWith("/Produtos")) {
        this.props.navigate("/");
      }
    }
  };

  onTermKeyDown = (e) => {
    if (e.key === "Enter") {
      this.termRequestId++;
      this.redirectToSearch();
    }
  };

  onUserClick = () => {
    if (this._user) {
      this.props.navigate("/cliente");
    } else {
      this.setState({ openLoginModal: true });
    }
  };

  onShopClick = () => {
    if (this._user) {
      this.props.navigate("/carrinho");
    } else {
      this.setState({ openLoginModal: true });
    }
  };

  onLogin = () => {
    this.setState({ openLoginModal: false });
  };

  openLoginModal = () => {
    this.setState({ openLoginModal: true });
  };

  render() {
    return (
      <StyledHeader flexDirection={"column"} justifyContent={"start"} isMobile={this._isMobile}>
        <NanoFlex className="header" flexDirection={this._isMobile ? "column" : "row"}>
          <NanoFlex className="leftContainer" flexDirection={"row"} alignItems={"flex-start"} justifyContent={"left"}>
            <CategoriesMenu />
            <NanoFlex className="imgContainer" flexDirection={"column"} alignItems={"flex-start"} onClick={this.redirectToHome}>
              <img src="/Images/icon.png" />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="rightContainer" flexDirection={"row"} alignItems={"flex-end"} justifyContent={"right"}>
            <NanoFlex className="searchWrapper" justifyContent={"flex-start"}>
              <input
                placeholder="Pesquisar produto"
                value={this.state.term ?? ""}
                onChange={(e) => this.setState({ term: e.target.value })}
                onKeyDown={this.onTermKeyDown}
              />
              <NanoFlex className="searchIconWrapper">
                <MagnifyingGlass />
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="btnLogin" onClick={() => this.onUserClick()}>
              <NanoFlex className="iconContainer">
                <NanoFlex className="icon">
                  <User />
                </NanoFlex>
              </NanoFlex>
              {!this._isMobile && (
                <NanoFlex flexDirection={"column"} alignItems={"flex-start"}>
                  <Type.h4>Olá!</Type.h4>
                  <Type.h4>{this._user ? this._user.Name : "Iniciar Sessão"}</Type.h4>
                </NanoFlex>
              )}
            </NanoFlex>
            <NanoFlex className="iconContainer" onClick={() => this.onShopClick()}>
              <NanoFlex className="icon shop">
                {this.state.shopLinesCount > 0 && (
                  <NanoFlex className="shopCount">
                    <Type.h5>{this.state.shopLinesCount}</Type.h5>
                  </NanoFlex>
                )}
                <CartShopping />
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
        {/* <NanoFlex className="navBar" flexDirection={"row"}>
          <CategoriesMenu />
          <NanoFlex className="freeShipping">
            <Type.h4>Portes gratis em encomendas acima de 200€ e até 100kg</Type.h4>
          </NanoFlex>
        </NanoFlex> */}
        <Modal
          show={this.state.openLoginModal}
          content={<Login onCancel={() => this.setState({ openLoginModal: false })} onLogin={() => this.onLogin()} />}
        />
      </StyledHeader>
    );
  }
}

var header = withParamsAndNavigate(Header);
export { header as Header };
