import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import Image from "../../Global/Elements/Image.js";
import { urlExistsAsync, withParamsAndNavigate } from "../../Global/tools.js";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { CategoriesGrid } from "../Categories/CategoriesGrid.js";

import pageService from "./../../../services/PageService.js";
import categoriesService from "./../../../services/CategoriesService.js";

import { ReactComponent as ArrowL } from "../../Global/Icons/arrowL.svg";
import { ReactComponent as ArrowR } from "../../Global/Icons/arrowR.svg";

const StyledHome = styled(NanoFlex)`
  padding: 0px 5vw;
  .sectionContainer {
    border-right: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
    border-left: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
    .topSection {
      flex: 1 1 0px;
      padding: 20px 20px;
      gap: 20px;
      .item {
        cursor: pointer;
        box-shadow: 2px 2px 2px ${(props) => props.theme.color.colorsPalette.lighterGrey};
        border: 2px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};

        a {
          text-decoration: none;
        }

        .imgContainer {
          overflow: hidden;
          width: 100%;
          height: 100%;
          img {
            padding: 10px;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .infoContainer {
          height: 75px;
          padding: 10px 20px;
          border-top: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};

          h2 {
            text-align: center;
          }
        }
      }
    }

    .carouselWrapper {
      padding: 20px 20px;
      .slider-wrapper {
        height: 100% !important;
      }
      .thumbs-wrapper {
        text-align: center;
      }
      .control-arrow,
      .carousel-status {
        display: none;
      }
      .imgContainer {
        max-height: 700px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .categoriesContainer {
      padding: 20px 50px;
    }
  }
`;

class Home extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      pages: null,
      categories: null,
      loadingImages: true,
      sliderImages: null,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.getPages();
    this.getCategories();
    this.loadImages().then();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getPages = () => {
    pageService
      .getPages()
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        this.setState({ pages: data });
      });
  };

  getCategories = () => {
    categoriesService
      .getCategories()
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        this.setState({ categories: data });
      });
  };

  loadImages = async () => {
    let sliderImages = [];

    for (let c = 1; c <= 5; c++) if (await urlExistsAsync(`/api/homepage/Slide${c}.jpg`)) sliderImages.push(`/api/homepage/Slide${c}.jpg`);

    this.setState({ loadingImages: false, sliderImages: sliderImages });
  };

  render() {
    return (
      <StyledHome flexDirection={"column"}>
        <NanoFlex className="sectionContainer" flexDirection={"column"}>
          <NanoFlex className="topSection" flexDirection={this._isMobile ? "column" : "row"}>
            <NanoFlex className="item" flexDirection={"column"} onClick={() => this.props.navigate("/piscinas")}>
              <div className="imgContainer">
                <Image imageUrl={"/api/homepage/Piscinas.jpg"} />
              </div>
              <NanoFlex className="infoContainer" flexDirection={"column"}>
                <NanoFlex className="title" justifyContent={"center"}>
                  <Type.h2>{this.state.pages?.find((v) => v.id === 1)?.name}</Type.h2>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex
              className="item"
              flexDirection={"column"}
              onClick={() => this.props.navigate(`/produtos?${new URLSearchParams({ hasPromo: true }).toString()}`)}
            >
              <div className="imgContainer">
                <Image imageUrl={"/api/homepage/Promocoes.jpg"} />
              </div>
              <NanoFlex className="infoContainer" flexDirection={"column"}>
                <NanoFlex className="title" justifyContent={"center"}>
                  <Type.h2>PROMOÇÕES</Type.h2>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="item" flexDirection={"column"} onClick={() => this.props.navigate("/chave-na-mao")}>
              <div className="imgContainer">
                <Image imageUrl={"/api/homepage/Chavenamao.jpg"} />
              </div>
              <NanoFlex className="infoContainer" flexDirection={"column"}>
                <NanoFlex className="title" justifyContent={"center"}>
                  <Type.h2>{this.state.pages?.find((v) => v.id === 2)?.name}</Type.h2>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          {!this._isMobile && !this.state.loadingImages && this.state.sliderImages.length > 0 && (
            <NanoFlex className="carouselWrapper">
              <Carousel autoPlay infiniteLoop emulateTouch useKeyboardArrows stopOnHover dynamicHeight={true}>
                {this.state.sliderImages.map((s) => {
                  return (
                    <NanoFlex key={s} className="imgContainer">
                      <Image imageUrl={s} />
                    </NanoFlex>
                  );
                })}
              </Carousel>
            </NanoFlex>
          )}
          <NanoFlex className="categoriesContainer">
            <CategoriesGrid data={this.state.categories ?? []} autoFill />
          </NanoFlex>
        </NanoFlex>
      </StyledHome>
    );
  }
}

var home = withParamsAndNavigate(Home);
export { home as Home };
